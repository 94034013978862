/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface TableCustomProps {
  size?: string;
}

export const TableCustom = styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
  padding: 12px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
  box-shadow: 0 0 0 1px ${(props) => props.theme.shadow} inset;
  min-width: 650px;
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td<TableCustomProps>`
  width: ${(props) => props.size};
  overflow: initial;
  border-bottom: 1px solid #eee;
  padding: 15px 25px;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => props.theme.textSec};
  align-items: center;
  line-height: 0rem;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #f4f6f890;
  }
  &:last-child {
    & > td,
    th {
      border-bottom: 0;
    }
  }
`;

export const TableCellH = styled.th<TableCustomProps>`
  width: ${(props) => props.size};
  border-bottom: 1px solid #eee;
  padding: 17px 25px;
  font-size: 0.875rem;
  color: ${(props) => props.theme.background}80;
  align-items: center;
  line-height: 0rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
`;

export const TableHead = styled.thead`
  background-color: ${(props) => props.theme.backgroundSec};
  line-height: 1.5rem;
  & tr {
    &:hover {
      background-color: transparent;
    }
  }
  & th {
    &:first-of-type {
      border-radius: 7px 0 0 7px;
    }
    &:last-of-type {
      border-radius: 0 7px 7px 0;
    }
  }
`;

export default {
  TableCustom,
  TableBody,
  TableCell,
  TableRow,
  TableCellH,
  TableHead,
};

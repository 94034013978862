/* eslint-disable no-shadow */
import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { HiLogout } from 'react-icons/hi';
import { BsClock } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';

import moment from 'moment';

import { LinkProfile } from './style';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

import {
  Text,
  Tag,
  Logo,
  Button,
  Stack,
  CustomerStack,
  StackPosition,
  Box,
} from '../../pages/site/lib/style';
import { Image } from '../../lib';
import { useAuth } from '../../contexts/AuthContext';
import { Menu } from '../../styles/style';

function CustomerHeader({ company, setLoginModal, setModalCompany }) {
  const { singOut, user, authed } = useAuth();
  const [statusModal, setStatusModal] = React.useState(false);
  const [animation, setAnimation] = React.useState('none');

  const handleToggleModal = () => {
    setStatusModal(!statusModal);
    setAnimation(!statusModal);
  };

  const themeColor = React.useContext(ThemeContext);

  return (
    <>
      <Box padding="20px 20px 10px 20px" margin="-50px 0 0">
        <Stack direction="row" spacing="20px">
          <Logo src={company?.logo} width="100px" height="100px" />
          <Box width="100%">
            <Stack
              direction="row"
              spacing="15px"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Text variant="title">{company?.name}</Text>
              <StackPosition>
                {company.whatsapp && (
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=55${company.whatsapp}`}
                    rel="noreferrer"
                  >
                    <FaWhatsapp
                      size="20"
                      color={`rgba(var(--${themeColor.color}), 1)`}
                    />
                  </a>
                )}
              </StackPosition>
            </Stack>
            <CustomerStack direction="row" spacing="15px" alignItems="center">
              {authed ? (
                <Text variant="small">Olá, {user?.configuration?.name}</Text>
              ) : (
                <Button
                  variant="link"
                  onClick={() => setLoginModal(true)}
                  style={{ padding: 0 }}
                >
                  Fazer Login ou Cadastrar
                </Button>
              )}

              {moment(new Date(), 'hh:mm:ss').isBetween(
                moment(company.openTime, 'hh:mm:ss'),
                moment(company.closeTime, 'hh:mm:ss'),
              ) ? (
                <Tag color="green">Aberto</Tag>
              ) : (
                <Tag color="red">Fechado</Tag>
              )}
              <BsClock size="12" />
              <Text variant="small">
                {company.openTime} - {company.closeTime}
              </Text>
              <Text
                variant="small"
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => setModalCompany((modalCompany) => !modalCompany)}
              >
                Mais Informações
              </Text>
            </CustomerStack>
          </Box>
        </Stack>
      </Box>
      {authed ? (
        <Menu onClick={handleToggleModal} animation={animation}>
          <Image
            src={
              user?.configuration?.picture &&
              !user?.configuration?.picture.includes('user_no_photo.png')
                ? user?.configuration?.picture
                : imgLogo
            }
            width="48px"
            height="48px"
            borderRadius="50%"
            border="3px solid rgb(218, 229, 242)"
          />
          {statusModal ? (
            <Stack direction="column" spacing="10px">
              <Button variant="ghost">
                <LinkProfile to="/configuracoes">Perfil</LinkProfile>
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  singOut();
                }}
              >
                Sair
                <HiLogout />
              </Button>
            </Stack>
          ) : (
            ''
          )}
        </Menu>
      ) : (
        ''
      )}
    </>
  );
}

export default CustomerHeader;

import React from 'react';

import { TagCard } from './style';

interface Props {
  backgroundColor?: string;
  color?: string;
  style?: Record<string, unknown>;
  padding?: string;
}

const Tag: React.FC<Props> = ({ children, ...props }) => {
  return <TagCard {...props}>{children}</TagCard>;
};

export default Tag;

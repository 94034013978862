/* eslint-disable react/prop-types */
import React from 'react';
import { BiGroup } from 'react-icons/bi';
import { Text, Card, Stack } from '../lib/style';
import { Image } from '../../../lib';
import imgLogo from '../../../assets/icons/logoWeekeDelivery.svg';

function CardItem({ data, open }) {
  return (
    <Card onClick={open}>
      <Stack
        direction="row"
        spacing="40px"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <Text variant="heading" gutter="8px">
            {data?.name}
          </Text>
          <Text variant="small" gutter="8px">
            {data?.description}
          </Text>
          {data?.porcao && (
            <Text
              variant="small"
              gutter="8px"
              style={{
                display: 'inline-flex',
                gap: '5px',
                alignItems: 'center',
                fontWeight: 700,
              }}
            >
              <BiGroup size="14" />
              <p>{data?.porcao}</p>
            </Text>
          )}
          <Text variant="caption-md">
            {Number(data?.price)?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </div>
        <Image
          src={
            data?.image && !data?.image.includes('product_no_photo.png')
              ? data.image
              : imgLogo
          }
          width="90px"
          height="90px"
        />
      </Stack>
    </Card>
  );
}

export default CardItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BsXLg } from 'react-icons/bs';
import { CSSTransition } from 'react-transition-group';

import Button from '../Button';
import Stack from '../Stack';
import Text from '../Text';
import { ModalSide, ModalOverlay, ModalBody } from './style';

interface Props {
  width?: string;
  title?: string;
  behind?: boolean;
  state?: boolean;
  set?: any;
  style?: Record<string, unknown>;
}

const Drawer: React.FC<Props> = ({ children, ...props }) => {
  const closeDrawer = () => {
    props.set(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        closeDrawer();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [closeDrawer, props.state]);

  return (
    <>
      <CSSTransition
        in={props.state}
        timeout={500}
        classNames="drawer-overlay"
        unmountOnExit
      >
        <ModalOverlay
          onClick={() => {
            closeDrawer();
          }}
        />
      </CSSTransition>
      <ModalSide behind={props.behind}>
        <CSSTransition
          in={props.state}
          timeout={500}
          classNames="drawer-body"
          unmountOnExit
        >
          <ModalBody {...props}>
            <Stack direction="row" align="center" padding="0 0 17px 0">
              <Text variant="title" full>
                {props.title}
              </Text>
              <Button
                variant="ghost"
                shape="icon"
                onClick={() => {
                  closeDrawer();
                }}
              >
                <BsXLg />
              </Button>
            </Stack>
            {children}
          </ModalBody>
        </CSSTransition>
      </ModalSide>
    </>
  );
};

export default Drawer;

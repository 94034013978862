import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerTracking = styled.main`
  box-sizing: boder-box;
  width: 90%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  > h1 {
    font-size: 20px;
    color: #808080;
  }
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 150px;
  > p,
  span {
    color: #808080;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    flex-direction: row;
    justify-content: start;
  }
`;

export const Status = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.background};
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    margin-bottom: 0px;
    height: 40px;
    width: 2px;
  }
`;

export const ContainerInfo = styled.div`
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;

  > h2 {
    font-size: 18px;
    color: #808080;
  }
`;

export const List = styled.ul`
  width: 100%;
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  > div {
    width: 100%;
    display: flex;
    align-items: start;
    > p {
      width: 100%;
      color: #808080;
      font-weight: bold;
    }
  }
`;

export const ItemList = styled.li`
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  color: #808080;
  background-color: ${(props) => props.background};

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    > div {
      flex-direction: column;
      text-align: center;
    }
  }
`;

/* eslint-disable no-eval */
import React, { useEffect, useState } from 'react';

import { Text } from '../../lib';
import api, { apiURL } from '../../services/api';

function UserName({ id }): JSX.Element {
  const [user, setUser] = useState<{ name: string; picture: string }>({
    name: 'Sem Nome',
    picture: '',
  });
  const getUser = async () => {
    await api.get(`${apiURL}/rest/users.php/${id}`).then((res: any) => {
      setUser(res.data.configuration);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return <Text>{user.name}</Text>;
}

export default UserName;

/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { color } from '../../services/api';

interface Props {
  width?: string;
  behind?: boolean;
}

export const ModalSide = styled.div<Props>`
  position: fixed;
  top: 0;
  right: 0;
  width: fit-content;
  height: 100vh;
  z-index: 119;
`;
export const ModalBody = styled.div<Props>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => props.width || '350px'};
  height: -webkit-fill-available;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${(props) => props.theme.background};
  box-shadow: -10px 0 50px -15px #00000020;
  padding: 25px;
  transition: 300ms all ease;
  ${(props) =>
    props.behind &&
    css`
      transform: scale(0.95);
      right: 80px;
      border-radius: 20px;
    `}
`;
export const ModalOverlay = styled.div<Props>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(var(--${color}3), 0.6);
  backdrop-filter: blur(5px);
  z-index: 119;
`;

export default { ModalSide, ModalOverlay, ModalBody };

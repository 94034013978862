/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { Image, Stack, Text } from '../../../../lib';
import { Column, Title, ColumnItem } from '../../style';
import api, { apiURL } from '../../../../services/api';
import imgLogo from '../../../../assets/icons/logoWeekeDelivery.svg';

export default function Product({
  order,
  currentDate,
  datePrevious,
  quantityDate,
}) {
  const { user } = useAuth();
  const [orderData, setOrderData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [filteredDate, setFilteredDate] = useState<any>([]);
  const [status, setStatus] = useState<boolean>(false);

  const getOrderData = async () => {
    if (user?.configuration?.access?.historic?.read) {
      await api.get(`${apiURL}/rest/requests.php`).then((res: any) => {
        let resp = res?.data?.filter((e) => e.state === 'DELIVERED');

        resp = resp.map((e) => ({
          products: e?.products?.map((product) => ({
            ...product,
            date: e?.last_log?.date?.split(' ')[0].replace(/-/g, ''),
          })),
        }));

        resp = resp?.flatMap((array) => array.products);

        setOrderData(resp || []);
        setStatus((prev) => !prev);
      });
    }
  };

  const getProductData = async () => {
    if (user?.configuration?.access?.historic?.read) {
      await api.get(`${apiURL}/rest/products.php`).then((res: any) => {
        setProductData(
          res.data.map((e) => ({
            id: e.id,
            image: e.image,
            name: e.name,
            price: e.price,
            counter: 0,
          })) || [],
        );
        setStatus((prev) => !prev);
      });
    }
  };

  useEffect(() => {
    getOrderData();
    getProductData();
  }, []);

  const addPrices = (price, counter) => {
    const prc = Number(price);
    const cnt = Number(counter);
    const resp = prc * cnt;
    return resp.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const countBestSellingProduct = () => {
    if (orderData && productData) {
      const data = [...productData];
      const newData = data.map((item) => ({ ...item }));
      const newOrderData = orderData?.filter((e) => {
        const date = Number(e?.date);
        const previous = Number(datePrevious?.replace(/-/g, ''));
        const current = Number(currentDate?.replace(/-/g, ''));
        return date >= previous && date <= current;
      });

      newOrderData?.forEach((e) => {
        const index = newData?.findIndex((el) => el.id === e.id);
        if (newData[Number(index)]) {
          newData[Number(index)].counter += Number(e?.amount);
        }
      });

      const compareByCounterBigger = (a, b) => {
        return b.counter - a.counter;
      };

      const compareByCounterMinor = (a, b) => {
        return a.counter - b.counter;
      };

      const resp = newData
        .filter((e) => e.counter !== 0)
        .slice(0, quantityDate);

      return setFilteredDate(
        resp.sort(
          order === 'maior' ? compareByCounterBigger : compareByCounterMinor,
        ),
      );
    }

    return [];
  };

  useEffect(() => {
    countBestSellingProduct();
  }, [order, currentDate, datePrevious, status, quantityDate]);

  return (
    <>
      <Stack direction="row" align="center" justify="space-between">
        <Column>
          <Title>
            <Text variant="label">Ordem</Text>
          </Title>
          {filteredDate?.map((e, index) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{index + 1}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Nome</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{e.name}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Imagem</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id} title={e.name}>
                <Image
                  width="50px"
                  height="50px"
                  borderRadius="8px"
                  src={
                    e?.image && !e.image.includes('product_no_photo.png')
                      ? e.image
                      : imgLogo
                  }
                />
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Preço</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">
                  {Number(e.price).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Total de Vendas</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{e.counter}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Valor Faturado</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{addPrices(e.price, e.counter)}</Text>
              </ColumnItem>
            );
          })}
        </Column>
      </Stack>
    </>
  );
}

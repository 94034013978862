/* eslint-disable no-useless-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { Image, Stack, Text } from '../../../../lib';
import { Column, Title, ColumnItem } from '../../style';
import api, { apiURL } from '../../../../services/api';
import imgLogo from '../../../../assets/icons/logoWeekeDelivery.svg';

export default function Clients({
  order,
  currentDate,
  datePrevious,
  quantityDate,
}) {
  const { user } = useAuth();
  const [orderData, setOrderData] = useState<any>([]);
  const [clientsData, setClientsData] = useState<any>([]);
  const [filteredDate, setFilteredDate] = useState<any>([]);
  const [status, setStatus] = useState<boolean>(false);

  const getOrderData = async () => {
    if (user?.configuration?.access?.historic?.read) {
      await api.get(`${apiURL}/rest/requests.php`).then((res: any) => {
        let resp = res?.data?.filter((e) => e.state === 'DELIVERED');

        const filteredResp = resp.filter((e) =>
          e?.products?.find((product) => Number(product?.price) > 0),
        );

        resp = filteredResp.map((e) => {
          const totalPrice = e.products.reduce(
            (sum, el) => sum + Number(el.price),
            0,
          );
          return {
            user: e.user,
            value: totalPrice,
            date: e.last_log.date.split(' ')[0],
          };
        });

        setOrderData(resp || []);
        setStatus((prev) => !prev);
      });
    }
  };

  function getClientsData() {
    if (user?.configuration?.access?.historic?.read) {
      api
        .get(`${apiURL}/rest/users.php`)
        .then((res) => {
          const resp = res.data;
          setClientsData(
            resp.map((e) => ({
              id: e.id,
              image: e.configuration.picture,
              name: e.configuration.name,
              phone: e.configuration.phone,
              amount: 0,
              totalSpent: 0,
            })) || [],
          );
          setStatus((prev) => !prev);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  const customerAccountant = () => {
    if (orderData && clientsData) {
      const data = [...clientsData];
      const newData = data.map((item) => ({ ...item }));
      const newOrderData = orderData?.filter((e) => {
        const date = Number(e?.date?.replace(/-/g, ''));
        const previous = Number(datePrevious?.replace(/-/g, ''));
        const current = Number(currentDate?.replace(/-/g, ''));
        return date >= previous && date <= current;
      });

      newOrderData?.forEach((e: any) => {
        const index = newData?.findIndex((el) => el?.id === e?.user?.id);
        if (newData[Number(index)]?.amount >= 0) {
          newData[Number(index)].amount += 1;
          newData[Number(index)].totalSpent += e.value;
        }
      });

      const compareByCounterBigger = (a, b) => {
        return b?.amount - a?.amount;
      };

      const compareByCounterMinor = (a, b) => {
        return a?.amount - b?.amount;
      };

      const resp = newData
        .filter((e) => e?.amount !== 0)
        .slice(0, quantityDate);

      return setFilteredDate(
        resp.sort(
          order === 'maior' ? compareByCounterBigger : compareByCounterMinor,
        ),
      );
    }

    return [];
  };

  useEffect(() => {
    getOrderData();
    getClientsData();
  }, []);

  useEffect(() => {
    customerAccountant();
  }, [order, currentDate, datePrevious, status, quantityDate]);

  return (
    <>
      <Stack direction="row" align="center" justify="space-between">
        <Column>
          <Title>
            <Text variant="label">Ordem</Text>
          </Title>
          {filteredDate?.map((e, index) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{index + 1}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Nome</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{e.name}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Imagem</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id} title={e.name}>
                <Image
                  width="50px"
                  height="50px"
                  borderRadius="8px"
                  src={
                    e?.image && !e?.image.includes('user_no_photo.png')
                      ? e?.image
                      : imgLogo
                  }
                />
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Compras</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{e?.amount}</Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Total de Gastos</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">
                  {e.totalSpent.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              </ColumnItem>
            );
          })}
        </Column>
        <Column>
          <Title>
            <Text variant="label">Telefone</Text>
          </Title>
          {filteredDate?.map((e) => {
            return (
              <ColumnItem key={e.id}>
                <Text variant="small">{e.phone}</Text>
              </ColumnItem>
            );
          })}
        </Column>
      </Stack>
    </>
  );
}

import React from 'react';

import { Typograph } from './style';

interface Props {
  weight?: string;
  align?: string;
  gutter?: string;
  color?: string;
  variant?: 'title' | 'subtitle' | 'small' | 'label';
  style?: Record<string, unknown>;
  full?: boolean;
  as?: any;
  short?: boolean;
}

const Text: React.FC<Props> = ({ children, ...props }) => (
  <Typograph {...props}>{children}</Typograph>
);

export default Text;

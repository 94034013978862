import styled, { css } from 'styled-components';

export const SlideHighlights = styled.section`
  box-sizing: border-box;
  width: 100%;
  max-width: 860px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  > h2{
    font-size: 18px;
  }

  > ul {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 180px;
    gap: 100px;
    overflow-x: auto;
    }
  }
`;

export const HighlightsCard = styled.li`
  box-sizing: border-box;
  padding: 20px 20px;
  height: 150px;
  min-width: 400px;
  background-color: #ffffff;
  border-bottom: 1px solid #dae5f2;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    max-width: 300px;
  }

  > div ~ div {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

interface Props {
  weight?: string;
  align?: string;
  gutter?: string;
  color?: string;
  variant?: 'title' | 'subtitle' | 'small' | 'label';
  full?: boolean;
}

export const TooltipComponent = styled.div`
  position: absolute;
  left: 110%;
  z-index: 100;
  background: #212b36;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  transition: 350ms all ease;
  opacity: 0;
  &::before {
    content: '';
    position: absolute;
    padding: 4px;
    transform: rotate(45deg);
    left: -4px;
    top: 13.5px;
    background: #212b36;
  }
`;

export default TooltipComponent;

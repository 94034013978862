/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { Container, Layout } from './styles/style';

import Sidebar from './components/Sidebar';
import api, { themeSystem } from './services/api';
import { dark, light } from './styles/theme/theme';

import { useAuth } from './contexts/AuthContext';
import Clients from './pages/clients';
import Dashboard from './pages/dashboard';
import Home from './pages/site';
import Login from './pages/login';
import Orders from './pages/orders';
import Products from './pages/products';
import Settings from './pages/settings';
import Users from './pages/users';
import TrackingInformation from './pages/tracking';
import NextUnit from './pages/nextUnit';
import Deliveryman from './pages/couriers';
import Deliveries from './pages/deliveries';
import Historic from './pages/historic';
import PasswordManaged from './pages/passwordManaged';

const PrivateRoute = () => {
  const { authed, user } = useAuth();
  const location = useLocation();
  const urlObj = new URL(window.location.href);
  urlObj.searchParams.entries();
  const { pathname } = urlObj;

  return authed ? (
    <>
      <ThemeProvider theme={themeSystem === 'dark' ? dark : light}>
        <Layout>
          {user?.configuration?.office === 'couriers' ||
          pathname === '/reset_account' ||
          pathname === '/active_account'
            ? ''
            : location.pathname !== '/' && <Sidebar />}
          <Container index={location.pathname !== '/'}>
            <Outlet />
          </Container>
        </Layout>
      </ThemeProvider>
    </>
  ) : (
    <>
      <Navigate to="/login" replace state={{ from: location }} />
    </>
  );
};

function LayoutDefault() {
  return <Outlet />;
}

export default function RoutesApp() {
  const { user, authed } = useAuth();

  return (
    <Routes>
      <Route element={<LayoutDefault />}>
        {(authed && user?.configuration?.access?.dashboard?.read) ||
        user?.configuration?.access?.dashboard?.write ||
        user?.configuration?.access?.requests?.read ||
        user?.configuration?.access?.requests?.write ||
        user?.configuration?.access?.clients?.read ||
        user?.configuration?.access?.clients?.write ||
        user?.configuration?.access?.products.read ||
        user?.configuration?.access?.products.write ||
        user?.configuration?.access?.users?.read ||
        user?.configuration?.access?.users?.write ||
        user?.configuration?.access?.couriers?.read ||
        user?.configuration?.access?.couriers?.write ||
        user?.configuration?.access?.settings?.read ||
        user?.configuration?.access?.settings?.write ? (
          user?.configuration?.office === 'couriers' ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/entregas" element={<Deliveries />} />
              <Route path="/configuracoes" element={<Settings />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/entregas" />} />
              <Route path="/active_account" element={<PasswordManaged />} />
              <Route path="/reset_account" element={<PasswordManaged />} />
            </>
          ) : (
            <Route element={<PrivateRoute />}>
              <>
                <Route path="*" element={<Dashboard />} />
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/pedidos" element={<Orders />} />
                <Route path="/clientes" element={<Clients />} />
                <Route path="/entregadores" element={<Deliveryman />} />
                <Route path="/produtos" element={<Products />} />
                <Route path="/configuracoes" element={<Settings />} />
                <Route path="/usuarios" element={<Users />} />
                <Route path="/historico" element={<Historic />} />
                <Route path="/active_account" element={<PasswordManaged />} />
                <Route path="/reset_account" element={<PasswordManaged />} />
              </>
              <Route
                path="/rastreio/:orderId"
                element={<TrackingInformation />}
              />
            </Route>
          )
        ) : (
          <>
            <Route path="/configuracoes" element={<Settings />} />
            <Route
              path="/rastreio/:orderId"
              element={<TrackingInformation />}
            />
            <Route path="/unidadeProxima" element={<NextUnit />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/active_account" element={<PasswordManaged />} />
            <Route path="/reset_account" element={<PasswordManaged />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

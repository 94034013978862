/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { color } from '../../services/api';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: string;
  full?: boolean;
  as?: React.ElementType | keyof JSX.IntrinsicElements | undefined;
  error?: string;
}

export const TextAreaField = styled.textarea<Props>`
  width: ${(props) => (props.full ? '-webkit-fill-available' : 'auto')};
  height: 125.8px;
  border: 1px solid ${(props) => props.theme.shadow};
  background-color: ${(props) => props.theme.backgroundSec};
  border-radius: 7px;
  padding: 13px 20px;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => props.theme.text};
  transition: 300ms all ease;
  resize: none;
  &:focus {
    background-color: #deecff40;
    border: 1px solid rgba(var(--${color}), 1);
    box-shadow: 0 0 0 1px rgba(var(--${color}), 1) inset;
  }
  &::placeholder {
    color: #a8a9a9;
  }
  ${(props) =>
    props.error &&
    css`
      background-color: #fd5d5410;
      border: 1px solid #fd5d54;
      box-shadow: 0 0 0 1px #fd5d54 inset;
    `}
  ${(props) =>
    props.as === 'select' &&
    css`
      background-image: url(/img/icon_chevron.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      padding-right: 40px;
      background-position: top 50% right 12px;
    `}
`;

export const Icon = styled.div`
  position: absolute;
  margin-left: 15px;
  color: #899097;
  display: flex;
`;

export const InputIcon = styled.div`
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  &:focus-within > ${Icon} {
    color: rgba(var(--${color}), 1);
  }
  & > ${TextAreaField} {
    padding-left: 42px;
  }
`;

export const InputDiv = styled.div<Props>`
  position: relative;
  width: 100%;

  ${(props) =>
    props.error &&
    css`
      & > input {
        border-color: red;
      }
      &:before {
        position: absolute;
        content: '${props.error}';
        font-size: 12px;
        z-index: 0;
        bottom: -20px;
        left: 0;
        color: #fd5d54;
      }
    `}
`;

export default { TextAreaField, InputIcon, Icon };

import styled, { keyframes } from 'styled-components';

const fallAnimation = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 400px;

  border-radius: 8px;

  padding: 30px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;

  h1,
  p {
    text-align: center;
  }

  animation: ${fallAnimation} 1.5s forwards;
`;

/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';
import { ThemeContext } from 'styled-components';

// Icones
import { BiChevronDown } from 'react-icons/bi';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import axios from 'axios';
import CardItem from '../CardItem';
import {
  Input,
  Image,
  Text,
  Stack,
  Textarea,
  Divider,
  Label,
  FormItem,
  Box,
  ModalBottom,
  ModalBody,
  ModalHead,
  ModalFooter,
  Button,
  ModalOverlay,
} from '../../lib/style';
import { textareaResize } from '../../services/utils';
import { apiURL } from '../../../../services/api';
import imgLogo from '../../../../assets/icons/logoWeekeDelivery.svg';
import { WarningModal } from '../../../../components/Modals/Warning';

export default function ModalProduct({ open, product, close, submit, edit }) {
  const { register, setValue, getValues, reset } = useForm({
    defaultValues: { ...edit, id: product },
  });
  const [bagStorage, setBagStorage] = useState([]);
  const [qtd, setQtd] = useState(1);
  const [sumItens, setItensTotal] = useState(0);
  const [sumTotal, setTotal] = useState(0);
  const [productSel, setProduct] = useState([]);
  const themeColor = React.useContext(ThemeContext);
  const [visibleWarningMode, setVisibleWarningMode] = useState(false);
  const [modalAnimation, setModalAnimation] = useState('down');
  const [warningModalValues, setWarningModalValues] = useState([
    'Sucesso',
    'success',
  ]);

  const warningModal = (text, type) => {
    setWarningModalValues([text, type]);
    setVisibleWarningMode(true);
    setModalAnimation('down');
    setTimeout(() => {
      setModalAnimation('up');
      setTimeout(() => {
        setVisibleWarningMode(false);
      }, 200);
    }, 5000);
  };

  let itemIndex = 0;
  const handleBag = () => {
    const obj = {
      ...getValues(),
      options: getValues()?.options?.filter((item) => item?.qtd > 0) || null,
    };

    if (obj?.qtd === undefined) {
      obj.qtd = 1;
    }

    localStorage.setItem('BAG', JSON.stringify([...bagStorage, obj]));
    setBagStorage((old) => [...old, getValues()]);
    reset();
  };

  const releaseSubmit = () => {
    const obj = {
      ...getValues(),
      options: getValues()?.options?.filter((item) => item?.qtd > 0) || null,
    };

    if (obj?.qtd === undefined) {
      obj.qtd = 1;
    }

    if (Array.isArray(obj?.id?.options) && obj?.id?.options.length > 0) {
      const options = obj?.id?.options;
      const listErrors = [];

      options.forEach((e) => {
        if (e.limit_min > 0) {
          if (Array.isArray(obj?.options) && obj?.options?.length > 0) {
            const orderOptions = obj?.options.filter((el) => {
              return Number(el.groupId) === Number(e.id);
            });

            if (Array.isArray(orderOptions) && orderOptions?.length > 0) {
              const result = orderOptions.find(
                (elem) => Number(elem.qtd) >= Number(e.limit_min),
              );

              if (
                result === undefined &&
                orderOptions.length < Number(e.limit_min)
              ) {
                listErrors.push(e);
              }
            } else {
              listErrors.push(e);
            }
          } else {
            listErrors.push(e);
          }
        }
      });

      if (listErrors.length === 0) {
        setValue('price', sumTotal);
        handleBag();
        submit();
        close();
      } else {
        const text = `${listErrors[0].name} requer no mínimo ${
          listErrors[0].limit_min
        } ${
          Number(listErrors[0].limit_min) === 1 ? 'opcional' : 'opcionais'
        } para o pedido.`;

        warningModal(text, 'warning');
      }
    } else {
      setValue('price', sumTotal);
      handleBag();
      submit();
      close();
    }
  };

  const sumItensHandle = () => {
    const merge = [];
    const options = getValues('options') || '';
    const adicionals = getValues('adicionals') || '';

    setItensTotal(
      merge
        .concat(options, adicionals)
        .filter((i) => i !== null)
        .filter((i) => i.qtd >= 1)
        .reduce(function (sum, tax) {
          return sum + tax.preco * tax.qtd;
        }, 0),
    );
  };

  const sumTotalHandle = () => {
    setTotal(parseFloat(productSel?.price) * qtd + sumItens);
  };

  useEffect(() => {
    setQtd(1);
    setItensTotal(0);
  }, [open, close]);

  useEffect(() => {
    sumTotalHandle();
  }, [qtd, sumItens, close, productSel]);

  useEffect(() => {
    setBagStorage(
      localStorage.getItem('BAG') === null || localStorage.getItem('BAG') === ''
        ? []
        : JSON.parse(localStorage.getItem('BAG')),
    );
  }, [open]);

  const getProduct = async () => {
    await axios.get(`${apiURL}/rest/products.php/${product.id}`).then((res) => {
      setProduct(res.data);
    });
  };

  useEffect(() => {
    getProduct();
  }, [product]);

  const ItemChoise = ({ item, index, name, maxItem, listItems, groupId }) => {
    const [qtdChild, setQtdChild] = useState(0);
    const groups = getValues('options')?.filter((e) => e.qtd > 0) || [];
    const maxItemsGroup = [];
    listItems.forEach((el) => {
      groups.forEach((e) => {
        if (el.name === e.nome) {
          maxItemsGroup.push(e);
        }
      });
    });

    React.useEffect(() => {
      sumItensHandle();
    }, [qtdChild]);

    return (
      <>
        <Stack
          direction="row"
          spacing="15px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing="15px" alignItems="center">
            <Image
              src={
                item?.image && !item.image.includes('product_no_photo.png')
                  ? item.image
                  : imgLogo
              }
              width="35px"
              height="35px"
            />
            <div>
              <Text>{item?.name}</Text>
              {item?.price && (
                <Text variant="caption">
                  {Number(item?.price)?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              )}
            </div>
          </Stack>
          <Stack
            direction="row"
            spacing="5px"
            alignItems="center"
            style={{
              width: 'fit-content',
              border: '1px solid #DAE5F2',
              borderRadius: '5px',
              padding: '0 5px',
            }}
          >
            <Button
              disabled={getValues(`${name}.${index}.qtd`) <= 0}
              size="lg"
              style={{ padding: 7 }}
              icon
              type="button"
              onClick={() => {
                const min =
                  Number(getValues(`${name}.${index}.qtd`)) ===
                  Number(item.limit_min)
                    ? Number(item.limit_min)
                    : 1;
                getValues(`${name}.${index}.qtd`) >= 1 &&
                  setQtdChild(qtdChild - 1);
                setValue(`${name}.${index}`, {
                  id: Number(item.id),
                  groupId: Number(groupId),
                  nome: item.name,
                  qtd:
                    (getValues(`${name}.${index}.qtd`)
                      ? getValues(`${name}.${index}.qtd`)
                      : 1) - min,
                  preco: parseFloat(item.price),
                });
              }}
            >
              <HiMinusSm />
            </Button>
            <Input
              type="number"
              style={{ width: 15, textAlign: 'center', border: 'none' }}
              name={item.nome}
              value={getValues(`${name}.${index}.qtd`) || 0}
              onChange={(e) => {
                const inputValue = parseInt(e.target.value, 10);
                setQtdChild(inputValue);
                setValue(`${name}.${index}.qtd`, inputValue);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <Button
              size="lg"
              icon
              type="button"
              style={{ color: '#0A7EFB', padding: 5 }}
              onClick={() => {
                const value = Number(getValues(`${name}.${index}.qtd`)) || 0;
                if (
                  maxItemsGroup.length < Number(maxItem) ||
                  maxItemsGroup.find((e) => e.nome === item.name)
                ) {
                  if (value + 1 <= Number(item.limit_max)) {
                    setQtdChild(qtd + 1);
                    setValue(`${name}.${index}`, {
                      id: Number(item.id),
                      groupId: Number(groupId),
                      nome: item.name,
                      qtd:
                        (getValues(`${name}.${index}.qtd`)
                          ? getValues(`${name}.${index}.qtd`)
                          : Number(item.limit_min) - 1) + 1,
                      preco: parseFloat(item.price),
                    });
                  }
                }
              }}
            >
              <HiPlusSm size="20px" />
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <>
      <CSSTransition in={open} timeout={300} classNames="fade" unmountOnExit>
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modalbottom"
        unmountOnExit
      >
        <ModalBottom>
          <ModalBody>
            <ModalHead>
              <Stack direction="row" spacing="15px" alignItems="center">
                <BiChevronDown
                  size="35"
                  color={`rgba(var(--${themeColor.color}), 1)`}
                  onClick={close}
                />
                <Text variant="title">Monte seu pedido</Text>
              </Stack>
            </ModalHead>
            <Box>
              <CardItem data={productSel} />
            </Box>
            <form>
              <div style={{ width: '100%' }}>
                {productSel &&
                  productSel.options &&
                  productSel.options?.map((item) => {
                    return (
                      <FormItem key={item?.id}>
                        <Label>
                          <p>Escolha as opções: {item.name}</p>
                          {item.limit_max !== '0' && (
                            <Text variant="small">
                              {Number(item.limit_min) > 0
                                ? `No mínimo ${item.limit_min} ${
                                    Number(item.limit_min) === 1
                                      ? 'opcional'
                                      : 'opcionais'
                                  }, no máximo ${item.limit_max} ${
                                    Number(item.limit_max) === 1
                                      ? 'opcional.'
                                      : 'opcionais.'
                                  }`
                                : `No máximo ${item.limit_max} ${
                                    Number(item.limit_max) === 1
                                      ? 'opcional.'
                                      : 'opcionais.'
                                  }`}
                            </Text>
                          )}
                        </Label>
                        <Stack direction="column" spacing="8px">
                          {item?.list?.map((list) => {
                            const index = itemIndex;
                            itemIndex += 1;
                            return (
                              <ItemChoise
                                name="options"
                                index={index}
                                key={list.id}
                                item={list}
                                maxItem={item.limit_max}
                                listItems={item.list}
                                groupId={item.id}
                              />
                            );
                          })}
                        </Stack>
                      </FormItem>
                    );
                  })}
                <Divider style={{ marginTop: 15 }} />
                <FormItem>
                  <Text gutter="8px">Observações</Text>
                  <Textarea
                    onChange={(e) => textareaResize(e)}
                    placeholder="Por exemplo: retirar a cebola"
                    {...register('observation')}
                  />
                </FormItem>
              </div>
            </form>

            <ModalFooter>
              <Stack
                direction="row"
                spacing="15px"
                padding="0 0 20px 0"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  spacing="5px"
                  alignItems="center"
                  style={{
                    width: 'fit-content',
                    border: '1px solid #DAE5F2',
                    borderRadius: '5px',
                    padding: '0 5px',
                  }}
                >
                  <Button
                    disabled={qtd <= 1}
                    size="lg"
                    style={{ padding: 7 }}
                    icon
                    onClick={() => {
                      qtd >= 1 && setQtd(qtd - 1);
                      setValue('qtd', qtd - 1);
                    }}
                    type="button"
                  >
                    <HiMinusSm />
                  </Button>
                  <Input
                    type="number"
                    style={{
                      width: 15,
                      padding: 7,
                      textAlign: 'center',
                      border: 'none',
                    }}
                    value={qtd}
                    disabled
                    {...register('qtd', {
                      valueAsNumber: true,
                      onChange: (e) => setQtd(parseInt(e.target.value, 10)),
                    })}
                  />
                  <Button
                    style={{
                      color: `rgba(var(--${themeColor.color}), 1)`,
                      padding: 5,
                    }}
                    size="lg"
                    icon
                    onClick={() => {
                      setQtd(qtd + 1);
                      setValue('qtd', qtd + 1);
                    }}
                    type="button"
                  >
                    <HiPlusSm size="20px" />
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  spacing="20px"
                  alignItems="center"
                  style={{ width: 'fit-content' }}
                >
                  <Text variant="caption">
                    {sumTotal?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                  <Button
                    variant="solid"
                    size="lg"
                    type="button"
                    onClick={() => {
                      setValue('id', product);
                      releaseSubmit();
                    }}
                  >
                    {edit ? 'Atualizar' : 'Adicionar'}
                  </Button>
                </Stack>
              </Stack>
            </ModalFooter>
          </ModalBody>
        </ModalBottom>
      </CSSTransition>
      <>
        {visibleWarningMode === true ? (
          <WarningModal
            text={warningModalValues[0]}
            img={warningModalValues[1]}
            animation={modalAnimation}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
}

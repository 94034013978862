import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;

export const ContainerDeliveries = styled.div`
  box-sizing: border-box;
  width: 90%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
  flex-direction: column;
  padding: 40px 20px;
  overflow-y: auto;
  margin: 10px 0px 60px 0px;
`;

export const CardDelivery = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  width: 100%;
  max-width: 300px;
  min-height: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  border-radius: 8px;

  transition: all 0.2s ease-in-out;
  border: 2px dashed transparent;
  :hover {
    border: 2px dashed green;
  }
`;

export const CardHeader = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  padding: 10px;
  color: ${(props) => props.color};
  > h2 {
    margin: 0;
    font-size: 16px;
  }
`;

export const CardBody = styled.div`
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  color: #808080;

  padding: 10px;

  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;

  flex-direction: column;

  gap: 10px;

  > div {
    position: absolute;
    right: 10px;
    bottom: 0px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    box-shadow: 0 0 5px gray;
  }
`;

export const CardFooter = styled.div`
  box-sizing: border-box;
  background: #f2f2f2;

  padding: 10px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 12px;
    color: gray;
  }

  cursor: pointer;
`;

export const Filter = styled.div<any>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  flex-direction: column;

  gap: 10px;

  color: gray;

  height: ${(props) => props.height};

  background: rgba(0, 0, 0, 0);
  z-index: 1;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FilterList = styled.div<any>`
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  animation: ${fadeIn} 0.5s ease;

  gap: 20px;

  > button {
    font-size: 12px;
    padding: 10px 20px;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    background: #3276c3;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;

    cursor: pointer;

    :hover {
      z-index: 1;
      transform: scale(1.05);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }

  display: ${(props) => props.display};
`;

import styled from 'styled-components';

export const Tag = styled.span<any>`
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
  background-color: ${(props) =>
    props.backgroundColor || 'rgba(0, 13, 255, 0.624)'};
  margin: 0px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React from 'react';

export default function IcClientsFilled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Bold"
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M22.5,15.5A1.5,1.5,0,0,0,21,17v1.5A2.5,2.5,0,0,1,18.5,21H17a1.5,1.5,0,0,0,0,3h1.5A5.506,5.506,0,0,0,24,18.5V17A1.5,1.5,0,0,0,22.5,15.5Z" />
      <path d="M1.5,8.5A1.5,1.5,0,0,0,3,7V5.5A2.5,2.5,0,0,1,5.5,3H7A1.5,1.5,0,0,0,7,0H5.5A5.506,5.506,0,0,0,0,5.5V7A1.5,1.5,0,0,0,1.5,8.5Z" />
      <path d="M7,21H5.5A2.5,2.5,0,0,1,3,18.5V17a1.5,1.5,0,0,0-3,0v1.5A5.506,5.506,0,0,0,5.5,24H7a1.5,1.5,0,0,0,0-3Z" />
      <path d="M18.5,0H17a1.5,1.5,0,0,0,0,3h1.5A2.5,2.5,0,0,1,21,5.5V7a1.5,1.5,0,0,0,3,0V5.5A5.506,5.506,0,0,0,18.5,0Z" />
      <circle cx="12" cy="7.5" r="3.5" />
      <path d="M6.11,17.848A.993.993,0,0,0,7.1,19h9.8a.993.993,0,0,0,.992-1.152,6,6,0,0,0-11.78,0Z" />
    </svg>
  );
}

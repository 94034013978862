/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const Dot = styled.div`
  border-radius: 50%;
  padding: 6px;
  background-color: rgba(var(--${(props) => props.theme.color}), 1);
  font-size: 11px;
  font-weight: 700;
  line-height: 0.6;
  color: #ffffff;
`;

export const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  padding: 20px;
  z-index: 109;
  background-color: #ffffff;
  padding: 15px 0;
  border-top: 1px solid #dae5f2;
`;

export const ModalHead = styled.div`
  background-color: #ffffff;
  padding: 15px 0 13px;
  margin-top: -15px;
  border-bottom: 1px solid #dae5f2;
  position: sticky;
  top: 0;
  z-index: 110;
`;
export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(var(--${(props) => props.theme.color}), 0.2);
  z-index: 110;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalBottom = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 110;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 100px;
  min-height: calc(100vh - 140px);
  width: -webkit-fill-available;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
`;

export const Box = styled.div`
  width: -webkit-fill-available;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.background};
  text-align: ${(props) => props.textAlign};
  align-items: ${(props) => props.alignItems || `center`};
  justify-content: ${(props) => props.justifyContent || `flex-start`};
`;

export const Stack = styled.div`
  width: -webkit-fill-available;
  flex-direction: ${(props) => props.direction || `column`};
  padding: ${(props) => props.padding};
  display: inline-flex;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  align-items: ${(props) => props.alignItems || `center`};
  justify-content: ${(props) => props.justifyContent || `flex-start`};
  gap: ${(props) => props.spacing};
  & > div {
    border-bottom: ${(props) => (props.divider ? '1px solid #DAE5F1' : '')};
  }
  & > div:last-child {
    border-bottom: 0;
  }
`;

export const ResumeBag = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  width: -webkit-fill-available;
  padding: 15px 20px 15px 20px;
  border-top: 1px solid #dae5f2;
  box-shadow: 0 -15px 30px -10px #054a9415;
  z-index: 99;
`;

export const DrawerFooter = styled.div`
  background-color: #ffffff;
  padding: 15px 20px 40px 20px;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #dae5f2;
  z-index: 100;
`;

export const Label = styled.div`
  border-radius: 5px;
  background-color: #ebebeb;
  width: -webkit-fill-available;
  padding: 8px 13px;
  font-weight: 700;
  font-size: 14px;
  color: #1c1e1f;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormItem = styled.div`
  position: relative;
  padding: 20px 0;
  & > ${Label} {
    position: sticky;
    top: 80px;
    z-index: 100;
  }
`;

export const Card = styled.div`
  width: -webkit-fill-available;
  padding: 25px 0;
  background-color: transparent;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dae5f2;
`;

export const Image = styled.img`
  object-fit: cover;
  border-radius: 5px;
`;

export const InputIcon = styled.div`
  position: relative;
  & > input {
    ${(props) =>
      props.align === 'left' &&
      css`
        padding-left: 40px;
      `}
  }
  & > div,
  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    ${(props) =>
      props.align === 'right'
        ? css`
            right: 15px;
          `
        : props.align === 'left'
        ? css`
            left: 15px;
          `
        : css`
            right: 10px;
          `}
  }
`;

export const Textarea = styled.textarea`
  font-weight: 400;
  resize: none;
  border-radius: 5px;
  transition: 250ms all ease;
  cursor: pointer;
  border: 1px solid;
  border-color: #dae5f2;
  padding: 9px 15px;
  line-height: 1.8;
  font-size: 0.875rem;
  width: -webkit-fill-available;
  color: #00274d;
  &::placeholder {
    color: #bfc8d2;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px
      rgba(var(--${(props) => props.theme.color}), 1);
    border-color: rgba(var(--${(props) => props.theme.color}), 1);
  }
`;

export const RadioCheck = styled.input`
  border: initial;
  appearance: radio;
  transform: scale(1.45);
  margin: 5px;
`;

export const Input = styled.input`
  font-weight: 400;
  border-radius: 5px;
  transition: 250ms all ease;
  cursor: pointer;
  border: 1px solid;
  background-color: #ffffff;
  border-color: #dae5f2;
  padding: 9px 15px;
  line-height: 1.8;
  font-size: 0.875rem;
  width: -webkit-fill-available;
  color: #00274d;
  &::placeholder {
    color: #bfc8d2;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px
      rgba(var(--${(props) => props.theme.color}), 1);
    border-color: rgba(var(--${(props) => props.theme.color}), 1);
  }

  ${(props) =>
    props.error &&
    css`
      box-shadow: inset 0px 0px 0px 1px #f53f3e;
      border-color: #f53f3e;
    `}
  ${(props) =>
    props.size === 'lg' &&
    css`
      padding: 9px 15px;
      line-height: 2;
      font-size: 0.975rem;
    `}
  ${(props) =>
    props.size === 'md' &&
    css`
      padding: 5px 13px;
      line-height: 1.8;
      font-size: 0.875rem;
    `}
  ${(props) =>
    props.size === 'xs' &&
    css`
      padding: 3px 11px;
      line-height: 1.6;
      font-size: 0.775rem;
    `}
`;

export const Tabs = styled.div`
  z-index: 100;
  padding: 0 20px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Button = styled.button`
  min-width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 700;
  border-radius: 5px;
  text-transform: uppercase;
  transition: 250ms all ease;
  cursor: pointer;
  border: 1px solid;
  border-color: transparent;
  padding: ${(props) => (props.padding ? props.padding : '5px 20px')};
  line-height: 1.8;
  font-size: ${(props) => (props.size ? props.size : '0.8rem')};
  ${(props) =>
    props.disabled === true &&
    css`
      cursor: not-allowed;
    `}
  ${(props) =>
    props.size === 'lg' &&
    css`
      padding: 6px 24px;
      line-height: 2;
      font-size: 0.975rem;
    `}
  ${(props) =>
    props.size === 'md' &&
    css`
      padding: 5px 20px;
      line-height: 1.8;
      font-size: 0.875rem;
    `}
  ${(props) =>
    props.size === 'xs' &&
    css`
      padding: 3px 16px;
      line-height: 1.6;
      font-size: 0.775rem;
    `}
  ${(props) =>
    props.icon === true &&
    css`
      padding-left: 15px;
      padding-right: 15px;
    `};
  ${(props) =>
    props.variant === 'solid' &&
    css`
      border: 1px solid;
      border-color: rgba(var(--${props.theme.color}), 1);
      background-color: rgba(var(--${props.theme.color}), 1);
      color: #ffffff;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: rgba(var(--${props.theme.color}2), 1);
        }
      }
      &:active {
        background-color: rgba(var(--${props.theme.color}3), 1);
        border-color: rgba(var(--${props.theme.color}), 1);
      }
    `};
  ${(props) =>
    props.variant === 'outline' &&
    css`
      border: 1px solid;
      border-color: rgba(var(--${props.theme.color}3), 1);
      color: rgba(var(--${props.theme.color}3), 1);
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: rgba(var(--${props.theme.color}), 0.15);
          border-color: rgba(var(--${props.theme.color}3), 1);
        }
      }
      &:active {
        background-color: rgba(var(--${props.theme.color}), 0.3);
        border-color: rgba(var(--${props.theme.color}3), 1);
      }
    `};
  ${(props) =>
    props.variant === 'ghost' &&
    css`
      background-color: rgba(var(--${props.theme.color}), 0.15);
      color: rgba(var(--${props.theme.color}3), 1);
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: rgba(var(--${props.theme.color}), 0.25);
        }
      }
      &:active {
        background-color: rgba(var(--${props.theme.color}), 0.4);
      }
    `};
  ${(props) =>
    props.variant === 'link' &&
    css`
      background-color: transparent;
      color: rgba(var(--${props.theme.color}3), 1);
      &:hover {
        text-decoration: underline;
      }
    `};
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: ${(props) => props.gutter && props.gutter};
  ${(props) =>
    props.variant === 'title' &&
    css`
      font-size: 18px;
      font-weight: 700;
      line-height: 1.1;
    `};
  ${(props) =>
    props.variant === 'heading' &&
    css`
      font-size: 16px;
      font-weight: 700;
    `};
  ${(props) =>
    props.variant === 'small' &&
    css`
      font-size: 12px;
      font-weight: 300;
    `};
  ${(props) =>
    props.variant === 'caption' &&
    css`
      font-size: 12px;
      font-weight: 700;
      color: rgba(var(--${props.theme.color}), 1);
    `};
  ${(props) =>
    props.variant === 'caption-md' &&
    css`
      font-size: 14px;
      font-weight: 700;
      color: rgba(var(--${props.theme.color}), 1);
    `};
  color: ${(props) => props.color && props.color};
`;

export const Logo = styled.img`
  background: #fafafa;
  border: 1px solid;
  box-sizing: border-box;
  box-shadow: 0px -5px 30px -10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  object-fit: cover;
  margin-top: -50px;
  border-color: #dae5f2;
  min-width: 100px;
  min-height: 100px;
`;

export const Tag = styled.div`
  border-radius: 4px;
  padding: 2px 9px;
  font-size: 11px;
  ${(props) =>
    props.color === 'red' &&
    css`
      background-color: #ef4646;
      color: #ffe2df;
    `};
  ${(props) =>
    props.color === 'green' &&
    css`
      background-color: #6acd7a;
      color: #e3fbe7;
    `};
`;

export const Container = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  background-color: #ffffff;
  border-radius: 30px 30px 0 0;
`;

export const Alert = styled.div`
  background-color: rgba(var(--${(props) => props.theme.color}), 0.15);
  color: rgba(var(--${(props) => props.theme.color}), 1);
  padding: 15px 25px;
  border-radius: 10px;
  text-align: center;
`;

export const CustomerStack = styled(Stack)`
  @media screen and (max-width: 660px) {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
  }
`;

export const StackPosition = styled.div`
  position: relative;
  > a {
    position: absolute;
    top: -25px;
    right: -10px;
  }
`;

import React, { FC, InputHTMLAttributes } from 'react';

import { InputField, InputIcon, Icon, InputDiv } from './style';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  icon?: any;
  id?: string;
  full?: boolean;
  ref?: any;
  onChange?: any;
  value?: any;
  defaultValue?: any;
  as?: React.ElementType | keyof JSX.IntrinsicElements | undefined;
}

const Input: FC<Props> = ({
  id,
  ref,
  icon,
  error,
  onChange,
  defaultValue,
  value = defaultValue,
  ...rest
}) => {
  return (
    <>
      <InputDiv error={error} ref={ref}>
        {icon ? (
          <InputIcon>
            <Icon>{icon}</Icon>
            <InputField onChange={onChange} value={value} {...rest} />
          </InputIcon>
        ) : (
          <InputField onChange={onChange} value={value} {...rest} />
        )}
      </InputDiv>
    </>
  );
};

export default Input;

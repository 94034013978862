/* eslint-disable react/style-prop-object */
import React from 'react';

export default function Bag({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      id="bag-alt"
      fill={color}
    >
      <path d="M21.87,19.78l-1.78-16A2,2,0,0,0,18.1,2H5.9a2,2,0,0,0-2,1.78l-1.78,16a2,2,0,0,0,.5,1.55A2,2,0,0,0,4.12,22H19.88a2,2,0,0,0,1.49-.67A2,2,0,0,0,21.87,19.78ZM16,9A4,4,0,0,1,8,9V7a1,1,0,0,1,2,0V9a2,2,0,0,0,4,0V7a1,1,0,0,1,2,0Z" />
    </svg>
  );
}

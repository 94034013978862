/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Upload = styled.label`
  width: -webkit-fill-available;
  border-radius: 15px;
  border: 2px dashed #e3e8eb;
  background: #e3e8eb30;
  padding: 15px;
  cursor: pointer;
  & input[type='file'] {
    display: none;
  }
`;

export default Upload;

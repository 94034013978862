import styled from 'styled-components';

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  color: ${(props) => props.theme.text};
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e4e8eb;

  button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    position: absolute;
    z-index: 1;
    background: #3276c3;
    padding: 10px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      z-index: 2;
      transform: scale(1.05);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
`;

export const SummaryValues = styled.div`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  gap: 10px;

  font-size: 12px;
  color: ${(props) => props.theme.text};

  > h3 {
    margin: 0px;
    font-weight: bold;
  }

  > div {
    width: 100%;

    display: flex;
    align-items: start;
    justify-content: space-between;
  }
`;

export const ContObservation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color};
  background-color: ${(props) => props.background};

  gap: 3px;

  border-radius: 8px;
  padding: 10px;

  border: 2px ${(props) => props.color} dashed;

  word-break: break-all;
  line-height: 1.5;
  word-spacing: 0.2px;
  font-size: ${(props) => props.size};
`;

/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

interface Props {
  weight?: string;
  align?: string;
  gutter?: string;
  color?: string;
  variant?: 'title' | 'subtitle' | 'small' | 'label';
  full?: boolean;
  short?: boolean;
}

export const Typograph = styled.span<Props>`
  font-weight: ${(props) => props.weight || '300'};
  text-align: ${(props) => props.align || 'left'};
  font-size: 0.8rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: ${(props) => props.gutter || 0};
  color: ${(props) => props.color || props.theme.text};
  width: ${(props) => (props.full ? '100%' : 'auto')};

  ${(props) =>
    props.short &&
    css`
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.variant === 'title' &&
    css`
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.4;
    `}
  ${(props) =>
    props.variant === 'subtitle' &&
    css`
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 1.5;
    `}
  ${(props) =>
    props.variant === 'small' &&
    css`
      font-weight: 400;
      font-size: 0.7rem;
      line-height: 1;
      opacity: 0.6;
    `}
  ${(props) =>
    props.variant === 'label' &&
    css`
      font-weight: 600;
      font-size: 0.7rem;
      line-height: 1;
      opacity: 1;
      text-transform: uppercase;
      margin-top: 14px;
    `}
`;

export default Typograph;

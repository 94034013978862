import axios from 'axios';

const token = window.localStorage.getItem('token');

const fetcher = (url: string, tokenLS: string) =>
  axios
    .get(url, { headers: { Authorization: `Bearer ${tokenLS}` } })
    .then((res) => res.data);

export default fetcher;
export { token };

/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface StackProps {
  direction?: string;
  padding?: string;
  wrap?: boolean;
  align?: string;
  justify?: string;
  spacing?: string;
  size?: string;
  height?: string;
  width?: string;
  responsive?: boolean;
  mobileDirection?: string;
  mobileAlign?: string;
  mobileJustify?: string;
}
export const StackDiv = styled.div<StackProps>`
  width: ${(props) => props.width || '-webkit-fill-available'};
  height: ${(props) => props.height || 'auto'};
  position: relative;
  flex-direction: ${(props) => props.direction || 'column'};
  padding: ${(props) => props.padding};
  display: inline-flex;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  align-items: ${(props) => props.align || `flex-start`};
  justify-content: ${(props) => props.justify || `flex-start`};
  gap: ${(props) => props.spacing};
  flex-basis: ${(props) => props.size};

  @media (max-width: 767px) {
    ${(props) =>
      props.responsive &&
      `
        flex-direction: ${props.mobileDirection || 'column'};
        align-items: ${props.mobileAlign || `flex-start`};
        justify-content: ${props.mobileJustify || `flex-start`};
      `}
  }
`;

export default StackDiv;

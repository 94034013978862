import React, { useEffect } from 'react';

import { DropDownMenu } from './style';

const DropDown: React.FC<any> = ({ children, ...props }) => {
  const ref = React.useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (props.state && ref.current && !ref.current.contains(e.target)) {
        props.set(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [props, props.state]);

  return (
    <>
      {props.state && (
        <DropDownMenu ref={ref} {...props}>
          {children}
        </DropDownMenu>
      )}
    </>
  );
};
export default DropDown;

import React, { useState } from 'react';
import Lottie from 'react-lottie';

import loadingLottie from '../../assets/lotties/loading_white.json';

import DropDown from '../DropDown';
import { ButtonCustom } from './style';

const Button: React.FC<{
  onClick?: () => void;
  onDragStart?: (e: any) => void;
  onDrop?: (e: any) => void;
  onDragOver?: (e: any) => void;
  draggable?: boolean;
  dropDown?: any;
  loading?: any;
  style?: any;
  variant?:
    | 'primary'
    | 'actived_menu'
    | 'ghost'
    | 'menu'
    | 'outline'
    | 'link'
    | 'dangerous';
  full?: boolean;
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  shape?: string;
  mode?: string;
  distance?: number;
  activeMenu?: boolean;
  badge?: string;
  type?: 'submit' | 'reset' | 'button';
}> = ({ children, ...props }) => {
  const { ...rest } = props;
  const [dropDown, setDropDrown] = useState<boolean>(false);

  function clickButton() {
    setDropDrown(!dropDown);
    props.onClick && props.onClick();
  }
  return (
    <span style={{ position: 'relative', width: props.full ? '100%' : 'auto' }}>
      <ButtonCustom {...rest} onClick={clickButton || {}} badge={props.badge}>
        {props.loading ? (
          <div style={{ margin: '-16px 0' }}>
            <Lottie
              options={{ animationData: loadingLottie }}
              height={48}
              width={96}
            />
          </div>
        ) : (
          children
        )}
      </ButtonCustom>
      {props.dropDown && (
        <DropDown set={setDropDrown} state={dropDown}>
          {props.dropDown}
        </DropDown>
      )}
    </span>
  );
};
export default Button;

import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import CardOrder from '../../components/CardOrder';
import { ColumnBoard } from '../../styles/style';
import { Text } from '../../lib';
import { ColumnInfoContainer } from '../../components/CardOrder/style';
import { colorsStepsCard } from '../../components/Modals/Orders';

const sumColumnValues = (e: any) => {
  let sum = 0;

  e?.forEach((el: any) => {
    sum += el.products.reduce((accumulator: number, currentValue: any) => {
      return accumulator + Number(currentValue.price);
    }, 0);
  });

  return sum.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const DraggableElement = ({
  column,
  prefix,
  elements,
  loading,
  activateMouse,
  mouse,
  disableMouse,
  minimize,
  setValueSelect,
  setModalCanceled,
  updateData,
}) => (
  <ColumnBoard
    borderColor={
      colorsStepsCard?.find(
        (e) => column?.name.toLowerCase() === e.name.toLowerCase(),
      )?.color
    }
    backgroundColor={
      colorsStepsCard?.find(
        (e) => column?.name.toLowerCase() === e.name.toLowerCase(),
      )?.backgroundColor
    }
    onMouseEnter={(e) => mouse(e)}
    onMouseUp={disableMouse}
  >
    <ColumnInfoContainer background="#c8c8c84d" size="14px">
      <Text variant="label" style={{ margin: 0, paddingBottom: 0 }}>
        {column.name}
      </Text>
      <span>
        Total:
        {elements?.length < 10 && elements?.length > 0
          ? ` 0${elements?.length}`
          : ` ${elements?.length}`}
      </span>
      <span>{sumColumnValues(elements)}</span>
    </ColumnInfoContainer>
    <Droppable droppableId={`${prefix}`}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ height: '100%' }}
        >
          {elements?.map((item, index) => (
            <CardOrder
              key={item.id}
              item={item}
              index={index}
              loading={loading}
              status={column}
              activateMouse={activateMouse}
              disableMouse={disableMouse}
              minimize={minimize}
              setModalCanceled={setModalCanceled}
              setValueSelect={setValueSelect}
              updateData={updateData}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </ColumnBoard>
);

export default DraggableElement;

/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import Lottie from 'react-lottie';
import { Image, Input, Modal, Stack, Tag, Text } from '../../lib';
import { Dot } from '../../styles/style';
import {
  CardBoard,
  ContObservation,
  HiddenTextContainer,
  CommandItem,
  TextCommands,
  SummaryValues,
  ContainerText,
  ContOptions,
  ContCommand,
} from './style';
import loadingLottie from '../../assets/lotties/loading_data.json';
import api from '../../services/api';
import ProductCard from './product';
import { colorsSteps, backgroundColors, addPrice } from '../Modals/Orders';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

function CardOrder({
  item,
  index,
  status,
  loading,
  activateMouse,
  disableMouse,
  minimize,
  setModalCanceled,
  setValueSelect,
  updateData,
}): JSX.Element {
  const [modal, setModal] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [user, setUser] = useState<{ name: string; picture: string }>({
    name: 'Sem Nome',
    picture: '',
  });

  const [statusPayment, setStatusPayment] = useState<string>('none');
  const [statusLastLog, setStatusLastLog] = useState<string>('none');
  const [deliveryFee, setDeliveryFee] = useState<string>('');

  // eslint-disable-next-line no-eval
  const address = eval(item?.address);

  const getUser = async () => {
    setUser(item?.user);
  };

  const lists = [
    {
      id: 1,
      state: 'IN_QUEUE',
      name: 'Na Fila',
    },
    { id: 2, state: 'IN_PRODUCTION', name: 'Em Produção' },
    { id: 3, state: 'DELIVERY_QUEUE', name: 'Fila de Entregas' },
    { id: 4, state: 'EN_ROUTE', name: 'A caminho' },
    { id: 5, state: 'DELIVERED', name: 'Entregue' },
    { id: 6, state: 'CANCELED', name: 'Cancelados' },
  ];

  useEffect(() => {
    getUser();
    getPaymentMethods();
    getDeliveryFee();
  }, []);

  const statusChange = (value, updater) => {
    const currentStatus = value;
    const newStatus = currentStatus === 'none' ? 'block' : 'none';

    if (updater === 0) {
      setStatusPayment(newStatus);
    } else if (updater === 1) {
      setStatusLastLog(newStatus);
    }
  };

  async function getPaymentMethods() {
    await api.get('rest/payment_gateway.php').then((res) => {
      setPaymentMethods(res.data);
    });
  }

  async function getDeliveryFee() {
    await api.get('rest/options.php/delivery').then((res) => {
      setDeliveryFee(res.data.delivery);
    });
  }

  const countCategory = (products) => {
    const list = products.map((e) => {
      return e?.category?.name;
    });

    const uniqueCategories = list.filter((category, i, array) => {
      return array.indexOf(category) === i;
    });

    return uniqueCategories.length || 0;
  };

  return (
    <>
      <Draggable draggableId={item?.id} index={index}>
        {(provided, snapshot) => {
          return (
            <CardBoard
              ref={provided.innerRef}
              snapshot={snapshot}
              loading={loading}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={() => setModal(true)}
              onMouseDown={activateMouse}
              onMouseUp={disableMouse}
              key={item.id}
              borderLeft={
                countCategory(item?.products) > 1
                  ? 'false'
                  : `3px solid ${
                      JSON.parse(item?.products[0]?.category?.options)?.color
                    }`
              }
            >
              {loading && (
                <div style={{ position: 'absolute' }}>
                  <Lottie
                    options={{ animationData: loadingLottie }}
                    style={{ filter: 'saturate(0)', opacity: 1 }}
                    height={90}
                    width={250}
                  />
                </div>
              )}
              <Stack direction="row" justify="space-between" align="center">
                <Text variant="label" style={{ margin: 0 }} gutter="8px">
                  {user?.name}
                </Text>
                <Text variant="label" style={{ margin: 0 }} gutter="8px">
                  #{item?.id}
                </Text>
              </Stack>
              {!minimize ? (
                <>
                  {item?.observation ? (
                    <Stack direction="column" spacing="5px">
                      <ContObservation
                        size="12px"
                        background="#FEB70C30"
                        color="#e49f00"
                      >
                        <p>
                          <strong>Observação Geral: </strong>
                          {item?.observation}
                        </p>
                      </ContObservation>
                    </Stack>
                  ) : (
                    ''
                  )}
                  <Stack
                    direction="column"
                    justify="start"
                    spacing="15px"
                    align="start"
                  >
                    {item?.products.map((e) => {
                      return (
                        <ContCommand key={e.id}>
                          <CommandItem>
                            <span>{e.amount}</span>
                            {e.observation ? (
                              <TextCommands color="#e49f00">
                                {e.name.charAt(0).toUpperCase() +
                                  e.name.slice(1).toLowerCase()}
                              </TextCommands>
                            ) : (
                              <TextCommands>
                                {e.name.charAt(0).toUpperCase() +
                                  e.name.slice(1).toLowerCase()}
                              </TextCommands>
                            )}
                          </CommandItem>
                          {e?.options ? (
                            <ContOptions>
                              {e.options.map((el) => (
                                <div key={el.id}>
                                  <span>{el.qtd}</span>
                                  <p>{el.nome}</p>
                                </div>
                              ))}
                            </ContOptions>
                          ) : (
                            ''
                          )}
                        </ContCommand>
                      );
                    })}
                  </Stack>
                </>
              ) : (
                <></>
              )}

              <Stack direction="row" justify="space-between" align="center">
                <HiddenTextContainer
                  size="14px"
                  onMouseEnter={() => statusChange(statusLastLog, 1)}
                  onMouseLeave={() => statusChange(statusLastLog, 1)}
                  display={statusLastLog}
                >
                  <Stack>
                    <Image
                      src={
                        item?.user?.name === item?.last_log?.user?.name &&
                        item?.last_log?.type === 'WAITING_FOR_CONFIRMATION'
                          ? imgLogo
                          : item?.last_log?.user?.picture === null ||
                            item?.last_log?.user?.picture === 'null'
                          ? imgLogo
                          : item?.last_log?.user?.picture
                      }
                      width="24px"
                      height="24px"
                      borderRadius="50%"
                      size="9px"
                    />
                  </Stack>

                  <span>
                    {item.user.name === item.last_log.user.name &&
                    item.last_log.type === 'WAITING_FOR_CONFIRMATION'
                      ? 'Aguardando interação'
                      : item.last_log.user.name}
                  </span>
                </HiddenTextContainer>
                <HiddenTextContainer
                  size="14px"
                  onMouseEnter={() => statusChange(statusPayment, 0)}
                  onMouseLeave={() => statusChange(statusPayment, 0)}
                  display={statusPayment}
                >
                  <p>{addPrice(item.products)}</p>
                  <span>
                    {paymentMethods
                      ? paymentMethods.find(
                          (e) => e.id === item.payment_gateway,
                        )?.name || '...'
                      : ''}
                  </span>
                </HiddenTextContainer>
              </Stack>
            </CardBoard>
          );
        }}
      </Draggable>

      <Modal set={setModal} state={modal} title="Detalhes do Pedido">
        <Stack direction="column" spacing="15px">
          <Stack direction="row" align="center" spacing="15px">
            <Tag
              backgroundColor={backgroundColors[status.id - 1]}
              color={colorsSteps[status.id - 1]}
            >
              {status.name}
            </Tag>
            <Dot color={colorsSteps[status.id - 1]} size="5px" />
            <Text>{user.name}</Text>
          </Stack>
          <Stack align="start" spacing="15px">
            <Text variant="label">Etapa</Text>
            <Input
              full
              as="select"
              type="text"
              placeholder="Etapa do pedido!"
              defaultValue={
                Number(
                  lists?.findIndex((element) => {
                    return element?.state === item?.last_log?.type;
                  }),
                ) + 1 || 1
              }
              onChange={(e: any) => {
                if (Number(e.target.value) === 6) {
                  setValueSelect([item.id, e.target.value]);
                  setModalCanceled(true);
                  setModal(false);
                } else {
                  updateData(item.id, e.target.value, 'null');
                  setModal(false);
                }
              }}
            >
              {lists.map((elem, i) => {
                return (
                  <option key={elem.name} value={i + 1}>
                    {elem.name}
                  </option>
                );
              })}
            </Input>
          </Stack>
          <Stack direction="column" spacing="5px">
            {item?.last_log?.observation &&
            item?.last_log?.observation !== 'null' ? (
              <ContObservation size="12px" background="#ebcfcf" color="#8e0b0b">
                <p>
                  <strong>Motivo do cancelamento: </strong>
                  {item.last_log.observation}
                </p>
              </ContObservation>
            ) : (
              ''
            )}
          </Stack>
          <Stack direction="column" spacing="5px">
            {item?.observation ? (
              <ContObservation
                size="12px"
                background="#FEB70C30"
                color="#e49f00"
              >
                <p>
                  <strong>Observação Geral: </strong>
                  {item.observation}
                </p>
              </ContObservation>
            ) : (
              ''
            )}
          </Stack>
          <Stack>
            {item?.products.map((product, row) => (
              <Stack
                direction="row"
                justify="space-between"
                padding="10px 0"
                spacing="15px"
                key={product}
              >
                <ProductCard item={product} />
              </Stack>
            ))}
          </Stack>
          <SummaryValues>
            <h3>Resumo de valores</h3>
            <div>
              <p>Forma de pagamento</p>
              <p>
                {paymentMethods
                  ? paymentMethods.find((e) => e.id === item.payment_gateway)
                      ?.name || '...'
                  : ''}
              </p>
            </div>
            <div>
              <p>Taxa de Entrega</p>
              <p>
                {deliveryFee
                  ? Number(deliveryFee).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'Grátis'}
              </p>
            </div>
            <div>
              <p>Total</p>
              <p>{addPrice(item.products, Number(deliveryFee))}</p>
            </div>
          </SummaryValues>
          <ContainerText>
            <h3>Endereço de entrega</h3>
            <p>
              Rua: {address.rua}, Bairro: {address.bairro}, n°
              {address.numero},
              {address.complemento ? `Complemento: ${address.complemento}` : ''}
              , Cidade: {address.cidade} - {address.uf}, Cep:{address.cep}
            </p>
          </ContainerText>
          <ContainerText>
            <h3>Horário</h3>
            <p>{moment(item?.date).format('DD/MM/YYYY HH:mm')}</p>
          </ContainerText>
        </Stack>
      </Modal>
    </>
  );
}

export default CardOrder;

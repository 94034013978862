import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import { GlobalStyle } from './styles/style';
import { AuthProvider } from './contexts/AuthContext';
import RoutesApp from './routes.js';

import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      duration: 800,
      offset: 0,
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <BrowserRouter>
          <RoutesApp />
        </BrowserRouter>
      </AuthProvider>
    </>
  );
};

export default App;

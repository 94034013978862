/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Image, Modal, Stack, Tag, Text } from '../../../lib';
import api, { apiURL } from '../../../services/api';
import arrived from '../../../assets/icons/arrived.svg';
import { Dot } from '../../../styles/style';
import {
  InfoContainer,
  MapContainer,
  ButtonsContainer,
  SummaryValues,
  ContObservation,
} from './style';
import { useAuth } from '../../../contexts/AuthContext';
import { InputRadio, LabelRadio } from '../../../lib/Radio/style';
import TextArea from '../../../lib/TextArea';

export const colorsSteps = [
  '#8b8d8d',
  '#5fc1ce',
  '#ebc747',
  '#1d2091',
  '#0bc117',
  '#8e0b0b',
];

export const backgroundColors = [
  '#d3d5d5',
  '#dcf7f9',
  '#fdf8e3',
  '#d1d3f5',
  '#c1fbd7',
  '#ebcfcf',
];

export const colorsStepsCard = [
  {
    name: 'Na Fila',
    color: '#8b8d8d',
    backgroundColor: 'rgba(139, 141, 141, 0.2)',
  },
  {
    name: 'Em Produção',
    color: '#5fc1ce',
    backgroundColor: 'rgba(95, 193, 206, 0.2)',
  },
  {
    name: 'Fila de Entregas',
    color: '#ebc747',
    backgroundColor: 'rgba(235, 199, 71, 0.2)',
  },
  {
    name: 'A caminho',
    color: '#1d2091',
    backgroundColor: 'rgba(29, 32, 145, 0.2)',
  },
  {
    name: 'Entregue',
    color: '#0bc117',
    backgroundColor: 'rgba(11, 193, 23, 0.2)',
  },
  {
    name: 'Cancelados',
    color: '#8e0b0b',
    backgroundColor: 'rgba(142, 11, 11, 0.2)',
  },
];

export const lists = [
  { id: 3, state: 'DELIVERY_QUEUE', name: 'Fila de Entregas' },
  { id: 4, state: 'EN_ROUTE', name: 'A caminho' },
  { id: 5, state: 'DELIVERED', name: 'Entregue' },
  { id: 6, state: 'CANCELED', name: 'Cancelados' },
];

export const defaultCancellationTextList = [
  'Atraso na entrega do pedido',
  'Item faltante no pedido',
  'Problemas de qualidade com os produtos',
  'Insatisfação com o serviço prestado',
  'Opção de pagamento indisponível',
  'Pedido duplicado por engano',
  'Endereço de entrega incorreto',
  'Produtos indisponíveis após a realização do pedido',
  'Preços diferentes dos anunciados',
  'Problemas com a embalagem dos produtos',
];

export const addPrice = (products, delivery = 0) => {
  const result = products?.reduce(
    (acumulador, produto) => acumulador + Number(produto.price),
    0,
  );

  if (delivery !== 0) {
    return (result + delivery).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return result?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const ModalDeliveries = ({
  data,
  modal,
  setModal,
  paymentMethods,
  deliveryFee,
  setMonitoring,
  modalCanceled,
  setModalCanceled,
}) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const statusDelivery = ['EN_ROUTE', 'DELIVERED', 'CANCELED'];
  const { user } = useAuth();
  const updateData = async (index) => {
    const txt = getValues('reason');

    if (
      user?.configuration?.access?.couriers?.write ||
      user?.configuration?.access?.couriers?.exec
    ) {
      await api
        .put(`${apiURL}/rest/requests.php/${data.id}`, {
          state: statusDelivery[index],
          log_observation: txt || 'null',
        })
        .then(() => {
          setMonitoring((prev) => !prev);
          setModal(false);
          setModalCanceled(false);
          /*
          postWeekeBot(statusDelivery[index]);
          */
        });
    }
  };

  // WeekeBot
  /*
    const postWeekeBot = async (text) => {
    const data_delivery = {
      message: {
        status: text,
        user: {
          name: data?.user?.name,
          phone: data?.user?.phone,
        },
      },
    };

    try {
      const response = await fetch(
        'https://augusto.local.weeke.app/delivery/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data_delivery),
          mode: 'no-cors',
        },
      );
      console.log('Requisição enviada com sucesso!');
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  */
  return (
    <>
      <Modal set={setModal} state={modal} title="Detalhes do Pedido">
        <Stack direction="column" spacing="20px">
          <Stack direction="row" align="center" spacing="15px">
            <Tag
              backgroundColor={
                backgroundColors[
                  Number(lists?.find((e) => e.state === data?.state)?.id) - 1
                ]
              }
              color={
                colorsSteps[
                  Number(lists?.find((e) => e.state === data?.state)?.id) - 1
                ]
              }
            >
              {lists?.find((e) => e.state === data?.state)?.name}
            </Tag>
            <Dot
              color={
                colorsSteps[
                  Number(lists?.find((e) => e.state === data?.state)?.id) - 1
                ]
              }
              size="5px"
            />
            <Text>{data?.user?.name}</Text>
          </Stack>
          <Stack direction="column" spacing="5px">
            {data?.last_log?.observation &&
            data?.last_log?.observation !== 'null' ? (
              <ContObservation size="12px" background="#ebcfcf" color="#8e0b0b">
                <p>
                  <strong>Motivo do cancelamento: </strong>
                  {data.last_log.observation}
                </p>
              </ContObservation>
            ) : (
              ''
            )}
          </Stack>
          <InfoContainer>
            <p>{`Nome: ${data?.user?.name}`}</p>
            <p>{`Endereço: ${data?.address?.rua}, ${data?.address?.numero}, ${data?.address?.bairro}, ${data?.address?.cidade}-${data?.address?.uf}`}</p>
            <p>
              {`Complemento: ${
                data?.address?.complemento ? data?.address?.complemento : ''
              }`}
            </p>
          </InfoContainer>
          <MapContainer>
            <Image
              src="https://i.ibb.co/B4pRZ5y/map.png%22"
              width="100%"
              height="100%"
            />
            {data?.state === 'EN_ROUTE' ? (
              <button
                type="button"
                onClick={
                  () => console.log('Oii!') /*
                      postWeekeBot('DELIVERY_DRIVER_ON_SITE')
                    */
                }
                title="Avisar cliente"
              >
                <Image src={arrived || 'null'} width="36px" height="36px" />
              </button>
            ) : (
              ''
            )}
          </MapContainer>
          <SummaryValues>
            <h3>Resumo de valores</h3>
            <div>
              <p>Forma de pagamento</p>
              <p>
                {paymentMethods
                  ? paymentMethods.find((e) => e.id === data?.payment_gateway)
                      ?.name || '...'
                  : ''}
              </p>
            </div>
            <div>
              <p>Taxa de Entrega</p>
              <p>
                {deliveryFee
                  ? Number(deliveryFee).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'Grátis'}
              </p>
            </div>
            <div>
              <p>Total</p>
              <p>{addPrice(data?.products, Number(deliveryFee))}</p>
            </div>
          </SummaryValues>
          <ButtonsContainer>
            {data?.state === 'DELIVERY_QUEUE' ? (
              <>
                <Button
                  variant="ghost"
                  size="md"
                  onClick={() => {
                    updateData(0);
                    setModal(false);
                  }}
                >
                  Entregar
                </Button>
                <Button
                  variant="ghost"
                  size="md"
                  onClick={() => {
                    setModalCanceled(true);
                  }}
                >
                  Cancelar
                </Button>
              </>
            ) : data?.state === 'EN_ROUTE' ? (
              <>
                <Button
                  variant="ghost"
                  size="md"
                  onClick={() => {
                    updateData(1);
                    setModal(false);
                  }}
                >
                  Entregue
                </Button>
                <Button
                  variant="ghost"
                  size="md"
                  onClick={() => {
                    setModalCanceled(true);
                  }}
                >
                  Cancelar
                </Button>
              </>
            ) : null}
          </ButtonsContainer>
        </Stack>
      </Modal>

      <Modal
        set={setModalCanceled}
        state={modalCanceled}
        title="Formulário de Cancelamento"
        width="900px"
      >
        <Stack
          direction="column"
          justify="space-between"
          height="100%"
          spacing="25px"
        >
          <Stack direction="row" spacing="25px">
            <Stack direction="column" spacing="25px">
              {defaultCancellationTextList?.map((txt, index) => {
                if (index <= 4) {
                  return (
                    <LabelRadio key={index}>
                      <InputRadio
                        type="radio"
                        name="pedido-cancelado"
                        value={index + 1}
                        onChange={(e) =>
                          setValue(
                            'reason',
                            defaultCancellationTextList[
                              Number(e.target.value) - 1
                            ],
                          )
                        }
                      />
                      {txt}
                    </LabelRadio>
                  );
                }
                return null;
              })}
            </Stack>
            <Stack direction="column" spacing="25px">
              {defaultCancellationTextList?.map((txt, index) => {
                if (index > 4) {
                  return (
                    <LabelRadio key={index}>
                      <InputRadio
                        type="radio"
                        name="pedido-cancelado"
                        value={index + 1}
                        onChange={(e) =>
                          setValue(
                            'reason',
                            defaultCancellationTextList[
                              Number(e.target.value) - 1
                            ],
                          )
                        }
                      />
                      {txt}
                    </LabelRadio>
                  );
                }
                return null;
              })}
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing="30px"
            as="form"
            onSubmit={handleSubmit(() => updateData(2))}
          >
            <Controller
              control={control}
              name="reason"
              rules={{ required: true }}
              defaultValue={getValues('reason')}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <TextArea
                  full
                  placeholder="Digite o motivo do cancelamento do pedido..."
                  type="text"
                  value={value}
                  ref={ref}
                  error={error ? 'Campo obrigatorio' : ''}
                  onChange={(e) => {
                    onChange(`${e.target.value}`);
                  }}
                />
              )}
            />
            <Stack direction="row" spacing="15px">
              <Button variant="ghost" type="submit">
                Enviar
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

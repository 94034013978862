/* eslint-disable react/jsx-key */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { Image } from '../../lib';
import {
  ContDescription,
  Description,
  TextPrice,
  Card,
  Cont,
  ContTitle,
  ContObservation,
  ContOptionsModal,
} from './style';
import expand from '../../assets/icons/expand.svg';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

const ProductCard = (item: any) => {
  const [statusDescription, setStatusDescription] = useState('none');

  const toggleDescription = () => {
    let status = statusDescription;
    status = status === 'none' ? 'block' : 'none';
    setStatusDescription(status);
  };

  return (
    <>
      <Card>
        <Image
          src={
            item?.item?.image &&
            !item?.item?.image.includes('product_no_photo.png')
              ? item?.item?.image
              : imgLogo
          }
          width="65px"
          height="65px"
          borderRadius="50%"
        />
        <Cont>
          <ContTitle>
            <span>{item?.item?.amount}</span>
            <h2>{item?.item?.name}</h2>
          </ContTitle>
          {item?.item?.observation ? (
            <ContObservation size="12px" background="#FEB70C30" color="#e49f00">
              <p>
                <strong>Observações: </strong>
                {item?.item?.observation}
              </p>
            </ContObservation>
          ) : (
            ''
          )}
          {item?.item?.options ? (
            <ContOptionsModal>
              {item?.item?.options.map((el) => {
                return (
                  <span>
                    {el?.qtd} x {el?.nome}
                  </span>
                );
              })}
            </ContOptionsModal>
          ) : (
            ''
          )}

          <ContDescription onClick={toggleDescription}>
            <p>Descrição</p>
            <img src={expand} alt="expandir" />
          </ContDescription>
          <Description display={statusDescription}>
            {item?.item?.description}
          </Description>
          <TextPrice>
            <span>
              {Number(item?.item?.price).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </TextPrice>
        </Cont>
      </Card>
    </>
  );
};

export default ProductCard;

import React from 'react';

export default function IcClients() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Bold"
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M23,16a1,1,0,0,0-1,1v2a3,3,0,0,1-3,3H17a1,1,0,0,0,0,2h2a5.006,5.006,0,0,0,5-5V17A1,1,0,0,0,23,16Z" />
      <path d="M1,8A1,1,0,0,0,2,7V5A3,3,0,0,1,5,2H7A1,1,0,0,0,7,0H5A5.006,5.006,0,0,0,0,5V7A1,1,0,0,0,1,8Z" />
      <path d="M7,22H5a3,3,0,0,1-3-3V17a1,1,0,0,0-2,0v2a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z" />
      <path d="M19,0H17a1,1,0,0,0,0,2h2a3,3,0,0,1,3,3V7a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z" />
      <path d="M12,11A4,4,0,1,0,8,7,4,4,0,0,0,12,11Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,5Z" />
      <path d="M18,20a1,1,0,0,0,1-1,6.006,6.006,0,0,0-6-6H11a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0,4,4,0,0,1,4-4h2a4,4,0,0,1,4,4A1,1,0,0,0,18,20Z" />
    </svg>
  );
}

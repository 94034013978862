import React, { FunctionComponent } from 'react';

import { Switcher, Toggle } from './style';

interface Props {
  ref?: any;
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: any;
}

const ToggleButton: FunctionComponent<Props> = (props) => {
  const {
    name,
    ref,
    defaultChecked,
    checked = defaultChecked,
    disabled = false,
    onChange = () => true,
  } = props;

  return (
    <Switcher ref={ref}>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <Toggle
        htmlFor={name}
        onClick={() => {
          onChange(!checked);
        }}
      />
    </Switcher>
  );
};

export default ToggleButton;

import styled from 'styled-components';
import { color } from '../../services/api';

export const ContSearch = styled.div<any>`
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSearch = styled.button`
  border: 1px solid solid rgba(var(--${color}3), 1);
  background-color: rgba(var(--${color}), 1);
  border-radius: 8px;

  height: 46.6px;
  padding: 5px 15px;

  display: flex;
  align-items: center;
  justify-content: start;

  gap: 10px;

  font-size: 16px;
  color: white;

  transition: transform 0.2s ease-in-out;

  transform: scale(1);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  gap: 20px;

  padding: 20px 10px;
  border: 1px solid ${(props) => props.theme.shadow};

  border-radius: 8px;

  cursor: pointer;

  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.theme.background};

  :hover {
    transform: translateY(-5px) translateX(5px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
`;

export const Title = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WarningContainer = styled.div<any>`
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px 0px;

  > span{
    font-size 16px;
    color: ${(props) => props.color};
    font-weight: bold;
  }
`;

export const Status = styled(Title)``;

import React from 'react';

import { StackDiv } from './style';

const Stack: React.FC<any> = ({ children, ...props }) => {
  return (
    <StackDiv {...props}>
      {children}
      {props.divider}
    </StackDiv>
  );
};

export default Stack;

/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { Controller, useForm } from 'react-hook-form';
import { DragDropContext } from 'react-beautiful-dnd';
import moment from 'moment';
import IcOrders from '../../assets/icons/orders';
import BreadcrumbComponent from '../../components/Breadcrumb';
import FailedLoad from '../../components/FailedLoad';
import HeaderPage from '../../components/Header';
import { Stack, Image, Modal, Input, Tag, Text, Button } from '../../lib';
import TextArea from '../../lib/TextArea';
import { InputRadio, LabelRadio } from '../../lib/Radio/style';
import api, { apiURL, themeSystem } from '../../services/api';
import { Board, Content, Dot } from '../../styles/style';
import DraggableElement from './draggableElement';
import imgSearch from '../../assets/icons/search.svg';
import {
  ButtonSearch,
  Card,
  ContSearch,
  Status,
  Title,
  WarningContainer,
} from './style';
import ProductCard from '../../components/CardOrder/product';
import {
  SummaryValues,
  ContainerText,
  ContObservation,
} from '../../components/CardOrder/style';
import {
  colorsSteps,
  backgroundColors,
  defaultCancellationTextList,
} from '../../components/Modals/Orders';
import { useAuth } from '../../contexts/AuthContext';
import InsufficientPermission from '../../components/InsufficientPermission';
import LoadingPage from '../../components/LoadingPage';
import imgMinimize from '../../assets/icons/minimize.svg';

const lists = [
  {
    id: 1,
    state: 'IN_QUEUE',
    name: 'Na Fila',
  },
  { id: 2, state: 'IN_PRODUCTION', name: 'Em Produção' },
  { id: 3, state: 'DELIVERY_QUEUE', name: 'Fila de Entregas' },
  { id: 4, state: 'EN_ROUTE', name: 'A caminho' },
  { id: 5, state: 'DELIVERED', name: 'Entregue' },
  { id: 6, state: 'CANCELED', name: 'Cancelados' },
];

const removeFromList = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  index: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  index: number,
  element: unknown,
) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

export default function Orders() {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>();

  const { user } = useAuth();
  const [listByStatus, setlistByStatus] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<string>('loading');
  const [monitoring, setMonitoring] = useState<boolean>(false);
  const [data, setData] = useState<[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [modalOrder, setModalOrder] = useState<boolean>(false);
  const [onSearch, setOnSearch] = useState<string>('');
  const [dataOrders, setDataOrders] = useState<[]>([]);
  const [dataOrder, setDataOrder] = useState<any>([]);
  const [modalCanceled, setModalCanceled] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [deliveryFee, setDeliveryFee] = useState<string>('');
  const [warning, setWarning] = useState<boolean>(false);
  const [mouseMove, setMouseMove] = useState<boolean>(false);
  const [columnText, setColumnText] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const [dataDragEnd, setDataDragEnd] = useState<[]>([]);
  const [openMenuDots, setOpenMenuDots] = useState<boolean>(false);
  const [filterCat, setFilterCat] = useState<string>('0');
  const [dataCat, setDataCat] = useState<any>([]);
  const [sortedCat, setSortedCat] = useState<any>([]);
  const [filterRequests, setFilterRequests] = useState<any>('todos');
  const [completeOrderList, setCompleteOrderList] = useState<any>([]);
  const [minimize, setMinimize] = useState<boolean>(false);
  const [valueSelect, setValueSelect] = useState<any>([]);

  const updateData = async (id: any, destination: number, txt: any) => {
    if (user?.configuration?.access?.requests?.write) {
      await api
        .put(`${apiURL}/rest/requests.php/${id}`, {
          state: lists[destination - 1].state,
          log_observation: txt || 'null',
        })
        .then(() => {
          setMonitoring((prevData) => !prevData);
        })
        .finally(() => {
          setLoading(false);
          setModalCanceled(false);
          reset();
          /*
            postWeekeBot(id);
          */
        });
    }
  };

  // WeekeBot
  /*
    const postWeekeBot = async (id: any) => {
    const order: any = await api
      .get(`${apiURL}/rest/requests.php/${id}`)
      .then((resp) => {
        return resp;
      });

    const data_delivery = {
      message: {
        status: order?.data?.state,
        user: {
          name: order?.data?.user?.name,
          phone: order?.data?.user?.phone,
        },
      },
    };

    try {
      const response = await fetch(
        'https://augusto.local.weeke.app/delivery/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data_delivery),
          mode: 'no-cors',
        },
      );
    } catch (error) {
      console.error('Erro:', error);
    }
  };
  */

  const onDragEnd = (result: any) => {
    if (user?.configuration?.access?.requests?.write) {
      if (!result.destination) {
        return;
      }

      setValueSelect([]);

      if (result.destination.droppableId !== result.source.droppableId) {
        if (
          lists[Number(result.destination.droppableId) - 1].state === 'CANCELED'
        ) {
          setDataDragEnd(result);
          setModalCanceled(true);
        } else {
          const txt = 'null';
          const listCopy = { ...listByStatus };

          const sourceList = listCopy[result.source.droppableId - 1];

          const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index,
          );

          listCopy[result.source.droppableId - 1] = newSourceList;
          const destinationList = listCopy[result.destination.droppableId - 1];

          listCopy[result.destination.droppableId - 1] = addToList(
            destinationList,
            result.destination.index,
            removedElement,
          );

          setlistByStatus(listCopy);

          updateData(result.draggableId, result.destination.droppableId, txt);
        }
      }
    }
  };

  const filterDataForCategory = (listOrders, category = 'todos') => {
    let filterData: any = [];

    if (category === 'todos' || category === '0') {
      filterData = listOrders;
    } else {
      filterData = listOrders.filter((e) => {
        return e.products.find((el) => el.category.id === category);
      });
    }

    filterData = filterData
      .filter((e: any) => {
        return e?.products.length > 0;
      })
      .reverse();

    setData(filterData);
    addOrdersToList(filterData);
  };

  const searchOrder = (value: string) => {
    const resp: any = [];

    if (value) {
      data.forEach((order: any) => {
        const id = String(order.id);
        const name = String(order.user.name).toLowerCase();
        const userName = String(value).toLowerCase();
        if (id.includes(value) || name.includes(userName)) {
          resp.push(order);
        }
      });

      return setDataOrders(resp);
    }

    setDataOrders([]);
  };

  const sendingCancellationReason = () => {
    if (valueSelect.length === 0) {
      const result: any = dataDragEnd;
      const dataForm = getValues('reason');

      const listCopy = { ...listByStatus };

      const sourceList = listCopy[result.source.droppableId - 1];

      const [removedElement, newSourceList] = removeFromList(
        sourceList,
        result.source.index,
      );

      listCopy[result.source.droppableId - 1] = newSourceList;
      const destinationList = listCopy[result.destination.droppableId - 1];

      listCopy[result.destination.droppableId - 1] = addToList(
        destinationList,
        result.destination.index,
        removedElement,
      );

      setlistByStatus(listCopy);

      updateData(result.draggableId, result.destination.droppableId, dataForm);
    } else {
      const dataForm = getValues('reason');
      updateData(valueSelect[0], valueSelect[1], dataForm);
      setValueSelect([]);
    }
  };

  function getData() {
    if (user?.configuration?.access?.requests?.read) {
      setLoading(true);
      setLoadingPage('loading');
      api
        .get(`${apiURL}/rest/requests.php/`)
        .then((res) => {
          setCompleteOrderList(res?.data);
          filterDataForCategory(res?.data, filterRequests);
          setLoading(false);
          setLoadingPage('');
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setLoadingPage('');
        });
    }
  }

  async function getPaymentMethods() {
    if (user?.configuration?.access?.requests?.read) {
      await api.get('rest/payment_gateway.php').then((res) => {
        setPaymentMethods(res.data);
      });
    }
  }

  async function getDeliveryFee() {
    if (user?.configuration?.access?.requests?.read) {
      await api.get('rest/options.php/delivery').then((res) => {
        setDeliveryFee(res.data.delivery);
      });
    }
  }

  function getDataCat() {
    if (user?.configuration?.access?.requests?.read) {
      setLoading(true);
      api
        .get(`${apiURL}/rest/products_categories.php`)
        .then((res) => {
          const resp = res?.data.map((e) => {
            return {
              id: e?.id,
              name: e?.name,
              priority: e?.priority,
              color: JSON.parse(e?.options)?.color,
            };
          });
          setDataCat(resp || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  function getSortedCategories() {
    if (user?.configuration?.access?.requests?.read) {
      setLoading(true);

      api
        .get(`${apiURL}/rest/options.php/categoryPriority`)
        .then((res) => {
          setSortedCat(res?.data?.categoryPriority || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getData();
  }, [monitoring]);

  useEffect(() => {
    getData();
    getDeliveryFee();
    getPaymentMethods();
    getDataCat();
    getSortedCategories();
  }, []);

  const addOrdersToList = (data: any) => {
    if (data) {
      setlistByStatus([]);
      lists?.map((listKey) => {
        const arrItems =
          data?.filter((item: any) => item?.state === listKey.state) || [];
        setlistByStatus((old: any) => [...old, arrItems]);
        return '';
      });
    }
  };

  const addPrice = (products: any[], delivery = 0) => {
    const result = products?.reduce(
      (acumulador: number, produto: { price: any }) =>
        acumulador + Number(produto.price),
      0,
    );

    if (delivery !== 0) {
      return (result + delivery).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return result?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const openModalOrder = (e: any) => {
    setDataOrder(
      data.find((order: any) => order.id === e.currentTarget.dataset.id),
    );
    setModalOrder(true);
  };

  const openModalOrders = (e: any) => {
    setDataOrders([]);
    setOnSearch('');
    setModal(true);
  };

  const mouse = (e: {
    currentTarget: { querySelector: (arg0: string) => any };
  }) => {
    if (mouseMove) {
      const tag = e.currentTarget.querySelector('span');
      setColumnText(tag.innerText);
      definingColors(tag.innerText);
    }
  };

  const activateMouse = () => {
    if (!mouseMove) {
      setMouseMove(true);
      setWarning(true);
    }
  };

  const disableMouse = () => {
    if (mouseMove) {
      setMouseMove(false);
      setWarning(false);
    }
  };

  const definingColors = (column: string) => {
    const text = column.toLowerCase();
    if (text === 'na fila') {
      setColor('#8b8d8d');
      setBackground('#d3d5d5');
    } else if (text === 'em produção') {
      setColor('#5fc1ce');
      setBackground('#dcf7f9');
    } else if (text === 'fila de entregas') {
      setColor('#ebc747');
      setBackground('#fdf8e3');
    } else if (text === 'a caminho') {
      setColor('#1d2091');
      setBackground('#d1d3f5');
    } else if (text === 'entregue') {
      setColor('#0bc117');
      setBackground('#c1fbd7');
    } else if (text === 'cancelados') {
      setColor('#8e0b0b');
      setBackground('#ebcfcf');
    }
  };

  function sortedCategories() {
    const newOrder: any = [];

    if (dataCat.length > 0) {
      sortedCat?.forEach((e: any) => {
        const item = dataCat?.find((el: any) => Number(el?.id) === Number(e));
        newOrder.push(item);
      });
    }
    return newOrder;
  }

  return user?.configuration?.access?.requests?.read ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <>
          <HeaderPage />
          <Content
            style={{ minHeight: 'auto' }}
            direction="row"
            onMouseUp={disableMouse}
            padding="0px 30px 0px 30px"
            justify="space-between"
          >
            <BreadcrumbComponent
              name="Quadro Pedidos"
              icon={<IcOrders />}
              length={listByStatus[0]?.length}
              extra={
                <Stack direction="row" align="center" spacing="20px">
                  <Button
                    variant="ghost"
                    onClick={() => setMinimize((prev) => !prev)}
                  >
                    {!minimize ? 'Minimizar Pedidos' : 'Expandir Pedidos'}
                  </Button>

                  <div style={{ position: 'relative' }}>
                    <Button
                      variant="ghost"
                      shape="icon"
                      onClick={() => setOpenMenuDots(!openMenuDots)}
                    >
                      <BsThreeDotsVertical size="16px" />
                    </Button>
                    {openMenuDots && (
                      <Stack
                        style={{
                          background:
                            themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
                          position: 'absolute',
                          top: 60,
                          right: 0,
                          width: '250px',
                          border: '1px solid #eee',
                          borderRadius: 5,
                          boxShadow: '-10px 0 50px -15px #00000020',
                        }}
                      >
                        <Stack
                          spacing="13px"
                          padding="15px"
                          style={{ marginTop: -10 }}
                        >
                          <Text variant="label" full>
                            <Stack direction="row" justify="space-between">
                              Categorias
                            </Stack>
                          </Text>
                          <Input
                            full
                            as="select"
                            type="text"
                            placeholder="Tipo do produto"
                            defaultValue={getValues('category.id') || 0}
                            value={filterCat}
                            onChange={(e: any) => {
                              setFilterCat(e.target.value);
                              setFilterRequests(e.target.value);
                              filterDataForCategory(
                                completeOrderList,
                                e?.target?.value,
                              );
                            }}
                          >
                            <option value="0">Todos</option>
                            {sortedCat
                              ? sortedCategories()?.map((item: any) => (
                                  // eslint-disable-next-line react/jsx-indent
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                ))
                              : ''}
                          </Input>
                        </Stack>
                      </Stack>
                    )}
                  </div>
                  <ContSearch>
                    <ButtonSearch
                      type="button"
                      title="Pesquisar Pedido!"
                      onClick={openModalOrders}
                    >
                      <Image
                        src={imgSearch || 'null'}
                        width="24px"
                        height="24px"
                      />
                      Pesquisar
                    </ButtonSearch>
                  </ContSearch>
                </Stack>
              }
            />
          </Content>

          <Board onMouseUp={disableMouse} onMouseLeave={disableMouse}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Stack spacing="15px" direction="row">
                {lists?.map((listKey, index) => (
                  <DraggableElement
                    elements={listByStatus[index]}
                    column={listKey}
                    prefix={listKey.id}
                    key={listKey.id}
                    loading={loading}
                    mouse={mouse}
                    activateMouse={activateMouse}
                    disableMouse={disableMouse}
                    minimize={minimize}
                    setValueSelect={setValueSelect}
                    setModalCanceled={setModalCanceled}
                    updateData={updateData}
                  />
                ))}
              </Stack>
            </DragDropContext>
          </Board>

          <Modal set={setModal} state={modal} title="Pesquisar Pedido">
            <Stack
              direction="column"
              align="start"
              justify="start"
              spacing="15px"
            >
              <Stack>
                <Input
                  type="text"
                  placeholder="Pesquisar..."
                  icon={<BsSearch />}
                  id="name"
                  autoFocus
                  value={onSearch}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setOnSearch(e.currentTarget?.value);
                    searchOrder(e.currentTarget?.value);
                  }}
                />
              </Stack>
              <Stack
                direction="column"
                align="start"
                justify="start"
                spacing="15px"
              >
                {dataOrders?.map((order: any) => {
                  return (
                    <Card
                      key={order.id}
                      onClick={openModalOrder}
                      data-id={order.id}
                    >
                      <Title>
                        <Text
                          variant="label"
                          style={{ margin: 0 }}
                          gutter="8px"
                        >
                          {order.user.name}
                        </Text>
                        <Text
                          variant="label"
                          style={{ margin: 0 }}
                          gutter="8px"
                        >
                          #{order.id}
                        </Text>
                      </Title>
                      <Status>
                        <Tag
                          backgroundColor={
                            backgroundColors[
                              Number(
                                lists?.find((e: any) => e.state === order.state)
                                  ?.id,
                              ) - 1
                            ]
                          }
                          color={
                            colorsSteps[
                              Number(
                                lists?.find((e: any) => e.state === order.state)
                                  ?.id,
                              ) - 1
                            ]
                          }
                        >
                          {lists.find((e) => e.state === order.state)?.name}
                        </Tag>
                      </Status>
                    </Card>
                  );
                })}
              </Stack>
            </Stack>
          </Modal>

          <Modal
            set={setModalOrder}
            state={modalOrder}
            title="Detalhes do Pedido"
          >
            <Stack direction="column" spacing="15px">
              <Stack direction="row" align="center" spacing="15px">
                <Tag
                  backgroundColor={
                    backgroundColors[
                      Number(
                        lists?.find((e: any) => e.state === dataOrder?.state)
                          ?.id,
                      ) - 1
                    ]
                  }
                  color={
                    colorsSteps[
                      Number(
                        lists?.find((e: any) => e.state === dataOrder?.state)
                          ?.id,
                      ) - 1
                    ]
                  }
                >
                  {lists?.find((e: any) => e.state === dataOrder?.state)?.name}
                </Tag>
                <Dot
                  color={
                    colorsSteps[
                      Number(
                        lists?.find((e: any) => e.state === dataOrder?.state)
                          ?.id,
                      ) - 1
                    ]
                  }
                  size="5px"
                />
                <Text>{dataOrder?.user?.name}</Text>
              </Stack>
              <Stack align="start" spacing="15px">
                <Text variant="label">Etapa</Text>
                <Input
                  full
                  as="select"
                  type="text"
                  placeholder="Etapa do pedido!"
                  defaultValue={
                    Number(
                      lists?.findIndex((element) => {
                        return element?.state === dataOrder?.last_log?.type;
                      }),
                    ) + 1 || 1
                  }
                  onChange={(e: any) => {
                    if (Number(e.target.value) === 6) {
                      setValueSelect([dataOrder.id, e.target.value]);
                      setModalCanceled(true);
                      setModal(false);
                      setModalOrder(false);
                    } else {
                      updateData(dataOrder.id, e.target.value, 'null');
                      setModal(false);
                      setModalOrder(false);
                    }
                  }}
                >
                  {lists.map((elem, index) => {
                    return (
                      <option key={elem.name} value={index + 1}>
                        {elem.name}
                      </option>
                    );
                  })}
                </Input>
              </Stack>
              <Stack direction="column" spacing="5px">
                {dataOrder?.last_log?.observation &&
                dataOrder?.last_log?.observation !== 'null' ? (
                  <ContObservation
                    size="12px"
                    background="#ebcfcf"
                    color="#8e0b0b"
                  >
                    <p>
                      <strong>Motivo do cancelamento: </strong>
                      {dataOrder.last_log.observation}
                    </p>
                  </ContObservation>
                ) : (
                  ''
                )}
              </Stack>
              <Stack direction="column" spacing="5px">
                {dataOrder?.observation ? (
                  <ContObservation
                    size="12px"
                    background="#FEB70C30"
                    color="#e49f00"
                  >
                    <p>
                      <strong>Observação Geral: </strong>
                      {dataOrder.observation}
                    </p>
                  </ContObservation>
                ) : (
                  ''
                )}
              </Stack>
              <Stack>
                {dataOrder?.products?.map((product: any, row: any) => (
                  <Stack
                    direction="row"
                    justify="space-between"
                    padding="10px 0"
                    spacing="15px"
                    key={product.id}
                  >
                    <ProductCard item={product} />
                  </Stack>
                ))}
              </Stack>
              <SummaryValues>
                <h3>Resumo de valores</h3>
                <div>
                  <p>Forma de pagamento</p>
                  <p>
                    {paymentMethods
                      ? paymentMethods.find(
                          (e: { id: any }) =>
                            e.id === dataOrder?.payment_gateway,
                        )?.name || '...'
                      : ''}
                  </p>
                </div>
                <div>
                  <p>Taxa de Entrega</p>
                  <p>
                    {deliveryFee
                      ? Number(deliveryFee).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : 'Grátis'}
                  </p>
                </div>
                <div>
                  <p>Total</p>
                  <p>{addPrice(dataOrder?.products, Number(deliveryFee))}</p>
                </div>
              </SummaryValues>
              <ContainerText>
                <h3>Endereço de entrega</h3>
                <p>
                  Rua: {dataOrder?.address?.rua}, Bairro:
                  {dataOrder?.address?.bairro}, n°
                  {dataOrder?.address?.numero},
                  {dataOrder?.address?.complemento
                    ? `Complemento: ${dataOrder?.address?.complemento}`
                    : ''}
                  , Cidade: {dataOrder?.address?.cidade} -
                  {dataOrder?.address?.uf}, Cep:{dataOrder?.address?.cep}
                </p>
              </ContainerText>
              <ContainerText>
                <h3>Horário</h3>
                <p>{moment(dataOrder?.date).format('DD/MM/YYYY HH:mm')}</p>
              </ContainerText>
            </Stack>
          </Modal>

          <Modal
            set={setModalCanceled}
            state={modalCanceled}
            title="Formulário de Cancelamento"
            width="900px"
          >
            <Stack
              direction="column"
              spacing="25px"
              justify="space-between"
              height="100%"
            >
              <Stack direction="row" spacing="25px">
                <Stack direction="column" spacing="25px">
                  {defaultCancellationTextList?.map((txt, index) => {
                    if (index <= 4) {
                      return (
                        <LabelRadio key={index}>
                          <InputRadio
                            type="radio"
                            name="pedido-cancelado"
                            value={index + 1}
                            onChange={(e) =>
                              setValue(
                                'reason',
                                defaultCancellationTextList[
                                  Number(e.target.value) - 1
                                ],
                              )
                            }
                          />
                          {txt}
                        </LabelRadio>
                      );
                    }
                    return null;
                  })}
                </Stack>
                <Stack direction="column" spacing="25px">
                  {defaultCancellationTextList?.map((txt, index) => {
                    if (index > 4) {
                      return (
                        <LabelRadio key={index}>
                          <InputRadio
                            type="radio"
                            name="pedido-cancelado"
                            value={index + 1}
                            onChange={(e) =>
                              setValue(
                                'reason',
                                defaultCancellationTextList[
                                  Number(e.target.value) - 1
                                ],
                              )
                            }
                          />
                          {txt}
                        </LabelRadio>
                      );
                    }
                    return null;
                  })}
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing="30px"
                as="form"
                onSubmit={handleSubmit(sendingCancellationReason)}
              >
                <Controller
                  control={control}
                  name="reason"
                  rules={{ required: true }}
                  defaultValue={getValues('reason')}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <TextArea
                      full
                      placeholder="Digite o motivo do cancelamento do pedido..."
                      type="text"
                      value={value}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(`${e.target.value}`);
                      }}
                    />
                  )}
                />
                <Stack direction="row" spacing="15px">
                  <Button variant="ghost" type="submit">
                    Enviar
                  </Button>
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => {
                      setModalCanceled(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Modal>
          {warning ? (
            <WarningContainer color={color} background={background}>
              <span>{columnText}</span>
            </WarningContainer>
          ) : (
            ''
          )}
        </>
      )}
    </>
  ) : (
    <>
      <HeaderPage />
      <InsufficientPermission />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const InputRadio = styled.input`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid black;
  background-color: ${(props) => props.theme.backgroundSec};
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  cursor: pointer;

  &:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
  }
`;

export const LabelRadio = styled.label`
  font-weight: '300'};
  text-align: 'left'};
  font-size: 0.8rem;
  line-height: 1.5;
  color: ${(props) => props.color || props.theme.text};
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1;
  opacity: 1;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
`;

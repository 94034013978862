/* eslint-disable no-unused-expressions */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import { Button, Drawer, Input, Modal, Stack, Text } from '../../../lib';
import api, { apiURL } from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';

interface Props {
  state: boolean;
  set: any;
  update: any;
  data: any;
  updateCategories: any;
}

interface FormProductsCategory {
  [x: string]: any;
  id: number;
  name: string;
  priority: number;
}

const DrawerCategories: React.FC<Props> = ({ ...props }) => {
  const { control, getValues, reset, handleSubmit } = useForm<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalDel, setModalDel] = React.useState<boolean>(false);
  const [modalCategory, setModalCategory] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [productIndex, setProductIndex] = React.useState<number>(0);
  const { user } = useAuth();
  const [isButtonDisabled, setButtonDisabled] = React.useState<boolean>(false);

  const defaultColor = '#FFFFFF';

  const submitProductCategory = (data: FormProductsCategory) => {
    setLoading(true);
    editing ? updateProductCategory(data) : createProductCategory(data);
  };

  const createProductCategory = async (data: FormProductsCategory) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .post(`${apiURL}/rest/products_categories.php`, {
          name: data?.name,
          options: JSON.stringify({
            color: data?.color || defaultColor,
          }),
          priority: 1,
        })
        .then((res) => {
          api
            .put(`rest/options.php`, {
              categoryPriority: JSON.stringify([
                ...props.data.map((e) => e?.id),
                res.data,
              ]),
            })
            .then((e) => {
              props.update();
              props.updateCategories();
              setLoading(false);
            });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
          setModalCategory(false);
        });
    }
  };

  const updateProductCategory = async (data: FormProductsCategory) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .put(`${apiURL}/rest/products_categories.php/${data?.id}`, {
          name: data?.name,
          options: JSON.stringify({
            color: data?.color || defaultColor,
          }),
          priority: 1,
        })
        .then((e) => {
          setLoading(false);
          props.update();
          props.updateCategories();
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
          setModalCategory(false);
        });
    }
  };

  const deleteProductCategory = async (data: FormProductsCategory) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .delete(`${apiURL}/rest/products_categories.php/${data?.id}`, {})
        .then((res) => {
          api
            .put(`rest/options.php`, {
              categoryPriority: JSON.stringify([
                ...props.data.map((e) => e?.id).filter((id) => id !== data?.id),
              ]),
            })
            .then((e) => {
              setLoading(false);
              setModalDel(false);
              setModalCategory(false);
              props.update();
              props.updateCategories();
            });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const handleDragStart = (e: any) => {
    if (user?.configuration?.access?.products?.write) {
      const text = e.target.innerText;
      const index = Number(text[0]);
      setProductIndex(index);
    }
  };

  const handleDrop = (e: any) => {
    if (user?.configuration?.access?.products?.write) {
      const text = e.target.innerText;
      const index = Number(text[0]);

      const newButtons = props.data.map((item: any) => item.id);

      const [removedButton] = newButtons.splice(productIndex - 1, 1);

      newButtons.splice(index - 1, 0, removedButton);

      api
        .put(`rest/options.php`, {
          categoryPriority: JSON.stringify(newButtons),
        })
        .then((el) => {
          props.updateCategories();
        })
        .catch((el) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  return user?.configuration?.access?.products?.read ? (
    <>
      <Drawer
        set={props.set}
        state={props.state}
        title="Categorias"
        behind={modalCategory}
      >
        <Stack spacing="10px">
          <Text variant="label">Lista de categorias principais</Text>
          {props?.data
            ? props?.data.map((item: any, index: number) => (
                // eslint-disable-next-line react/jsx-indent
                <Stack key={item?.id}>
                  <Button
                    variant="ghost"
                    full
                    style={{ justifyContent: 'space-between' }}
                    onClick={() => {
                      reset(item);
                      setModalCategory(true);
                      setEditing(true);
                    }}
                    onDragStart={(e) => handleDragStart(e)}
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                    draggable
                  >
                    {index + 1} - {item?.name}
                    <FiEdit2 />
                  </Button>
                </Stack>
              ))
            : ''}
          <Button
            full
            variant="outline"
            style={{ borderStyle: 'dashed' }}
            onClick={() => {
              reset({});
              setModalCategory(true);
              setEditing(false);
            }}
          >
            Adicionar nova
          </Button>
        </Stack>
      </Drawer>

      <Drawer
        set={setModalCategory}
        state={modalCategory}
        title={editing ? 'Editar Categoria' : 'Adicionar Categoria'}
      >
        <Stack
          spacing="15px"
          as="form"
          onSubmit={handleSubmit(submitProductCategory)}
        >
          <Text variant="label">Nome da categoria</Text>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                placeholder="Nome da categoria"
                type="text"
                name="name"
                id="name"
                defaultValue={getValues('name')}
                value={value || ''}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Text variant="label">Cor da Categoria</Text>
          <Controller
            control={control}
            name="color"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                type="color"
                name="color"
                id="color"
                height="150px"
                defaultValue={getValues('color') || defaultColor}
                value={value || ''}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Button
            full
            variant="primary"
            loading={loading ? 1 : 0}
            disabled={isButtonDisabled}
          >
            {editing ? 'Salvar ' : 'Adicionar'}
          </Button>
          {editing && (
            <Button
              full
              mode="dangerous"
              type="button"
              onClick={() => {
                setModalDel(true);
              }}
              disabled={isButtonDisabled}
            >
              <FiTrash />
              Deletar
            </Button>
          )}
        </Stack>
      </Drawer>

      <Modal set={setModalDel} state={modalDel} title="Deletar Produto">
        <Text gutter="20px">
          Ao deletar uma categoria, todos os produtos relacionados serão
          apagados. Deseja realmente deletar?
        </Text>
        <Stack direction="row" align="center" spacing="20px">
          <Button
            variant="ghost"
            full
            onClick={() => {
              setModalDel(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            full
            loading={loading ? 1 : 0}
            onClick={() => {
              deleteProductCategory(getValues());
              setModalDel(false);
            }}
            disabled={isButtonDisabled}
          >
            Deletar
          </Button>
        </Stack>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DrawerCategories;

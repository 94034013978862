import styled, { keyframes } from 'styled-components';
import { color } from '../../../services/api';

const slideInDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOutUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

export const WarningModalStyle = styled.div`
  box-sizing: border-box;
  width: auto;
  height: 100px;
  gap: 20px;
  color: gray;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;

  > span,
  p {
    margin: 0px;
  }

  animation: ${(props) =>
      props.animation === 'down' ? slideInDown : slideOutUp}
    0.3s ease-out;
`;

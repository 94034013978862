/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
/* eslint-disable react/react-in-jsx-scope */
import React, { useContext, createContext, ReactNode, useEffect } from 'react';

import api from '../services/api';

const TOKEN_API = 'token_weeke_delivery';

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<any>;
  singOut(): void;
  Authenticated: boolean;
  authed: boolean;
  user: any;
};

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [authed, setAuthed] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<any>({});

  const Authenticated = !!window.localStorage.getItem(TOKEN_API);
  useEffect(() => {
    loadStorageData();
    sessionUser();
  }, [Authenticated]);

  useEffect(() => {
    api.interceptors.response.use(undefined, (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          console.log('login expirado');
          if (error.response.data.errors) {
            window.localStorage.clear();
          }
        }
      }
      return Promise.reject(error);
    });
  }, []);

  async function loadStorageData() {
    const storageToken = window.localStorage.getItem(TOKEN_API);
    const userSession = window.localStorage.getItem(
      'user_session_weeke_delivery',
    );

    if (storageToken) {
      api.defaults.headers.common.Authorization = `Bearer ${storageToken}`;
      setAuthed(true);
      setUser(JSON.parse(userSession ?? ''));
    } else {
      window.localStorage.removeItem(TOKEN_API);
      window.localStorage.removeItem('user_session_weeke_delivery');
    }
  }

  async function sessionUser() {
    await api.get<any>('rest/session.php').then((userInfo) => {
      window.localStorage.setItem(
        'user_session_weeke_delivery',
        JSON.stringify(userInfo.data),
      );
      setUser(userInfo.data);
    });
  }

  async function signIn({ email, password }: SignInCredentials) {
    return new Promise(async (resolve, reject): Promise<void> => {
      try {
        await api
          .post<any>('rest/login.php', {
            email,
            password,
          })
          .then((resToken) => {
            window.localStorage.setItem(TOKEN_API, resToken.data);
            setAuthed(true);
            resolve(resToken.data);
            sessionUser();
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async function singOut() {
    setUser({});
    setAuthed(false);
    return new Promise(() => {
      window.localStorage.removeItem(TOKEN_API);
    });
  }

  return (
    <AuthContext.Provider
      value={{ signIn, singOut, authed, user, Authenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const AuthContext = createContext({
  authed: false,
  user: {},
  signIn: () => {},
  singOut: () => {},
} as unknown as AuthContextData);

export function useAuth() {
  return useContext(AuthContext);
}

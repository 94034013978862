import React from 'react';
import { BsXLg } from 'react-icons/bs';
import { CSSTransition } from 'react-transition-group';

import Button from '../Button';
import Stack from '../Stack';
import Text from '../Text';
import { ModalFloat, ModalOverlay, ModalBody, ContChildren } from './style';

interface Props {
  width?: string;
  title?: string;
  state?: boolean;
  set: (val: boolean) => void;
  style?: Record<string, unknown>;
}

const Modal: React.FC<Props> = ({ children, ...props }) => {
  const handleClick = (): void => {
    props.set(false);
  };
  return (
    <>
      <CSSTransition
        in={props.state}
        timeout={500}
        classNames="drawer-overlay"
        unmountOnExit
      >
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={props.state}
        timeout={500}
        classNames="modal-body"
        unmountOnExit
      >
        <ModalFloat>
          <ModalBody {...props}>
            <Stack direction="row" align="center" padding="0 0 17px 0">
              <Text variant="title" full>
                {props.title}
              </Text>
              <Button
                variant="ghost"
                shape="icon"
                onClick={() => {
                  handleClick();
                }}
              >
                <BsXLg />
              </Button>
            </Stack>
            <ContChildren>{children}</ContChildren>
          </ModalBody>
        </ModalFloat>
      </CSSTransition>
    </>
  );
};

export default Modal;

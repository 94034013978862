import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const HeaderLocation = styled.div`
  width: 100%;
  max-width: 300px;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 20px;

  text-align: center;

  > h1 {
    font-size: 32px;
    font-weight: 700;
    color: #404040;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);

    margin: 0px;
  }

  p {
    color: #808080;
  }

  > p,
  button {
    font-size: 16px;
  }

  > button {
    font-weight: bold;
  }
`;

export const ListLocation = styled.ul`
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  padding: 20px 10px;

  width: 100%;
  max-width: 600px;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  gap: 20px;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ItemLocation = styled.li`
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  width: 100%;
  min-height: 150px;

  background: white;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;

  gap: 20px;

  padding: 5px;

  > div {
    width: 100%;
    max-width: 100px;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  > div ~ div {
    width: 100%;
    height: 100%;
    max-width: 400px;

    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-direction: column;

    > h2 {
      color: #404040;
      margin: 0px;
    }

    > p {
      color: #808080;
    }

    > p ~ p {
      font-weight: bold;
      color: #1a8bbb;
    }
  }
`;

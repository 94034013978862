import styled from 'styled-components';
import { color } from '../../services/api';
import phone from '../../assets/backgrounds/iphone.svg';

export const Cont = styled.div<any>`
  width: ${(props) => props.width || '100%'};

  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'start'};
  align-items: ${(props) => props.align || 'start'};

  gap: 15px;

  border-left: ${(props) => props.border || 'none'};
  padding: ${(props) => props.padding || '0px'};

  flex-wrap: ${(props) => props.wrap || 'nowrap'};

  margin: ${(props) => props.margin || '0px'};
`;

export const Phone = styled.div`
  box-sizing: border-box;
  background-image: url('${phone}');
  background-size: cover;
  background-position: center;
  width: 300px;
  height: 600px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PhoneScreen = styled.div`
  box-sizing: border-box;
  width: 200px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  justify-content: start;
  align-items: center;

  flex-direction: column;

  gap: 30px;
  padding: 5px;

  ::-webkit-scrollbar {
    width: 0.1em;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  z-index: 0;
`;

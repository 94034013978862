import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { IoMdLogIn } from 'react-icons/io';
import { MdOutlineAlternateEmail, MdOutlineRemoveRedEye } from 'react-icons/md';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api, { apiURL } from '../../services/api';
import isometricLogin from '../../assets/lotties/isometric_login.json';
import { Button, Image, Input, Stack, Text } from '../../lib';
import { IntroduceLogin } from '../../styles/style';
import { WarningModal } from '../../components/Modals/Warning';

export default function SignInPage() {
  const { control, handleSubmit } = useForm<any>();
  const [seePswd, setSeePswd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorLogin, setErrorLogin] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [statusPage, setStatusPage] = useState('login');
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [visibleWarningMode, setVisibleWarningMode] = useState(false);
  const [modalAnimation, setModalAnimation] = useState('down');
  const [warningModalValues, setWarningModalValues] = useState([
    'Sucesso',
    'success',
  ]);

  const warningModal = (text: string, type: string) => {
    setWarningModalValues([text, type]);
    setVisibleWarningMode(true);
    setModalAnimation('down');
    setTimeout(() => {
      setModalAnimation('up');
      setTimeout(() => {
        setVisibleWarningMode(false);
      }, 200);
    }, 1000);
  };

  const submitLogin = async (data: any) => {
    setLoading(true);
    setErrorLogin(false);
    setButtonDisabled(true);

    await signIn(data)
      .then(() => {
        navigate('/dashboard');
      })
      .catch((res) => {
        setErrorLogin(true);
      })
      .finally(() => {
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const submitForgotPassword = async (data: any) => {
    setLoading(true);
    setButtonDisabled(true);

    await api
      .post(`${apiURL}/rest/user_reset.php`, {
        email: data.email,
      })
      .then((res) => {
        warningModal('Email de recuperação enviado com sucesso!', 'success');
        setTimeout(() => {
          setStatusPage('login');
        }, 1400);
      })
      .catch((res) => {
        warningModal('Desculpe, ocorreu um erro.', 'error');
      })
      .finally(() => {
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  return statusPage === 'login' ? (
    <>
      <div style={{ background: '#eee' }}>
        <Stack
          justify="center"
          align="center"
          style={{ height: '100vh' }}
          data-aos="fade-up"
          padding="0 20px"
        >
          <Stack
            direction="row"
            style={{
              maxWidth: '800px',
            }}
            spacing="40px"
            className="responsive-flex-768"
          >
            <IntroduceLogin>
              <Lottie
                options={{ animationData: isometricLogin }}
                height={400}
                width="90%"
              />
            </IntroduceLogin>
            <Stack
              as="form"
              onSubmit={handleSubmit(submitLogin)}
              spacing="30px"
              style={{
                background: '#ffffff',
                borderRadius: '20px',
              }}
              padding="30px"
            >
              <Image
                src="./img/logo_weeke_delivery.svg"
                alt="Pizza Food"
                height="35px"
                width="130px"
                fit="contain"
                position="left"
              />
              <Text variant="label">E-mail</Text>
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Email"
                    icon={<MdOutlineAlternateEmail />}
                    type="email"
                    ref={ref}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Senha</Text>
              <div style={{ position: 'relative', width: '100%' }}>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      full
                      icon={<MdOutlineRemoveRedEye />}
                      placeholder="Senha"
                      id="password"
                      type={seePswd ? 'text' : 'password'}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />

                <span style={{ position: 'absolute', right: 0, top: 0 }}>
                  <Button
                    type="button"
                    shape="icon"
                    onClick={() => {
                      setSeePswd(!seePswd);
                    }}
                  >
                    {seePswd ? (
                      <HiOutlineEye size="17px" />
                    ) : (
                      <HiOutlineEyeOff size="17px" />
                    )}
                  </Button>
                </span>
              </div>

              {errorLogin && (
                <Stack>
                  <div
                    style={{
                      width: '100%',
                      borderRadius: 5,
                      backgroundColor: '#ed143d33',
                      padding: '4px 0',
                    }}
                  >
                    <Text align="center" color="#ed143d" weight="600">
                      Senha ou E-mail incorretos
                    </Text>
                  </div>
                </Stack>
              )}

              <Stack direction="row" justify="space-between" align="center">
                <Button
                  variant="link"
                  type="button"
                  onClick={() => {
                    setStatusPage('forgotPassword');
                  }}
                >
                  Esqueci a senha
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading ? 1 : 0}
                  disabled={isButtonDisabled}
                >
                  Entrar <IoMdLogIn />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  ) : (
    <>
      <div style={{ background: '#eee' }}>
        <Stack
          justify="center"
          align="center"
          style={{ height: '100vh' }}
          data-aos="fade-up"
          padding="0 20px"
        >
          <Stack
            direction="row"
            style={{
              maxWidth: '800px',
            }}
            spacing="40px"
            className="responsive-flex-768"
          >
            <IntroduceLogin>
              <Lottie
                options={{ animationData: isometricLogin }}
                height={400}
                width="90%"
              />
            </IntroduceLogin>
            <Stack
              as="form"
              onSubmit={handleSubmit(submitForgotPassword)}
              spacing="30px"
              style={{
                background: '#ffffff',
                borderRadius: '20px',
              }}
              padding="30px"
            >
              <Image
                src="./img/logo_weeke_delivery.svg"
                alt="Pizza Food"
                height="35px"
                width="130px"
                fit="contain"
                position="left"
              />
              <Text variant="label">E-mail</Text>
              <Text variant="small">
                Recuperação de conta: Insira seu e-mail
              </Text>
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Email"
                    icon={<MdOutlineAlternateEmail />}
                    type="email"
                    ref={ref}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Stack direction="row" justify="space-between" align="center">
                <Button
                  variant="link"
                  type="button"
                  onClick={() => {
                    setStatusPage('login');
                  }}
                >
                  Retornar ao login
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  loading={loading ? 1 : 0}
                  disabled={isButtonDisabled}
                >
                  Enviar <IoMdLogIn />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
      <>
        {visibleWarningMode === true ? (
          <WarningModal
            text={warningModalValues[0]}
            img={warningModalValues[1]}
            animation={modalAnimation}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
}

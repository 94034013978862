/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { color } from '../../services/api';

export const DropDownMenu = styled.div`
  position: absolute;
  z-index: 100;
  top: 50px;
  right: 0px;
  border: none;
  padding: 7px;
  box-shadow: 0 5px 30px -10px rgba(var(--${color}3), 0.3);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: none;
  transition: 300ms all ease;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  background: ${(props) => props.theme.background};
`;

export default DropDownMenu;

/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import HeaderPage from '../../components/Header';
import { useAuth } from '../../contexts/AuthContext';
import { Input, Stack } from '../../lib';
import BreadcrumbComponent from '../../components/Breadcrumb';
import IcHistoric from '../../assets/icons/historic';
import FailedLoad from '../../components/FailedLoad';
import { Content } from '../../styles/style';
import { InputDate, Select } from './style';
import Product from './components/products';
import Clients from './components/clients';
import InsufficientPermission from '../../components/InsufficientPermission';
import LoadingPage from '../../components/LoadingPage';

const formattedDate = (value = 0) => {
  const date = new Date();

  date.setDate(date.getDate() - value);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  const resp = `${year}-${formattedMonth}-${formattedDay}`;
  return resp;
};

export default function Historic() {
  const { user } = useAuth();
  const [filter, setFilter] = useState<string>('produtos');
  const [order, setOrder] = useState<string>('maior');
  const [currentDate, setCurrentDate] = useState<string>(formattedDate);
  const [datePrevious, setDatePrevious] = useState<string>(formattedDate(30));
  const [quantityDate, setQuantityDate] = useState<number>(10);
  const [valueDateStandard, setValueDateStandard] = useState<string>('0');
  const [loadingPage, setLoadingPage] = useState<string>('loading');

  const valueOrder = (e) => {
    setOrder(e.target.value);
  };

  const standardDate = (e) => {
    const value = e.target.value;

    if (value === '1dia') {
      setValueDateStandard(value);
      setCurrentDate(formattedDate());
      setDatePrevious(formattedDate(1));
    } else if (value === '1semana') {
      setValueDateStandard(value);
      setCurrentDate(formattedDate());
      setDatePrevious(formattedDate(7));
    } else if (value === '1mes') {
      setValueDateStandard(value);
      setCurrentDate(formattedDate());
      setDatePrevious(formattedDate(30));
    } else if (value === '3meses') {
      setValueDateStandard(value);
      setCurrentDate(formattedDate());
      setDatePrevious(formattedDate(90));
    }
  };

  const valueFilter = (e) => {
    setFilter(e.target.value);
  };

  const valueDatePrevious = (e) => {
    setValueDateStandard('0');
    setDatePrevious(e.target.value);
  };

  const valueCurrentDate = (e) => {
    setValueDateStandard('0');
    setCurrentDate(e.target.value);
  };

  const valueQuantity = (e) => {
    setQuantityDate(e.target.value);
  };

  setTimeout(() => {
    setLoadingPage('');
  }, 1000);

  return user?.configuration?.access?.historic?.read ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <>
          <HeaderPage />
          <Content
            style={{ minHeight: 'auto' }}
            direction="column"
            spacing="20px"
          >
            <BreadcrumbComponent
              name="Histórico"
              icon={
                <IcHistoric width="18px" height="18px" color="currentColor" />
              }
            />
            <Stack
              direction="column"
              align="start"
              justify="start"
              spacing="15px"
            >
              <Stack
                as="form"
                direction="row"
                align="start"
                justify="start"
                spacing="15px"
              >
                <Select onChange={(e) => valueFilter(e)}>
                  <option value="0" disabled>
                    Filtro
                  </option>
                  <option value="produtos">Produtos</option>
                  <option value="clientes">Clientes</option>
                </Select>
                <Select onChange={(e) => valueQuantity(e)}>
                  <option value="0" disabled>
                    Quantidade
                  </option>
                  <option value={10}>10</option>
                  <option value={100}>100</option>
                  <option value={300}>300</option>
                  <option value={1000}>1000</option>
                </Select>
                <InputDate>
                  <input
                    type="date"
                    value={datePrevious}
                    max={formattedDate()}
                    onChange={(e) => valueDatePrevious(e)}
                  />
                  <span>/</span>
                  <input
                    type="date"
                    value={currentDate}
                    min={datePrevious}
                    max={formattedDate()}
                    onChange={(e) => valueCurrentDate(e)}
                  />
                  <span>/</span>
                  <Select
                    value={valueDateStandard}
                    onChange={(e) => standardDate(e)}
                  >
                    <option value="0" disabled selected>
                      Padrão
                    </option>
                    <option value="1dia">1 Dia</option>
                    <option value="1semana">1 Semana</option>
                    <option value="1mes">1 Mês</option>
                    <option value="3meses">3 Meses</option>
                  </Select>
                </InputDate>

                <Select onChange={(e) => valueOrder(e)}>
                  <option value="0" disabled>
                    Ordem
                  </option>
                  <option value="maior">Maior</option>
                  <option value="menor">Menor</option>
                </Select>
              </Stack>
              {filter === 'produtos' ? (
                <Product
                  order={order}
                  currentDate={currentDate}
                  datePrevious={datePrevious}
                  quantityDate={quantityDate}
                />
              ) : (
                <Clients
                  order={order}
                  currentDate={currentDate}
                  datePrevious={datePrevious}
                  quantityDate={quantityDate}
                />
              )}
            </Stack>
          </Content>
        </>
      )}
    </>
  ) : (
    <>
      <>
        <HeaderPage />
        <InsufficientPermission />
      </>
    </>
  );
}

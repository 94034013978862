import React, { useEffect } from 'react';
import { HiLogout } from 'react-icons/hi';
import { RiNotification2Line } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import { Button, Image, Stack, Text } from '../../lib';
import { Header } from '../../styles/style';
import { Cont, Title, LinkProfile } from './style';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';
import api from '../../services/api';

function HeaderPage({ title = '', link = '' }): any {
  const { singOut, user } = useAuth();
  const navigate = useNavigate();
  const [company, setCompany] = React.useState<any>([]);

  const getCompany = async () => {
    await api
      .get(`/rest/options.php/logo,headerTitle`)
      .then((res) => setCompany(res.data));
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (user) {
    return (
      <Header>
        <Stack
          direction="row"
          justify="space-between"
          align="center"
          spacing="20px"
          responsive
          mobileJustify="center"
          mobileAlign="center"
        >
          <Stack
            direction="row"
            full
            justify="start"
            align="center"
            spacing="20px"
            responsive
            mobileDirection="row"
            mobileJustify="center"
            mobileAlign="center"
          >
            <Link to="/">
              <Image
                src={
                  company?.logo && !company?.logo.includes('user_no_photo.png')
                    ? company?.logo
                    : imgLogo
                }
                alt="Company"
                height="60px"
                fit="contain"
                borderRadius="8px"
              />
            </Link>

            {user?.configuration?.office !== 'couriers' ? (
              <Stack
                direction="row"
                justify="start"
                align="center"
                spacing="20px"
              >
                <Text variant="title">
                  {company?.headerTitle ? company?.headerTitle : ''}
                </Text>
              </Stack>
            ) : (
              <></>
            )}

            {title ? (
              <Stack
                direction="row"
                width="100px"
                justify="start"
                align="center"
                spacing="20px"
              >
                <Link to={link}>
                  <Title>{title}</Title>
                </Link>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
          <Stack
            direction="row"
            justify="flex-end"
            align="center"
            spacing="20px"
            responsive
            mobileAlign="center"
            mobileJustify="center"
          >
            <Cont responsive mobileAlign="center" mobileJustify="center">
              {user?.configuration?.access?.dashboard?.read ||
              user?.configuration?.access?.dashboard?.write ||
              user?.configuration?.access?.requests?.read ||
              user?.configuration?.access?.requests?.write ||
              user?.configuration?.access?.clients?.read ||
              user?.configuration?.access?.clients?.write ||
              user?.configuration?.access?.products.read ||
              user?.configuration?.access?.products.write ||
              user?.configuration?.access?.users?.read ||
              user?.configuration?.access?.users?.write ||
              user?.configuration?.access?.couriers?.read ||
              user?.configuration?.access?.couriers?.write ||
              user?.configuration?.access?.settings?.read ||
              user?.configuration?.access?.settings?.write ? (
                <LinkProfile to="/dashboard">
                  <Image
                    src={
                      user?.configuration?.picture &&
                      !user?.configuration?.picture.includes(
                        'user_no_photo.png',
                      )
                        ? user?.configuration?.picture
                        : imgLogo
                    }
                    width="48px"
                    height="48px"
                    borderRadius="50%"
                  />
                </LinkProfile>
              ) : (
                <LinkProfile to="/">
                  <Image
                    src={
                      user?.configuration?.picture &&
                      !user?.configuration?.picture.includes(
                        'user_no_photo.png',
                      )
                        ? user?.configuration?.picture
                        : imgLogo
                    }
                    width="48px"
                    height="48px"
                    borderRadius="50%"
                  />
                </LinkProfile>
              )}
            </Cont>

            {title === 'Entregas' ? (
              <Button
                variant="ghost"
                onClick={() => {
                  navigate('/configuracoes');
                }}
              >
                PERFIL
              </Button>
            ) : (
              <></>
            )}
            <Button
              variant="ghost"
              onClick={() => {
                singOut();
                if (
                  user?.configuration?.access?.dashboard?.read ||
                  user?.configuration?.access?.dashboard?.write ||
                  user?.configuration?.access?.requests?.read ||
                  user?.configuration?.access?.requests?.write ||
                  user?.configuration?.access?.clients?.read ||
                  user?.configuration?.access?.clients?.write ||
                  user?.configuration?.access?.products.read ||
                  user?.configuration?.access?.products.write ||
                  user?.configuration?.access?.users?.read ||
                  user?.configuration?.access?.users?.write ||
                  user?.configuration?.access?.couriers?.read ||
                  user?.configuration?.access?.couriers?.write ||
                  user?.configuration?.access?.settings?.read ||
                  user?.configuration?.access?.settings?.write
                ) {
                  navigate('/login');
                } else {
                  navigate('/');
                }
              }}
            >
              SAIR
              <HiLogout />
            </Button>
          </Stack>
        </Stack>
      </Header>
    );
  }
}

export default HeaderPage;

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const TagCard = styled.p<any>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  padding: ${(props) => props.padding || '11px 10px'};
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.55;
  color: ${(props) => props.color};
  }};
  width: fit-content;
  align-items: center;
  display: flex;
  gap: 5px;
`;

export default TagCard;

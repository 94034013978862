/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';

import Chart from 'react-apexcharts';

import {
  HiOutlineChartPie,
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineDotsCircleHorizontal,
  HiOutlineInboxIn,
  HiOutlineStar,
} from 'react-icons/hi';

import { ApexOptions } from 'apexcharts';
import useSWR from 'swr';

import moment from 'moment';
import axios from 'axios';
import IcHome from '../../assets/icons/home';
import IcPin from '../../assets/icons/pin';
import BreadcrumbComponent from '../../components/Breadcrumb';
import FailedLoad from '../../components/FailedLoad';
import HeaderPage from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import { Divider, Image, Stack, Tag, Text } from '../../lib';
import api, { apiURL } from '../../services/api';
import fetcher from '../../services/fetcher';
import {
  CardDashboard,
  CardStep,
  Content,
  Dot,
  listCards,
} from '../../styles/style';
import UserName from './user';
import { useAuth } from '../../contexts/AuthContext';
import InsufficientPermission from '../../components/InsufficientPermission';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

const LastestOrders = () => {
  const [data, setData] = useState([]);
  const { user } = useAuth();

  const colorsSteps = [
    '#8b8d8d',
    '#5fc1ce',
    '#ebc747',
    '#1d2091',
    '#0bc117',
    '#8e0b0b',
  ];

  const status = [
    {
      id: 1,
      state: 'IN_QUEUE',
      name: 'Na Fila',
    },
    { id: 2, state: 'IN_PRODUCTION', name: 'Em Produção' },
    { id: 3, state: 'DELIVERY_QUEUE', name: 'Fila de Entregas' },
    { id: 4, state: 'EN_ROUTE', name: 'A caminho' },
    { id: 5, state: 'DELIVERED', name: 'Entregue' },
    { id: 6, state: 'CANCELED', name: 'Cancelados' },
  ];

  const getData = async () => {
    if (user?.configuration?.access?.dashboard?.read) {
      await api.get(`${apiURL}/rest/requests.php`).then((res: any) => {
        const resp = res?.data || [];
        const filteredResp = resp.filter((e) =>
          e?.products?.find((product) => Number(product?.price) > 0),
        );
        setData(filteredResp);
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return user?.configuration?.access?.dashboard?.read ? (
    <>
      {data &&
        data?.length > 0 &&
        data?.slice(0, 3).map((item: any, i: number, row: any) => {
          // eslint-disable-next-line prefer-destructuring
          const address = item.address;
          const statusItem = status.filter((st) => st.state === item.state);

          return (
            <Stack key={item?.id}>
              <Stack
                direction="row"
                justify="space-between"
                padding="10px 0"
                spacing="30px"
              >
                <Stack>
                  <Stack
                    direction="row"
                    align="center"
                    spacing="15px"
                    padding="0 0 15px"
                  >
                    <Tag
                      padding="0px"
                      color={colorsSteps[statusItem[0]?.id - 1]}
                    >
                      {statusItem[0]?.name}
                    </Tag>
                    <Dot
                      color={colorsSteps[statusItem[0]?.id - 1]}
                      size="5px"
                    />
                    <UserName id={item?.user?.id} />
                  </Stack>
                  <Text variant="small" gutter="5px">
                    {moment(item.date).format('DD/MM/YYYY HH:mm')}
                  </Text>
                  <Stack
                    direction="row"
                    as={Text}
                    spacing="10px"
                    align="center"
                  >
                    <span>
                      <IcPin />
                    </span>
                    <Text
                      style={{
                        whiteSpace: 'nowrap',
                        width: '100%',
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {address.bairro}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              {row.length !== i + 1 && <Divider spacing="12px" />}
            </Stack>
          );
        })}
    </>
  ) : (
    <></>
  );
};

const MostReqProducts = () => {
  const orderData: any = useSWR<any>(`${apiURL}/rest/requests.php`, fetcher);
  const productData: any = useSWR<any>(`${apiURL}/rest/products.php`, fetcher);
  let products: any = [];

  if (
    orderData?.data &&
    productData?.data &&
    Array.isArray(orderData?.data) &&
    Array.isArray(productData?.data)
  ) {
    const filteredProducts = productData?.data?.map((e) => ({
      id: e.id,
      image: e.image,
      name: e.name,
      price: e.price,
      counter: 0,
      category: e.category.name,
    }));

    let filteredOrders = orderData?.data?.filter(
      (e) => e.state === 'DELIVERED',
    );

    filteredOrders = filteredOrders.map((e) => ({
      products: e?.products?.map((product) => ({
        ...product,
        date: e?.last_log?.date?.split(' ')[0].replace(/-/g, ''),
      })),
    }));

    filteredOrders = filteredOrders?.flatMap((array) => array.products);

    const newData = filteredProducts?.map((item) => ({ ...item }));

    filteredOrders?.forEach((e) => {
      const index = newData?.findIndex((el) => el?.id === e?.id);
      newData[Number(index)].counter += Number(e?.amount);
    });

    const compareByCounterBigger = (a, b) => {
      return b.counter - a.counter;
    };

    const resp = newData?.filter((e) => e?.counter !== 0).slice(0, 4);

    products = resp?.sort(compareByCounterBigger);
  }

  return (
    <>
      {products &&
        products?.length > 0 &&
        products?.slice(0, 4).map((item: any, i: number, row: any) => (
          <Fragment key={item?.id}>
            <Stack
              direction="row"
              justify="space-between"
              padding="10px 0"
              spacing="15px"
            >
              <Image
                src={
                  item?.image && !item?.image.includes('product_no_photo.png')
                    ? item?.image
                    : imgLogo
                }
                width="65px"
                height="65px"
                borderRadius="50%"
              />
              <Stack direction="row" justify="space-between" align="center">
                <Stack spacing="7px">
                  <Text variant="subtitle" gutter="-4px">
                    {item?.name}
                  </Text>
                  <Text>{item?.category}</Text>
                  <Text variant="small">
                    {item?.counter}
                    {item?.counter > 1 ? ' pedidos ' : ' pedido '}
                    desse produto
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            {row.length !== i + 1 && <Divider spacing="10px" />}
          </Fragment>
        ))}
    </>
  );
};

export default function DashBoard() {
  const [data, setData] = useState([]);
  const [loadingPage, setLoadingPage] = useState<string>('loading');
  const [orderData, setOrderData] = useState<any>([]);
  let deliveredOrdersFilter = [];
  let canceledOrderFilter = [];
  const { user } = useAuth();

  const dayOfTheWeekGenerator = () => {
    const daysOfTheWeek: any = [];
    const date = new Date();
    for (let i = 7; i >= 1; i--) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() - i);
      daysOfTheWeek.push(newDate.toISOString());
    }
    return daysOfTheWeek;
  };

  setTimeout(() => {
    setLoadingPage('');
  }, 1000);

  const getData = async () => {
    await api.get(`${apiURL}/rest/requests.php`).then((res: any) => {
      if (user?.configuration?.access?.dashboard?.read) {
        const resp = res?.data || [];
        const filteredResp = resp.filter((e) =>
          e?.products?.find((product) => Number(product?.price) > 0),
        );
        setData(filteredResp);
      }
    });
  };

  const getOrderData = async () => {
    if (user?.configuration?.access?.dashboard?.read) {
      await api.get(`${apiURL}/rest/requests.php`).then((res: any) => {
        const resp = res?.data || [];
        const filteredResp = resp.filter((e) =>
          e?.products?.find((product) => Number(product?.price) > 0),
        );

        setOrderData(filteredResp);
      });
    }
  };

  useEffect(() => {
    getData();
    getOrderData();
  }, []);

  const generatedDays = dayOfTheWeekGenerator();

  const OrderCounterOfTheDay = () => {
    if (orderData) {
      const filteredFeliveredOrders: any = [];
      const sortingCanceledOrders: any = [];

      const convertToCustomFormat = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hour = String(date.getUTCHours()).padStart(2, '0');
        const minute = String(date.getUTCMinutes()).padStart(2, '0');
        const second = String(date.getUTCSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      };

      const extractDate = (dateTimeString: any) => {
        return Number(dateTimeString.slice(0, 9));
      };

      generatedDays.forEach((element, index) => {
        const isoDate = convertToCustomFormat(generatedDays[index]);

        const orders = orderData?.filter((e) => {
          const date = extractDate(e?.date?.replace(/-/g, ''));
          const previous = extractDate(isoDate.replace(/-/g, ''));
          const current = extractDate(isoDate.replace(/-/g, ''));
          return date >= previous && date <= current;
        });

        const ordersDelivered = orders.filter(
          (e) => e.state === 'DELIVERED',
        ).length;

        const ordersCanceled = orders.filter(
          (e) => e.state === 'CANCELED',
        ).length;

        filteredFeliveredOrders.push(ordersDelivered);
        sortingCanceledOrders.push(ordersCanceled);
      });

      deliveredOrdersFilter = filteredFeliveredOrders;
      canceledOrderFilter = sortingCanceledOrders;
    }
  };

  OrderCounterOfTheDay();

  const optionsChart: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    yaxis: {
      show: true,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    colors: ['#3DBA78', '#C3C7CB'],
    xaxis: {
      labels: {
        show: true,
        format: 'dd/MM',
      },

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
      categories: generatedDays,
    },
    tooltip: {
      x: {
        format: 'dd MMMM yy',
      },
    },
  };
  return user?.configuration?.access?.dashboard?.read ? (
    loadingPage === 'loading' ? (
      <LoadingPage />
    ) : loadingPage === 'error' ? (
      <FailedLoad />
    ) : (
      <>
        <HeaderPage />

        <Content
          style={{
            minHeight: 'auto',
            height: 'calc(100% - 119px)',
          }}
        >
          <BreadcrumbComponent
            name="Dashboard"
            icon={<IcHome />}
            extra={
              <Stack direction="row" align="center" spacing="15px">
                {/* <Input as="select" full placeholder="Selecione um período">
              <option value="monthly" selected>
                Mensal
              </option>
              <option value="weekly">Semanal</option>
              <option value="daily">Diário</option>
            </Input> */}
              </Stack>
            }
          />

          <Stack
            direction="row"
            spacing="15px"
            className="responsive-flex-768"
            style={{ paddingTop: 30 }}
          >
            <Stack
              spacing="15px"
              style={{
                maxWidth: '490px',
              }}
            >
              <Stack as={listCards} direction="row" spacing="15px">
                <CardStep
                  as={Stack}
                  data-aos="fade-up"
                  padding="20px"
                  data-aos-delay="100"
                  direction="column"
                  align="center"
                  justify="center"
                  spacing="10px"
                >
                  <Stack
                    padding="10px"
                    style={{
                      background: '#FEB70C',
                      marginTop: -45,
                      borderRadius: '50%',
                      width: 'fit-content',
                      animation: 'pulse 2s infinite',
                    }}
                    className="pulse-frame"
                  >
                    <HiOutlineClock size="30px" color="#ffffff" />
                  </Stack>
                  <Stack align="center" justify="center">
                    <Text
                      variant="title"
                      style={{ fontSize: 33 }}
                      gutter="-5px"
                      align="center"
                    >
                      {
                        data?.filter((item: any) => item.state === 'IN_QUEUE')
                          .length
                      }
                    </Text>
                    <Text align="center">Na Fila</Text>
                  </Stack>
                </CardStep>
                <CardStep
                  as={Stack}
                  data-aos="fade-up"
                  data-aos-delay="300"
                  padding="20px"
                  direction="column"
                  align="center"
                  justify="center"
                  spacing="10px"
                >
                  <Stack
                    padding="10px"
                    style={{
                      background: '#228CEC',
                      marginTop: -45,
                      borderRadius: '50%',
                      width: 'fit-content',
                    }}
                  >
                    <HiOutlineCheckCircle size="30px" color="#ffffff" />
                  </Stack>
                  <Stack align="center" justify="center">
                    <Text
                      variant="title"
                      style={{ fontSize: 33 }}
                      gutter="-5px"
                      align="center"
                    >
                      {
                        data?.filter((item: any) => item.state === 'DELIVERED')
                          .length
                      }
                    </Text>
                    <Text align="center">Entregues</Text>
                  </Stack>
                </CardStep>
                <CardStep
                  as={Stack}
                  data-aos="fade-up"
                  data-aos-delay="500"
                  padding="20px"
                  style={{
                    border: '1px solid #3DBA78',
                    background: '#3DBA78',
                  }}
                  direction="column"
                  align="center"
                  justify="center"
                  spacing="10px"
                >
                  <Stack
                    padding="10px"
                    style={{
                      background: '#3DBA78',
                      marginTop: -45,
                      borderRadius: '50%',
                      width: 'fit-content',
                    }}
                  >
                    <HiOutlineDotsCircleHorizontal
                      size="30px"
                      color="#ffffff"
                    />
                  </Stack>
                  <Stack align="center" justify="center">
                    <Text
                      variant="title"
                      style={{ fontSize: 33 }}
                      gutter="-5px"
                      align="center"
                      color="#ffffff"
                    >
                      {
                        data?.filter(
                          (item: any) => item.state === 'IN_PRODUCTION',
                        ).length
                      }
                    </Text>
                    <Text align="center" color="#ffffff">
                      Em Produção
                    </Text>
                  </Stack>
                </CardStep>
              </Stack>

              <Stack
                data-aos="fade-up"
                data-aos-delay="800"
                padding="10px"
                as={CardDashboard}
              >
                <Stack
                  padding="10px"
                  direction="row"
                  spacing="15px"
                  align="center"
                  as={Text}
                >
                  <HiOutlineChartPie size="22px" />
                  <Text variant="subtitle">Resumo da semana</Text>
                </Stack>
                <div style={{ width: '-webkit-fill-available' }}>
                  <Chart
                    options={optionsChart}
                    series={[
                      {
                        name: 'Pedidos Entregues',
                        data: deliveredOrdersFilter,
                      },
                      {
                        name: 'Pedidos Cancelados',
                        data: canceledOrderFilter,
                      },
                    ]}
                    type="area"
                    width="100%"
                    height={260}
                  />
                </div>
              </Stack>
            </Stack>
            <Stack
              data-aos="fade-up"
              data-aos-delay="400"
              padding="20px"
              as={CardDashboard}
              style={{
                flexBasis: '25%',
              }}
            >
              <Stack
                direction="row"
                spacing="5px"
                align="center"
                justify="space-between"
              >
                <Stack as={Text} direction="row" spacing="15px" align="center">
                  <HiOutlineInboxIn size="22px" />
                  <Text variant="subtitle">Últimos pedidos</Text>
                </Stack>
              </Stack>
              <Stack direction="column" padding="20px 0 0">
                <LastestOrders />
              </Stack>
            </Stack>
            <Stack
              data-aos-delay="400"
              padding="20px"
              as={CardDashboard}
              style={{
                flexBasis: '25%',
              }}
            >
              <Stack
                direction="row"
                spacing="15px"
                align="center"
                justify="space-between"
              >
                <Stack as={Text} direction="row" spacing="15px" align="center">
                  <HiOutlineStar size="22px" />
                  <Text variant="subtitle">Produtos mais pedido</Text>
                </Stack>
              </Stack>
              <Stack direction="column" padding="20px 0 0">
                <MostReqProducts />
              </Stack>
            </Stack>
          </Stack>
        </Content>
      </>
    )
  ) : (
    <>
      <HeaderPage />
      <InsufficientPermission />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { color } from '../../services/api';

export const Toggle = styled.label`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  background-color: #7c8b9490;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 27px;
    width: 27px;
    left: 0px;
    top: -3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    border: 1px solid #eeeff1;
  }
`;

export const Switcher = styled.div`
  align-self: flex-start;
  position: relative;
  width: 50px;
  margin: 5px 0;
  line-height: 1.4;
  & input:checked + ${Toggle} {
    background-color: rgba(var(--${color}), 0.25);
  }
  & input:focus + ${Toggle} {
    box-shadow: 0 0 1px #6a0bff;
  }
  & input:checked + ${Toggle}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    background-color: rgba(var(--${color}), 1);
    border-color: rgba(var(--${color}), 1);
    transform: translateX(26px);
  }
`;

export default { Switcher, Toggle };

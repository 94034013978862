import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkProfile = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #999;
  :hover {
    opacity: 0.9;
  }
`;

export const Cont = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  @media (max-width: 767px) {
    ${(props) =>
      props.responsive &&
      `
        flex-direction: ${props.mobileDirection || 'column'};
        align-items: ${props.mobileAlign || `flex-start`};
        justify-content: ${props.mobileJustify || `flex-start`};
      `}
  }
`;

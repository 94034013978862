/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { IoMdLogIn } from 'react-icons/io';
import { MdOutlineAlternateEmail, MdOutlineRemoveRedEye } from 'react-icons/md';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api, { apiURL } from '../../services/api';
import isometricLogin from '../../assets/lotties/isometric_login.json';
import { Button, Image, Input, Stack, Text } from '../../lib';
import { IntroduceLogin } from '../../styles/style';
import { WarningModal } from '../../components/Modals/Warning';

export default function PasswordManaged() {
  const { control, handleSubmit } = useForm<any>();
  const [seePswd, setSeePswd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorLogin, setErrorLogin] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [visibleWarningMode, setVisibleWarningMode] = useState(false);
  const [modalAnimation, setModalAnimation] = useState('down');
  const [warningModalValues, setWarningModalValues] = useState([
    'Sucesso',
    'success',
  ]);
  const navigate = useNavigate();
  const urlObj = new URL(window.location.href);
  urlObj.searchParams.entries();

  const { pathname, search } = urlObj;

  const warningModal = (text: string, type: string) => {
    setWarningModalValues([text, type]);
    setVisibleWarningMode(true);
    setModalAnimation('down');
    setTimeout(() => {
      setModalAnimation('up');
      setTimeout(() => {
        setVisibleWarningMode(false);
      }, 200);
    }, 1000);
  };

  const getResetData = async () => {
    let resp: any = [];

    await api
      .get(`${apiURL}/rest/user_reset.php/${search.slice(10)}`)
      .then((res) => {
        resp = res;
      })
      .catch((res) => {
        resp = res;
      });

    return resp;
  };

  const getActivationData = async () => {
    let resp: any = [];

    await api
      .get(`${apiURL}/rest/user_active.php/${search.slice(11)}`)
      .then((res) => {
        resp = res;
      })
      .catch((res) => {
        resp = res;
      });

    return resp;
  };

  const submitPassword = async (data: any) => {
    setErrorLogin(false);
    if (data?.password === data?.confirmPassword) {
      setLoading(true);
      setButtonDisabled(true);

      let user: any = [];
      let url: any = '';

      if (pathname === '/reset_account') {
        user = await getResetData();
        url = `${apiURL}/rest/user_reset.php/${search.slice(10)}`;
      } else if (pathname === '/active_account') {
        user = await getActivationData();
        url = `${apiURL}/rest/user_active.php/${search.slice(11)}`;
      }

      if (user?.data?.email) {
        await api
          .put(url, {
            password: data?.password,
          })
          .then((res) => {
            warningModal('Senha gerada com sucesso!', 'success');
            setTimeout(() => {
              navigate('/login');
            }, 1400);
          })
          .catch((res) => {
            warningModal('Desculpe, ocorreu um erro.', 'error');
          })
          .finally(() => {
            setLoading(false);
            setButtonDisabled(false);
          });
      } else {
        warningModal('Desculpe, ocorreu um erro.', 'error');
        setLoading(false);
        setButtonDisabled(false);
      }
    }
  };

  return (
    <>
      <div style={{ background: '#eee' }}>
        <Stack
          justify="center"
          align="center"
          style={{ height: '100vh' }}
          data-aos="fade-up"
          padding="0 20px"
        >
          <Stack
            direction="row"
            style={{
              maxWidth: '800px',
            }}
            spacing="40px"
            className="responsive-flex-768"
          >
            <IntroduceLogin>
              <Lottie
                options={{ animationData: isometricLogin }}
                height={400}
                width="90%"
              />
            </IntroduceLogin>
            <Stack
              as="form"
              onSubmit={handleSubmit(submitPassword)}
              spacing="30px"
              style={{
                background: '#ffffff',
                borderRadius: '20px',
              }}
              padding="30px"
            >
              <Image
                src="./img/logo_weeke_delivery.svg"
                alt="Pizza Food"
                height="35px"
                width="130px"
                fit="contain"
                position="left"
              />

              <Text variant="label">Senha</Text>
              <Text variant="small">
                Para criar sua senha, preencha o campo "password" com a senha
                desejada e confirme-a no campo "confirmar senha".
              </Text>
              <div style={{ position: 'relative', width: '100%' }}>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      full
                      icon={<MdOutlineRemoveRedEye />}
                      placeholder="Senha"
                      id="password"
                      type={seePswd ? 'text' : 'password'}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />

                <span style={{ position: 'absolute', right: 0, top: 0 }}>
                  <Button
                    type="button"
                    shape="icon"
                    onClick={() => {
                      setSeePswd(!seePswd);
                    }}
                  >
                    {seePswd ? (
                      <HiOutlineEye size="17px" />
                    ) : (
                      <HiOutlineEyeOff size="17px" />
                    )}
                  </Button>
                </span>
              </div>

              <Text variant="label">Confirmar Senha</Text>
              <div style={{ position: 'relative', width: '100%' }}>
                <Controller
                  control={control}
                  name="confirmPassword"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      full
                      icon={<MdOutlineRemoveRedEye />}
                      placeholder="Confirmar Senha"
                      id="confirmPassword"
                      type={seePswd ? 'text' : 'password'}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </div>

              {errorLogin && (
                <Stack>
                  <div
                    style={{
                      width: '100%',
                      borderRadius: 5,
                      backgroundColor: '#ed143d33',
                      padding: '4px 0',
                    }}
                  >
                    <Text align="center" color="#ed143d" weight="600">
                      As senhas não correspondem
                    </Text>
                  </div>
                </Stack>
              )}

              <Stack direction="row" justify="center" align="center">
                <Button
                  full
                  variant="primary"
                  type="submit"
                  loading={loading ? 1 : 0}
                  disabled={isButtonDisabled}
                >
                  Criar Senha
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
      <>
        {visibleWarningMode === true ? (
          <WarningModal
            text={warningModalValues[0]}
            img={warningModalValues[1]}
            animation={modalAnimation}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
}

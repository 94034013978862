import styled from 'styled-components';

export const ContPayment = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: start;

  gap: 20px;
`;

export const CardPayment = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: start;

  gap: 20px;
  padding: 20px 10px;

  background-color: ${(props) => props.theme.backgroundSec};
  border: 1px solid ${(props) => props.theme.shadow || '#e4e8eb'};
  border-radius: 8px;

  > p {
    font-size 14px;
    color: ${(props) => props.theme.text};
  }
`;

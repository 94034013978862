/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { color } from '../../services/api';

import TooltipComponent from '../ToolTip/style';

interface ButtonCustomProps {
  loading?: boolean;
  variant?:
    | 'primary'
    | 'actived_menu'
    | 'ghost'
    | 'menu'
    | 'outline'
    | 'link'
    | 'dangerous';
  full?: boolean;
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  shape?: string;
  mode?: string;
  distance?: number;
  activeMenu?: boolean;
  badge?: string;
}

export const ButtonCustom = styled.button<ButtonCustomProps>`
  border: none;
  position: relative;
  position: relative;
  display: flex;
  justify-content: ${(props) => props.align || 'center'};
  align-items: center;
  box-shadow: none;
  border-radius: 0.375rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: none;
  transition: 300ms all ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  background: transparent;
  white-space: nowrap;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;

  ${(props) =>
    props.badge &&
    css`
      &:before {
        content: '${props.badge}';
        font-size: 0.6rem;
        font-weight: 700;
        line-height: 0.5;
        color: #ffffff;
        position: absolute;
        padding: 5px 5.5px;
        border-radius: 50%;
        background: red;
        top: 0px;
        right: 0px;
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      padding: 0.7rem 1rem;
      font-size: 0.75rem;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 0.55rem 0.85rem;
      font-size: 0.7rem;
    `}

  /* Button Primary */
  ${(props) =>
    props.variant === 'primary' &&
    css`
      background-color: rgba(var(--${color}), 1);
      box-shadow: 0 0 #0000, 0 0 #0000,
        0 10px 15px -3px rgba(var(--${color}), 0.5),
        0 4px 6px -4px rgba(var(--${color}), 0.5);
      color: #ffffff;
      &:hover {
        background-color: rgba(var(--${color}2), 1);
        box-shadow: 0 0 #0000, 0 0 #0000,
          0 10px 15px -10px rgba(var(--${color}), 0.5),
          0 4px 6px -8px rgba(var(--${color}), 0.5);
      }
      &:active {
        z-index: 100;
        color: #ffffffaa;
        background-color: rgba(var(--${color}3), 1);
        transform: translateY(2px);
        box-shadow: none;
      }
    `}

  /* Button Outline */
  ${(props) =>
    props.variant === 'outline' &&
    css`
      color: rgba(var(--${color}3), 1);
      border: 2px solid rgba(var(--${color}3), 1);
      &:hover {
        background-color: rgba(var(--${color}), 0.15);
      }
      &:active {
        z-index: 100;
        color: rgba(var(--${color}3), 0.7);
        background-color: rgba(var(--${color}), 0.3);
        transform: translateY(2px);
      }
    `}

  /* Button Ghost */
  ${(props) =>
    props.variant === 'ghost' &&
    css`
      background-color: rgba(var(--${color}), 0.15);
      color: rgba(var(--${color}3), 1);
      &:hover {
        background-color: rgba(var(--${color}), 0.25);
      }
      &:active {
        z-index: 100;
        background-color: rgba(var(--${color}), 0.4);
        transform: translateY(2px);
        color: rgba(var(--${color}3), 0.5);
      }
    `}

  /* Button ActiveMenu */
  ${(props) =>
    props.variant === 'actived_menu' &&
    css`
      font-weight: 600;
      background-color: rgba(var(--${color}), 0.15);
      color: rgba(var(--${color}3), 1);
      &:hover {
        background-color: rgba(var(--${color}), 0.25);
      }
      &:active {
        z-index: 100;
        background-color: rgba(var(--${color}), 0.4);
        transform: translateY(2px);
        color: rgba(var(--${color}3), 0.5);
      }

      @media only screen and (min-width: 768px) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: -${props.distance || 25}px;
          height: 100%;
          z-index: -1;
          width: 5px;
          background-color: rgba(var(--${color}), 1);
          border-radius: 10px 0 0 10px;
        }
      }
    `}

  /* Button Link */
  ${(props) =>
    props.variant === 'link' &&
    css`
      padding: 0;
      color: rgba(var(--${color}3), 1);
      &:active {
        z-index: 100;
        transform: translateY(0px);
        color: rgba(var(--${color}3), 0.75);
      }
    `}

  /* Button Menu */
  ${(props) =>
    props.variant === 'menu' &&
    css`
      background-color: transparent;
      color: ${props.theme.textSec};
      font-size: 0.875rem;

      font-weight: 400;

      &:hover {
        color: rgba(var(--${color}3), 1);
        background-color: rgba(var(--${color}), 0.075);
      }
      &:active {
        z-index: 100;
        background-color: rgba(var(--${color}), 0.2);
        transform: translateY(2px);
        color: rgba(var(--${color}3), 0.8);
      }
    `}

  /* Button Disable */
  ${(props) =>
    props.disabled &&
    css`
      background-color: #61738080;
      color: #777;
      pointer-events: none;
      cursor: not-allowed;
    `}

  /* Full Button */
  ${(props) =>
    props.full
      ? css`
          width: 100%;
        `
      : css`
          width: fit-content;
        `}


  ${(props) =>
    props.shape === 'icon' &&
    css`
      border-radius: 50%;
      padding: 16px;
      width: auto;
    `}

  ${(props) =>
    props.shape === 'small' &&
    css`
      padding: 0;
      font-size: 0.7rem;
      line-height: 1;
    `}

  /* Dangerous */
  ${(props) =>
    props.mode === 'dangerous' &&
    css`
      color: #b72136;
      &:hover {
        background-color: ${props.variant === 'link'
          ? 'transparent'
          : '#fadcdb'};
        color: #b72136;
      }
      &:active {
        z-index: 100;
        background-color: ${props.variant === 'link'
          ? 'transparent'
          : '#b7213670'};
        transform: translateY(2px);
      }
    `}

    /* Loading */
    ${(props) =>
    props.loading &&
    css`
      pointer-events: none;
      background-color: rgba(var(--${color}3), 1);
    `}

    &:hover {
    & ${TooltipComponent} {
      opacity: 1;
    }
  }
`;

export default ButtonCustom;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import BreadcrumbComponent from '../../components/Breadcrumb';
import FailedLoad from '../../components/FailedLoad';
import HeaderPage from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import ModalDelete from '../../components/Modals/Delete';
import {
  Button,
  CheckBox,
  Drawer,
  Image,
  Input,
  Stack,
  Tag,
  Text,
  ToggleButton,
  Uploader,
} from '../../lib';
import api, { apiURL, themeSystem } from '../../services/api';
import { Content, Card } from '../../styles/style';
import IcClients from '../../assets/icons/clients';
import imgChecked from '../../assets/icons/checked.svg';
import imgUnchecked from '../../assets/icons/unchecked.svg';
import { useAuth } from '../../contexts/AuthContext';
import InsufficientPermission from '../../components/InsufficientPermission';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

export default function Users() {
  const { getValues, control, reset, handleSubmit } = useForm<any>();
  const [modal, setModal] = React.useState<boolean>(false);
  const [modalDel, setModalDel] = React.useState<boolean>(false);
  const [modalPerms, setModalPerms] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errorData, setErrorData] = React.useState<boolean>(false);
  const [onSearch, setOnSearch] = useState<string>('');
  const [upload, setUpload] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const { user } = useAuth();
  const [data, setData] = useState<any>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [loadingPage, setLoadingPage] = useState<string>('loading');

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    if (user?.configuration?.access?.clients?.read) {
      setLoading(true);
      setLoadingPage('loading');
      api
        .get(`${apiURL}/rest/users.php`)
        .then((res) => {
          const resp = res.data;
          setData(
            resp?.filter(
              (e) =>
                !e.configuration?.access?.dashboard?.read &&
                !e.configuration?.access?.dashboard?.write &&
                !e.configuration?.access?.requests?.read &&
                !e.configuration?.access?.requests?.write &&
                !e.configuration?.access?.clients?.read &&
                !e.configuration?.access?.clients?.write &&
                !e.configuration?.access?.products?.read &&
                !e.configuration?.access?.products?.write &&
                !e.configuration?.access?.users?.read &&
                !e.configuration?.access?.users?.write &&
                !e.configuration?.access?.couriers?.read &&
                !e.configuration?.access?.couriers?.write &&
                !e.configuration?.access?.settings?.read &&
                !e.configuration?.access?.settings?.write &&
                !e.configuration?.access?.historic?.read &&
                !e.configuration?.access?.historic?.write,
            ),
          );
        })
        .catch((e) => {
          console.log(e);
          setErrorData(true);
        })
        .finally(() => {
          setLoadingPage('');
          setLoading(false);
        });
    }
  }

  const updateUser = (data: any) => {
    if (user?.configuration?.access?.clients?.write) {
      setLoading(true);
      const formData = new FormData();
      formData.append('avatar', upload);
      setButtonDisabled(true);

      api
        .put(`${apiURL}/rest/users.php/${data.id}`, {
          email: data.email,
          configuration: data.configuration,
          address: [
            {
              cep: data.cep,
              bairro: data.bairro,
              cidade: data.cidade,
              uf: data.uf,
              complemento: data.complemento,
              numero: data.numero,
              rua: data.rua,
            },
          ],
        })
        .then(() => {
          if (upload !== 'null') {
            api({
              method: 'post',
              url: `rest/upload.php/user/${data.id}`,
              data: formData,
            });
          } else if (upload === 'null') {
            api
              .put(`${apiURL}/rest/users.php/${data.id}`, {
                configuration: { picture: null },
              })
              .then((res) => {
                console.log(res);
              });
          }
        })
        .catch((e) => {
          console.log(e);
          setErrorData(true);
        })
        .finally(() => {
          setTimeout(() => {
            getData();
            setModal(false);
            setLoading(false);
            setButtonDisabled(false);
          }, 1000);
        });
    }
  };

  return user?.configuration?.access?.clients?.read ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <>
          <HeaderPage />
          <Content>
            <BreadcrumbComponent
              name="Clientes"
              icon={<IcClients />}
              length={data.length}
              search={
                <Input
                  type="text"
                  placeholder="Pesquisar ..."
                  icon={<BsSearch />}
                  id="name"
                  value={onSearch}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setOnSearch(e.currentTarget?.value);
                  }}
                />
              }
            />
            <Stack
              direction="row"
              spacing="20px"
              align="center"
              wrap="true"
              data-aos="fade-up"
              padding="5px"
            >
              {errorData ? (
                <FailedLoad />
              ) : loading ? (
                <LoadingPage />
              ) : (
                data
                  ?.filter((e: any) =>
                    e.configuration?.name
                      .toLowerCase()
                      .includes(onSearch.toLowerCase()),
                  )
                  ?.map((item: any) => (
                    <Card
                      key={item.configuration.name}
                      as={Stack}
                      data-id={item.id}
                      direction="row"
                      spacing="20px"
                      align="center"
                      onClick={(e: any) => {
                        reset(item);
                        setModal(true);

                        setSelectedUser(
                          data.find(
                            (el: any) => el.id === e.currentTarget.dataset.id,
                          ),
                        );
                      }}
                      onMouseEnter={(e: any) => {
                        setSelectedUser(
                          data.find(
                            (el: any) => el.id === e.currentTarget.dataset.id,
                          ),
                        );
                      }}
                    >
                      <Image
                        src={
                          item?.configuration?.picture &&
                          item?.configuration?.picture !== 'null' &&
                          !item?.configuration?.picture?.includes(
                            'user_no_photo.png',
                          )
                            ? item?.configuration?.picture
                            : imgLogo
                        }
                        width="48px"
                        height="48px"
                        borderRadius="50%"
                      />
                      <Stack
                        direction="row"
                        justify="space-between"
                        align="center"
                      >
                        <div>
                          <Text weight="600">{item.configuration.name}</Text>
                        </div>
                        <div>
                          <Tag
                            color={item.configuration.enabled ? 'green' : 'red'}
                          >
                            {item.configuration.enabled
                              ? 'Ativo'
                              : 'Desativado'}
                          </Tag>
                        </div>
                      </Stack>
                    </Card>
                  ))
              )}
            </Stack>
          </Content>
          <ModalDelete
            set={setModalDel}
            disabled={isButtonDisabled}
            setDisabled={setButtonDisabled}
            state={modalDel}
            update={() => getData()}
            item={`${apiURL}/rest/users.php/${getValues('id')}`}
          />
          <Drawer set={setModal} state={modal} title="Editar Usuário">
            <Stack spacing="30px" as="form" onSubmit={handleSubmit(updateUser)}>
              <Stack direction="row" spacing="10px" align="end">
                <Text variant="label">Email Verificado</Text>
                <Image
                  src={
                    getValues('configuration.actived')
                      ? imgChecked
                      : imgUnchecked
                  }
                  width="16px"
                  height="16px"
                />
              </Stack>
              <Text variant="label">Status</Text>
              <Stack direction="row" spacing="15px">
                <Controller
                  control={control}
                  name="configuration.enabled"
                  render={({ field: { onChange, value, ref } }) => (
                    <ToggleButton
                      onChange={onChange}
                      checked={value}
                      ref={ref}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="id"
                  render={({ field: { value, ref } }) => (
                    <Input full value={value} ref={ref} readOnly hidden />
                  )}
                />
              </Stack>

              <Text variant="label">Nome do Usuário</Text>
              <Controller
                control={control}
                name="configuration.name"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Nome do Usuário"
                    value={value}
                    ref={ref}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Uploader
                thumb={getValues('configuration.picture')}
                upload={(img: any) => setUpload(img)}
                user={selectedUser}
              />

              <Text variant="label">Telefone</Text>
              <Controller
                control={control}
                name="configuration.phone"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Telefone"
                    value={value}
                    ref={ref}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">E-mail</Text>
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Ex.: email@gmail.com"
                    value={value}
                    ref={ref}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">CEP</Text>
              <Controller
                control={control}
                name="cep"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex.: 79999-000"
                    defaultValue={getValues('address[0].cep')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Rua</Text>
              <Controller
                control={control}
                name="rua"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex.: Rua das flores"
                    defaultValue={getValues('address[0].rua')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Numero</Text>
              <Controller
                control={control}
                name="numero"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex: 23"
                    defaultValue={getValues('address[0].numero')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Bairro</Text>
              <Controller
                control={control}
                name="bairro"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex: Bairro do Jardim"
                    defaultValue={getValues('address[0].bairro')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Cidade</Text>
              <Controller
                control={control}
                name="cidade"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex: Campo Verde"
                    defaultValue={getValues('address[0].cidade')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">UF</Text>
              <Controller
                control={control}
                name="uf"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    placeholder="Ex: MT"
                    defaultValue={getValues('address[0].uf')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Text variant="label">Complemento</Text>
              <Controller
                control={control}
                name="complemento"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    full
                    defaultValue={getValues('address[0].complemento')}
                    value={value}
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />

              <Stack padding="15px 0 0">
                <Button
                  variant="link"
                  type="button"
                  full
                  onClick={() => setModalPerms(true)}
                >
                  Editar Permissões
                </Button>
              </Stack>

              <Stack
                direction="row"
                align="center"
                spacing="20px"
                padding="15px 0"
                style={{
                  position: 'sticky',
                  bottom: '-25px',
                  background: themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
                }}
              >
                <Button
                  variant="ghost"
                  full
                  type="button"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  full
                  loading={loading ? 1 : 0}
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  Salvar
                </Button>
              </Stack>

              <Button
                full
                mode="dangerous"
                type="button"
                onClick={() => {
                  setModalDel(true);
                  setModal(false);
                }}
              >
                <FiTrash />
                Deletar
              </Button>
            </Stack>
          </Drawer>
          <Drawer set={setModalPerms} state={modalPerms} title="Permissões">
            <Stack spacing="20px">
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Dashboard
                  </Text>
                  <Text>Administrar a dashboard da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.dashboard.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.dashboard.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.dashboard.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.dashboard.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.dashboard.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.dashboard.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Clientes
                  </Text>
                  <Text>Administrar clientes da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.clients.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.clients.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.clients.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.clients.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.clients.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.clients.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Pedidos
                  </Text>
                  <Text>Administrar os pedidos da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.requests.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.requests.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.requests.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.requests.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.requests.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.requests.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Produtos
                  </Text>
                  <Text>Administrar produtos da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.products.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.products.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.products.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.products.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.products.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.products.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Usuários
                  </Text>
                  <Text>Administrar usuários da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.users.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.users.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.users.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.users.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.users.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.users.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Entregadores
                  </Text>
                  <Text>Administrar entregadores da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.couriers.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.couriers.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.couriers.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.couriers.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.couriers.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.couriers.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Histórico
                  </Text>
                  <Text>Administrar histórico da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.historic.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.historic.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.historic.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.historic.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.historic.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.historic.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" align="center" spacing="20px">
                <Stack size="50%">
                  <Text weight="600" gutter="10px">
                    Configurações
                  </Text>
                  <Text>Administrar configurações da loja</Text>
                </Stack>
                <Stack size="50%" align="center">
                  <Button
                    variant="menu"
                    dropDown={
                      <Stack spacing="15px" padding="10px">
                        <Controller
                          control={control}
                          name="configuration.access.settings.read"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.settings.read"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Leitura
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.settings.write"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.settings.write"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Escrita
                            </CheckBox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="configuration.access.settings.exec"
                          render={({ field: { onChange, value, ref } }) => (
                            <CheckBox
                              id="configuration.access.settings.exec"
                              ref={ref}
                              onChange={onChange}
                              checked={value}
                            >
                              Execução
                            </CheckBox>
                          )}
                        />
                      </Stack>
                    }
                  >
                    Selecionar opções <HiChevronDown />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Drawer>
        </>
      )}
    </>
  ) : (
    <>
      <HeaderPage />
      <InsufficientPermission />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { color } from '../../services/api';

interface Props {
  width?: string;
}

export const ModalFloat = styled.div<Props>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center; 
  justify-content: center;
  display:
  width: 100%;
  height: 100vh;
  z-index: 130;
`;
export const ModalBody = styled.div<Props>`
  box-sizing: border-box;
  width: ${(props) => props.width || '400px'};
  background-color: ${(props) => props.theme.backgroundSec || 'white'};
  box-shadow: 0px -10px 50px -15px #00000020;
  border-radius: 10px;
  padding: 20px;
  @media only screen and (max-width: 400px) {
    width: 320px;
  }
  position: relative;
`;
export const ModalOverlay = styled.div<Props>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(var(--${color}3), 0.6);
  backdrop-filter: blur(5px);
  z-index: 119;
`;

export const ContChildren = styled.div<Props>`
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px 20px 20px 0px;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 20px;
`;
export default { ModalFloat, ModalOverlay, ModalBody };

import { IDefaultTheme } from 'styled-components';

export const light: IDefaultTheme = {
  text: '#212B36',
  textSec: '#637281',
  background: '#fff',
  backgroundSec: '#F4F6F8',
  backgroundTerc: '#fafbfb',
  shadow: '#e4e8eb',
};

export const dark: IDefaultTheme = {
  text: '#ffffff',
  textSec: '#ffffff',
  background: '#191A1F',
  backgroundSec: '#232429',
  backgroundTerc: '#191A1F',
  shadow: '#111214',
};

export default { light, dark };

// Components
import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { FaHeadphonesAlt } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import IcClients from '../../assets/icons/clients';
import IcClientsFilled from '../../assets/icons/clients_filled';
import IcHome from '../../assets/icons/home';
import IcHomeFilled from '../../assets/icons/home_filled';
import IcOrders from '../../assets/icons/orders';
import IcOrdersFilled from '../../assets/icons/orders_filled';
import IcProducts from '../../assets/icons/products';
import IcProductsFilled from '../../assets/icons/products_filled';
import IcSett from '../../assets/icons/setting';
import IcSettFilled from '../../assets/icons/setting_filled';
import IcUsers from '../../assets/icons/users';
import IcUsersFilled from '../../assets/icons/users_filled';
import ImgDeliveryman from '../../assets/icons/deliveryman';
import { Button, Divider, Image, Stack, Text, ToolTip } from '../../lib';
import api, { color } from '../../services/api';
import { NavBar, Label } from '../../styles/style';
import DeliverymanFilled from '../../assets/icons/deliveryman_filled';
import IcHistoric from '../../assets/icons/historic';
import IcHistoricFilled from '../../assets/icons/historic_filled';
import { Tag } from './style';
import imgEmphasis from '../../assets/icons/emphasis.svg';

function Sidebar(): JSX.Element {
  const [company, setCompany] = React.useState<any>([]);
  const [collapsedMenu, setCollapsedMenu] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const menuList = [
    {
      id: 1,
      name: 'Dashboard',
      url: '/dashboard',
      icon: <IcHome />,
      actived: <IcHomeFilled />,
    },
    {
      id: 2,
      name: 'Usuários',
      url: '/usuarios',
      icon: <IcUsers />,
      actived: <IcUsersFilled />,
    },
    {
      id: 3,
      name: 'Produtos',
      url: '/produtos',
      icon: <IcProducts />,
      actived: <IcProductsFilled />,
      tag: (
        <Tag>
          <img src={imgEmphasis} alt="Destaque" />
        </Tag>
      ),
    },
    {
      id: 4,
      name: 'Quadro Pedidos',
      url: '/pedidos',
      icon: <IcOrders />,
      actived: <IcOrdersFilled />,
      tag: (
        <Tag>
          <img src={imgEmphasis} alt="Destaque" />
        </Tag>
      ),
    },
    {
      id: 5,
      name: 'Histórico',
      url: '/historico',
      icon: <IcHistoric width="18px" height="18px" color="currentColor" />,
      actived: <IcHistoricFilled width="18px" height="18px" />,
    },
    {
      id: 6,
      name: 'Clientes',
      url: '/clientes',
      icon: <IcClients />,
      actived: <IcClientsFilled />,
    },
    {
      id: 7,
      name: 'Entregadores',
      url: '/entregadores',
      icon: <ImgDeliveryman width="20px" height="20px" color="currentColor" />,
      actived: <DeliverymanFilled />,
    },
    {
      id: 8,
      name: 'Meu Weeke',
      url: '/configuracoes',
      icon: <IcSett />,
      actived: <IcSettFilled />,
    },
  ];

  const getCompany = async () => {
    await api.get(`/rest/options.php/logo`).then((res) => setCompany(res.data));
  };

  useEffect(() => {
    getCompany();
    setCollapsedMenu(
      JSON.parse(window.localStorage.getItem('collapsedMenu') || 'true'),
    );
  }, []);

  useEffect(() => {
    window.localStorage.setItem('collapsedMenu', collapsedMenu.toString());
  }, [collapsedMenu]);

  return (
    <NavBar collapsed={collapsedMenu}>
      <Stack
        direction="row"
        justify={!collapsedMenu ? 'space-between' : 'center'}
        align="center"
      >
        {!collapsedMenu && (
          <Link to="/">
            <Image
              src={company?.logo}
              alt="Pizza Food"
              height="60px"
              width="130px"
              fit="contain"
              position="left"
              borderRadius="8px"
            />
          </Link>
        )}
        <Button
          shape="icon"
          variant="menu"
          onClick={() => setCollapsedMenu(!collapsedMenu)}
        >
          {!collapsedMenu ? <BiChevronLeft /> : <BiChevronRight />}
        </Button>
      </Stack>
      <Stack
        justify="flex-start"
        align="flex-start"
        padding="5px 0 17px 0"
        spacing="8px"
      >
        <Stack spacing="8px">
          {menuList.slice(0, 3).map((item) => (
            <Button
              full
              align="flex-start"
              variant={location.pathname === item.url ? 'ghost' : 'menu'}
              key={item.name}
              shape={collapsedMenu ? 'icon' : ''}
              onClick={() => {
                navigate(item.url);
              }}
            >
              {location.pathname === item.url ? item.actived : item.icon}
              {!collapsedMenu ? item.name : <ToolTip>{item.name}</ToolTip>}
              {item?.tag && !collapsedMenu ? item?.tag : ''}
            </Button>
          ))}
        </Stack>
        <Stack spacing="8px">
          {menuList.slice(3, 7).map((item) => (
            <Button
              variant={location.pathname === item.url ? 'ghost' : 'menu'}
              key={item.name}
              onClick={() => {
                navigate(item?.url);
              }}
              shape={collapsedMenu ? 'icon' : ''}
              full
              align="flex-start"
            >
              {location.pathname === item.url ? item.actived : item.icon}
              {!collapsedMenu ? item.name : <ToolTip>{item.name}</ToolTip>}
              {item?.tag && !collapsedMenu ? item?.tag : ''}
            </Button>
          ))}
        </Stack>
        {!collapsedMenu && <Label>Configurações</Label>}
        <Stack spacing="8px">
          {menuList.slice(7, 8).map((item) => (
            <Button
              variant={location.pathname === item.url ? 'ghost' : 'menu'}
              key={item?.name}
              onClick={() => {
                navigate(item.url);
              }}
              shape={collapsedMenu ? 'icon' : ''}
              full
              align="flex-start"
            >
              {location?.pathname === item?.url ? item?.actived : item?.icon}
              {!collapsedMenu ? item?.name : <ToolTip>{item?.name}</ToolTip>}
              {item?.tag && !collapsedMenu ? item?.tag : ''}
            </Button>
          ))}
        </Stack>
      </Stack>
      {!collapsedMenu && (
        <>
          <Divider />
          <Stack spacing="18px" padding="20px 0" align="center">
            <Stack
              padding="20px"
              style={{
                width: 'fit-content',
                borderRadius: '50%',
                backgroundColor: `rgba(var(--${color}), .1)`,
              }}
            >
              <FaHeadphonesAlt
                size="30px"
                color={`rgba(var(--${color}3), 1)`}
              />
            </Stack>
            <div>
              <Text variant="subtitle" align="center">
                Precisa de ajuda?
              </Text>
              <Text align="center"> Entre em contato com o suporte</Text>
            </div>

            <Button
              variant="primary"
              full
              onClick={() => window.open('https://www.weeke.com.br/suporte')}
            >
              Fale Conosco
            </Button>
          </Stack>
        </>
      )}
    </NavBar>
  );
}

export default Sidebar;

import React from 'react';

import { DividerBorder } from './style';

interface Props {
  spacing?: string;
  style?: Record<string, unknown>;
}

const Divider: React.FC<Props> = ({ ...props }) => {
  return <DividerBorder {...props} />;
};

export default Divider;

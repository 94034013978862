import React, { useEffect } from 'react';
import { BsPlus } from 'react-icons/bs';
import { themeSystem } from '../../services/api';

import { Button, Stack, Text } from '../../lib';

const BreadcrumbComponent = (props: {
  icon: any;
  name: string;
  search?: any;
  length?: number;
  addNew?: any;
  extra?: any;
  permission?: any;
}) => {
  // Responsividade
  const match = () => {
    if (typeof window !== 'undefined') {
      if (!window.matchMedia) {
        return false;
      }
      return window.matchMedia('(max-width: 767px)').matches;
    }
    return false;
  };

  const [responsive, setResponsive] = React.useState<boolean>(match);

  useEffect(() => {
    const handler = () => setResponsive(match);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <Stack
      padding="15px 0 15px 0"
      direction="row"
      align="center"
      justify="space-between"
      data-aos="fade-down"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
      }}
    >
      <Stack align="flex-start" spacing="10px">
        <Stack spacing="12px" direction="row" align="center" as={Text}>
          {props.icon}
          <Text variant="title">{props.name}</Text>
        </Stack>
        {props.length ? (
          <Text variant="small">{props.length} registro(s)</Text>
        ) : (
          ''
        )}
      </Stack>
      <Stack direction="row" spacing="20px" justify="flex-end">
        {!responsive && props.search && (
          <Stack style={{ width: 'fit-content' }}>{props.search}</Stack>
        )}
        <Stack
          spacing="15px"
          direction="row"
          align="flex-start"
          style={{ width: 'fit-content' }}
        >
          {props.extra}
          {props.addNew && props.permission && (
            <Button
              variant="primary"
              onClick={props.addNew}
              shape={responsive ? 'icon' : ''}
            >
              <BsPlus size="16px" />
              {!responsive && 'Adicionar'}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BreadcrumbComponent;

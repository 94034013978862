/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// Componentes
import { BiChevronDown } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import {
  Text,
  FormItem,
  Stack,
  ModalHead,
  ModalBottom,
  ModalBody,
  Button,
  ModalOverlay,
  Input,
} from '../../lib/style';

import api, { apiURL, color } from '../../../../services/api';
import { useAuth } from '../../../../contexts/AuthContext';
import { WarningModal } from '../../../../components/Modals/Warning';

export default function ModalLogin({ open, close }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [newAccount, setNewAccount] = useState(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [statusPage, setStatusPage] = useState('login');
  const [visibleWarningMode, setVisibleWarningMode] = useState(false);
  const [modalAnimation, setModalAnimation] = useState('down');
  const [warningModalValues, setWarningModalValues] = useState([
    'Sucesso',
    'success',
  ]);

  const warningModal = (text, type) => {
    setWarningModalValues([text, type]);
    setVisibleWarningMode(true);
    setModalAnimation('down');
    setTimeout(() => {
      setModalAnimation('up');
      setTimeout(() => {
        setVisibleWarningMode(false);
      }, 200);
    }, 1000);
  };

  const loginUser = async (data) => {
    setButtonDisabled(true);

    await signIn(data)
      .then(() => {
        close();
        window.location.reload();
      })
      .catch((res) => {
        setError('password', {
          type: 'custom',
          message: 'E-mail ou senha incorreto(s)',
        });
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  const submitForgotPassword = async (data) => {
    setButtonDisabled(true);

    await api
      .post(`${apiURL}/rest/user_reset.php`, {
        email: data.email,
      })
      .then((res) => {
        warningModal('Email de recuperação enviado com sucesso!', 'success');
        setTimeout(() => {
          setStatusPage('login');
        }, 1400);
      })
      .catch((res) => {
        warningModal('Desculpe, ocorreu um erro.', 'error');
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  const createAccount = async (data) => {
    if (data.password === data.confirm_password) {
      await axios
        .post(`${apiURL}/rest/users.php`, {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          if (res.data === true) {
            setError('email', {
              type: 'custom',
              message: 'Uma conta com esse e-mail ja existe',
            });
          } else {
            loginUser(data);
          }
          close();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setError('password', {
        type: 'custom',
        message: 'As senhas fornecidas não coincidem.',
      });
    }
  };

  const themeColor = useContext(ThemeContext);

  return (
    <>
      <CSSTransition in={open} timeout={300} classNames="fade" unmountOnExit>
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modalbottom"
        unmountOnExit
      >
        <ModalBottom>
          {statusPage === 'login' ? (
            <ModalBody>
              <ModalHead>
                <Stack direction="row" spacing="15px" alignItems="center">
                  <BiChevronDown
                    size="35"
                    color={`rgba(var(--${themeColor.color}), 1)`}
                    onClick={close}
                  />
                  <Text variant="title">Login</Text>
                </Stack>
              </ModalHead>
              <FormItem>
                <Text variant="small" gutter="12px">
                  E-mail
                </Text>
                <Input
                  error={!!errors?.email?.message}
                  placeholder="E-mail"
                  {...register('email', { required: true })}
                />
                {!!errors?.email?.message && (
                  <Text
                    variant="small"
                    gutter="12px"
                    color="red"
                    style={{ paddingTop: 5 }}
                  >
                    {errors?.email?.message}
                  </Text>
                )}
                <Text variant="small" gutter="12px" style={{ marginTop: 15 }}>
                  Senha
                </Text>
                <Input
                  error={!!errors?.password?.message}
                  placeholder="Senha"
                  type="password"
                  {...register('password', { required: true })}
                />

                {newAccount ? (
                  <></>
                ) : (
                  <Button
                    padding="5px 0px"
                    size="0.6rem"
                    variant="link"
                    type="button"
                    onClick={() => {
                      setStatusPage('forgotPassword');
                    }}
                  >
                    Esqueceu a senha?
                  </Button>
                )}

                {!!errors?.password?.message && (
                  <Text
                    variant="small"
                    gutter="12px"
                    color="red"
                    style={{ paddingTop: 5 }}
                  >
                    {errors?.password?.message}
                  </Text>
                )}
                {newAccount && (
                  <>
                    <Text
                      variant="small"
                      gutter="12px"
                      style={{ marginTop: 15 }}
                    >
                      Confirmar senha
                    </Text>
                    <Input
                      error={!!errors.confirm_password}
                      placeholder="Confirmar senha"
                      type="password"
                      {...register('confirm_password', { required: true })}
                    />
                  </>
                )}
              </FormItem>
              <Stack direction="row" spacing="20px">
                {!newAccount ? (
                  <>
                    <Button
                      variant="link"
                      size="md"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setNewAccount(true);
                      }}
                    >
                      Criar uma conta
                    </Button>
                    <Button
                      variant="solid"
                      size="md"
                      style={{ width: '100%' }}
                      onClick={handleSubmit(loginUser)}
                      disabled={isButtonDisabled}
                    >
                      Login
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="link"
                      size="md"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setNewAccount(false);
                      }}
                    >
                      Ja tenho conta
                    </Button>
                    <Button
                      variant="solid"
                      size="md"
                      style={{ width: '100%' }}
                      onClick={handleSubmit(createAccount)}
                    >
                      Criar
                    </Button>
                  </>
                )}
              </Stack>
            </ModalBody>
          ) : (
            <ModalBody>
              <ModalHead>
                <Stack direction="row" spacing="15px" alignItems="center">
                  <BiChevronDown
                    size="35"
                    color={`rgba(var(--${themeColor.color}), 1)`}
                    onClick={close}
                  />
                  <Text variant="title">Recuperar conta</Text>
                </Stack>
              </ModalHead>
              <FormItem>
                <Text variant="small" gutter="12px">
                  E-mail
                </Text>
                <Input
                  error={!!errors?.email?.message}
                  placeholder="E-mail"
                  {...register('email', { required: true })}
                />
              </FormItem>
              <Stack direction="row" spacing="20px">
                <>
                  <Button
                    variant="link"
                    size="md"
                    style={{ width: '100%' }}
                    onClick={() => {
                      setStatusPage('login');
                    }}
                  >
                    Retornar ao login
                  </Button>
                  <Button
                    variant="solid"
                    size="md"
                    style={{ width: '100%' }}
                    onClick={handleSubmit(submitForgotPassword)}
                    disabled={isButtonDisabled}
                  >
                    Enviar
                  </Button>
                </>
              </Stack>
            </ModalBody>
          )}
        </ModalBottom>
      </CSSTransition>
      <>
        {visibleWarningMode === true ? (
          <WarningModal
            text={warningModalValues[0]}
            img={warningModalValues[1]}
            animation={modalAnimation}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
}

import styled, { css } from 'styled-components';

import { color } from '../../services/api';

export const CardBoard = styled.div<any>`
  text-transform: uppercase;
  position: relative;
  margin: 5px 0;
  background-color: ${(props) => props.theme.background};
  border-radius: 10px;
  border-left: ${(props) =>
    props.borderLeft !== 'false'
      ? props.borderLeft
      : `1px solid ${props.theme.shadow}`};
  border-right: 1px solid ${(props) => props.theme.shadow};
  border-top: 1px solid ${(props) => props.theme.shadow};
  border-bottom: 1px solid ${(props) => props.theme.shadow};
  padding: 18px;
  user-select: none;
  width: inherit;
  cursor: grab;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: 300ms all ease;
  &:active {
    box-shadow: 0 0px 0px 2px inset rgba(var(--${color}), 1);
  }
  &.dragging {
    opacity: 0;
    transform: rotate(-5deg);
  }
  ${(props) =>
    props.loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  : hover {
    z-index: 1;
    transform: scale(1.05);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;

  gap: 20px;

  padding: 20px 10px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.background};

  border-radius: 8px;
`;

export const Cont = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;

  flex-direction: column;

  gap: 20px;
`;

export const ContTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  > h2 {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.text};
  }

  > span {
    font-size: 8px;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    padding: 8px;
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Description = styled.p<any>`
  display: ${(props) => props.display || 'none'};
  color: ${(props) => props.theme.text};
  font-size: 12px;
`;

export const ContDescription = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    font-weight: bold;
    font-size: 12px;
    color: ${(props) => props.theme.text};
  }

  > img {
    width: 12px;
    height: 12px;
  }
`;

export const ContObservation = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color};
  background-color: ${(props) => props.background};

  gap: 3px;

  border-radius: 8px;
  padding: 10px;

  border: 2px ${(props) => props.color} dashed;

  word-break: break-all;
  line-height: 1.5;
  word-spacing: 0.2px;
  font-size: ${(props) => props.size};
`;

export const TextPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-top: 10px;
    color: ${(props) => props.theme.text};
    font-size: 12px;
  }
`;

export const ColumnInfoContainer = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;

  gap: 10px;

  word-break: break-all;
  line-height: 1.5;
  word-spacing: 0.2px;

  > span ~ span {
    border: 1px solid ${(props) => props.theme.shadow};
    border-radius: 8px;
    padding: 12px 10px;
    font-size: ${(props) => props.size};
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.background};
  }
`;

export const HiddenTextContainer = styled.div<any>`
  position: relative;

  font-size: ${(props) => props.size};
  color: ${(props) => props.theme.text};

  > span {
    z-index: 1;
    position: absolute;
    top: -30px;
    display: ${(props) => props.display || 'none'};
    border-radius: 8px;
    padding: 12px 10px;
    background-color: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.text};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    white-space: nowrap;
  }
`;

export const CommandItem = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  > span {
    font-size: 8px;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    padding: 8px;
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TextCommands = styled.p<any>`
  font-size: 12px;
  color: ${(props) => props.color || props.theme.text};
`;

export const SummaryValues = styled.div<any>`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  gap: 10px;

  font-size: 12px;
  color: ${(props) => props.theme.text};

  > h3 {
    margin: 0px;
    font-weight: bold;
  }

  > div {
    width: 100%;

    display: flex;
    align-items: start;
    justify-content: space-between;
  }
`;

export const ContainerText = styled.div<any>`
  > h3 {
    margin: 0px;
    font-weight: bold;
  }

  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  gap: 10px;

  font-size: 12px;
  color: ${(props) => props.theme.text};
`;

export const ContOptions = styled.div<any>`
  box-sizing: border-box;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  font-size: 10px;
  color: ${(props) => props.theme.text};

  padding-left: 10px;
  margin-left: 40px;
  border-left: 1px solid ${(props) => props.theme.text};

  > div {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    > span {
      font-size: 8px;
      font-weight: bold;
      padding: 8px;
      border: 1px solid ${(props) => props.theme.text};
      border-radius: 5px;
    }
  }
`;

export const ContCommand = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 20px;
  width: 100%;
  flex-direction: column;

  padding: ${(props) => props.padding};

  border: ${(props) => props.border};
  border-radius: 8px;
`;

export const ContOptionsModal = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  > span {
    font-size: 12px;
    color: ${(props) => props.theme.text};
  }
`;

/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import BreadcrumbComponent from '../../components/Breadcrumb';
import FailedLoad from '../../components/FailedLoad';
import HeaderPage from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import ModalDelete from '../../components/Modals/Delete';
import { useAuth } from '../../contexts/AuthContext';
import { Button, Drawer, Image, Input, Stack, Tag, Text } from '../../lib';
import api, { apiURL, themeSystem } from '../../services/api';
import { Content, Card } from '../../styles/style';
import ImgDeliveryman from '../../assets/icons/deliveryman';
import InsufficientPermission from '../../components/InsufficientPermission';
import { Cont, Phone, PhoneScreen } from './style';
import filter from '../../assets/icons/filter.svg';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';
import {
  Filter,
  FilterList,
  CardDelivery,
  CardHeader,
  CardBody,
  CardFooter,
} from '../deliveries/style';
import {
  ModalDeliveries,
  colorsSteps,
  backgroundColors,
  lists,
} from '../../components/Modals/Orders';
import right from '../../assets/icons/right.svg';

export default function Deliveryman() {
  const { user } = useAuth();
  const { getValues, control, reset, handleSubmit } = useForm<any>();
  const [modal, setModal] = React.useState<boolean>(false);
  const [modalDeliveries, setModalDeliveries] = React.useState<boolean>(false);
  const [modalPerms, setModalPerms] = React.useState<boolean>(false);
  const [modalDel, setModalDel] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errorData, setErrorData] = React.useState<boolean>(false);
  const [onSearch, setOnSearch] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataCategory, setDataCategory] = React.useState([]);
  const [category, setCategory] = React.useState('Todos');
  const [dataDeliveries, setDataDeliveries] = useState<any>([]);
  const [orderData, setOrderData] = React.useState([]);
  const [filterSize, setFilterSize] = React.useState('50px');
  const [displayStates, setDisplayStates] = React.useState('none');
  const [monitoring, setMonitoring] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [deliveryFee, setDeliveryFee] = React.useState('');
  const [modalCanceled, setModalCanceled] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [loadingPage, setLoadingPage] = useState<string>('loading');

  useEffect(() => {
    getData();
    getDataDeliveries();
    getDeliveryFee();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    getDataDeliveries();
  }, [monitoring]);

  function getData() {
    if (user?.configuration?.access?.couriers?.read) {
      setLoading(true);
      setLoadingPage('loading');
      api
        .get(`${apiURL}/rest/users.php/`)
        .then((res) => {
          setData(
            res.data.filter(
              (e: any) => e?.configuration?.office === 'couriers',
            ),
          );
        })
        .catch((e) => {
          console.log(e);
          setErrorData(true);
        })
        .finally(() => {
          setLoading(false);
          setLoadingPage('');
        });
    }
  }

  const updateUser = (data: any) => {
    if (user?.configuration?.access?.couriers?.write) {
      setLoading(true);
      setButtonDisabled(true);

      api
        .put(`${apiURL}/rest/users.php/${data.id}`, {
          configuration: data.configuration,
        })
        .then()
        .catch((e) => {
          console.log(e);
          setErrorData(true);
        })
        .finally(() => {
          setModal(false);
          getData();
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const getDataDeliveries = async () => {
    if (user?.configuration?.access?.couriers?.read) {
      api
        .get(`${apiURL}/rest/requests.php/`)
        .then((res) => {
          setDataDeliveries(
            res.data?.filter(
              (e) =>
                e.state === 'DELIVERY_QUEUE' ||
                e.state === 'EN_ROUTE' ||
                e.state === 'DELIVERED' ||
                e.state === 'CANCELED',
            ),
          );
          setDataCategory(
            res.data?.filter(
              (e) =>
                e.state === 'DELIVERY_QUEUE' ||
                e.state === 'EN_ROUTE' ||
                e.state === 'DELIVERED' ||
                e.state === 'CANCELED',
            ),
          );
          setCategory('Todos');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getDeliveryFee = async () => {
    if (user?.configuration?.access?.couriers?.read) {
      await api.get('rest/options.php/delivery').then((res) => {
        setDeliveryFee(res.data.delivery);
      });
    }
  };

  const getPaymentMethods = async () => {
    if (user?.configuration?.access?.couriers?.read) {
      await api.get('rest/payment_gateway.php').then((res) => {
        setPaymentMethods(res.data);
      });
    }
  };

  const checkingStatus = (status) => {
    let resp = '';

    if (status === 'DELIVERY_QUEUE') {
      resp = 'Fila de Entrega';
    } else if (status === 'EN_ROUTE') {
      resp = 'A Caminho';
    } else if (status === 'DELIVERED') {
      resp = 'Entregue';
    } else if (status === 'CANCELED') {
      resp = 'Cancelado';
    }

    return resp;
  };

  const openModal = (el) => {
    // eslint-disable-next-line prefer-destructuring
    const id = el.currentTarget.dataset.id;
    setOrderData(dataDeliveries.find((e) => e.id === id));
    setModalDeliveries(true);
  };

  const openFilter = () => {
    setFilterSize((prev) => (prev === '50px' ? '100%' : '50px'));
    setDisplayStates((prev) => (prev === 'none' ? 'flex' : 'none'));
  };

  const filteringByCategory = (filterCategory) => {
    let resp = [];
    if (filterCategory === 'todos') {
      resp = dataDeliveries;
      setCategory('Todos');
    } else if (filterCategory === 'fila de entregas') {
      // eslint-disable-next-line array-callback-return
      resp = dataDeliveries?.filter((e) => e.state === 'DELIVERY_QUEUE');
      setCategory('Fila de Entregas');
    } else if (filterCategory === 'a caminho') {
      // eslint-disable-next-line array-callback-return
      resp = dataDeliveries?.filter((e) => e.state === 'EN_ROUTE');
      setCategory('A Caminho');
    } else if (filterCategory === 'entregue') {
      // eslint-disable-next-line array-callback-return
      resp = dataDeliveries?.filter((e) => e.state === 'DELIVERED');
      setCategory('Entregue');
    } else if (filterCategory === 'cancelados') {
      // eslint-disable-next-line array-callback-return
      resp = dataDeliveries?.filter((e) => e.state === 'CANCELED');
      setCategory('Cancelados');
    }
    return setDataCategory(resp);
  };

  return user?.configuration?.access?.couriers?.read ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <>
          <HeaderPage />
          <Cont>
            <Cont direction="column">
              <Content>
                <BreadcrumbComponent
                  name="Entregadores"
                  icon={
                    <ImgDeliveryman
                      color="currentColor"
                      width="22px"
                      height="22px"
                    />
                  }
                  permission={user?.configuration?.access?.couriers?.read}
                  length={data.length}
                  search={
                    <Input
                      type="text"
                      placeholder="Pesquisar ..."
                      icon={<BsSearch />}
                      id="name"
                      value={onSearch}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setOnSearch(e.currentTarget?.value);
                      }}
                    />
                  }
                />
                <Cont padding="5px" margin="20px 0px 0px 0px">
                  <Cont wrap="wrap">
                    {errorData ? (
                      <FailedLoad />
                    ) : loading ? (
                      <LoadingPage />
                    ) : (
                      data
                        ?.filter((e: any) =>
                          e.configuration?.name
                            .toLowerCase()
                            .includes(onSearch.toLowerCase()),
                        )
                        ?.map((item: any) => (
                          <Card
                            data-id={item.id}
                            key={item.configuration.name}
                            as={Stack}
                            direction="row"
                            spacing="20px"
                            align="center"
                            onClick={(e: any) => {
                              setSelectedUser(
                                data.find(
                                  (el: any) =>
                                    el.id === e.currentTarget.dataset.id,
                                ),
                              );
                              if (
                                user?.configuration?.access?.couriers?.write
                              ) {
                                reset(item);
                                setModal(true);
                                setEditing(true);
                              }
                            }}
                            onMouseEnter={(e: any) => {
                              setSelectedUser(
                                data.find(
                                  (el: any) =>
                                    el.id === e.currentTarget.dataset.id,
                                ),
                              );
                            }}
                          >
                            <Image
                              src={
                                item?.configuration?.picture &&
                                !item?.configuration?.picture.includes(
                                  'user_no_photo.png',
                                )
                                  ? item?.configuration?.picture
                                  : imgLogo
                              }
                              width="48px"
                              height="48px"
                              borderRadius="50%"
                            />
                            <Stack
                              direction="row"
                              justify="space-between"
                              align="center"
                            >
                              <div>
                                <Text weight="600">
                                  {item.configuration.name}
                                </Text>
                              </div>
                              <div>
                                <Tag
                                  color={
                                    item.configuration.enabled ? 'green' : 'red'
                                  }
                                >
                                  {item.configuration.enabled
                                    ? 'Ativo'
                                    : 'Desativado'}
                                </Tag>
                              </div>
                            </Stack>
                          </Card>
                        ))
                    )}
                  </Cont>
                </Cont>
              </Content>
            </Cont>
            <Cont
              direction="column"
              align="center"
              justify="center"
              padding="0px 10px"
              width="400px"
            >
              <Cont direction="column" align="start" justify="center">
                <Phone>
                  <PhoneScreen>
                    <Filter height={filterSize}>
                      <Button onClick={openFilter}>
                        {category}
                        <Image src={filter} width="24px" height="24px" />
                      </Button>
                      <FilterList display={displayStates}>
                        <button
                          type="button"
                          onClick={() => filteringByCategory('todos')}
                        >
                          Todos
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            filteringByCategory('fila de entregas')}
                        >
                          Fila de Entregas
                        </button>
                        <button
                          type="button"
                          onClick={() => filteringByCategory('a caminho')}
                        >
                          A caminho
                        </button>
                        <button
                          type="button"
                          onClick={() => filteringByCategory('entregue')}
                        >
                          Entregue
                        </button>
                        <button
                          type="button"
                          onClick={() => filteringByCategory('cancelados')}
                        >
                          Cancelados
                        </button>
                      </FilterList>
                    </Filter>
                    {dataCategory?.map((e: any) => {
                      return (
                        <CardDelivery
                          key={e.id}
                          onClick={(el) => openModal(el)}
                          data-id={e?.id}
                        >
                          <CardHeader
                            backgroundColor={
                              backgroundColors[
                                Number(
                                  lists?.find((el) => el?.state === e?.state)
                                    ?.id,
                                ) - 1
                              ]
                            }
                            color={
                              colorsSteps[
                                Number(
                                  lists?.find((el) => el?.state === e?.state)
                                    ?.id,
                                ) - 1
                              ]
                            }
                          >
                            <h2>{checkingStatus(e?.state)}</h2>
                            <span>#{e?.id}</span>
                          </CardHeader>
                          <CardBody>
                            <p>{`Nome: ${e?.user?.name}`}</p>
                            <p>
                              {`${`Endereço: ${e?.address?.rua}, ${e?.address?.bairro}`.slice(
                                0,
                                60,
                              )}...`}
                            </p>
                            <div>
                              <Image
                                src="https://i.ibb.co/B4pRZ5y/map.png%22"
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </CardBody>
                          <CardFooter>
                            <span>Vizualizar Entrega</span>
                            <Image
                              src={right || 'null'}
                              width="16px"
                              height="16px"
                            />
                          </CardFooter>
                        </CardDelivery>
                      );
                    })}
                  </PhoneScreen>
                </Phone>
              </Cont>
            </Cont>
          </Cont>
          <ModalDeliveries
            modal={modalDeliveries}
            setModal={setModalDeliveries}
            data={orderData}
            deliveryFee={deliveryFee}
            paymentMethods={paymentMethods}
            setMonitoring={setMonitoring}
            modalCanceled={modalCanceled}
            setModalCanceled={setModalCanceled}
          />
          <ModalDelete
            set={setModalDel}
            state={modalDel}
            update={() => getData()}
            item={`${apiURL}/rest/users.php/${getValues('id')}`}
            disabled={isButtonDisabled}
            setDisabled={setButtonDisabled}
          />
          <Drawer
            behind={modalPerms}
            set={setModal}
            state={modal}
            title="Editar Entregador"
          >
            <Stack spacing="15px" as="form" onSubmit={handleSubmit(updateUser)}>
              {editing && (
                <>
                  <Text variant="label">Locomoção</Text>

                  <Controller
                    control={control}
                    name="configuration.couriers.locomotion"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <Input
                        full
                        as="select"
                        type="text"
                        placeholder="Locomoção"
                        defaultValue={0}
                        value={value}
                        ref={ref}
                        error={error ? 'Campo obrigatorio' : ''}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                      >
                        <option value="0" disabled>
                          Selecione uma Locomoção
                        </option>
                        <option value="walked">Caminhando</option>
                        <option value="bicycle">Bicicleta</option>
                        <option value="motorbike">Moto</option>
                        <option value="car">Carro</option>
                        <option value="truck">Caminhão</option>
                      </Input>
                    )}
                  />

                  <Text variant="label">Identificação</Text>
                  <Controller
                    control={control}
                    name="configuration.couriers.identification"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <Input
                        full
                        placeholder="Digite a identificação do meio de locomoção!"
                        value={value}
                        ref={ref}
                        error={error ? 'Campo obrigatorio' : ''}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                          // eslint-disable-next-line react/jsx-closing-bracket-location
                        }}
                      />
                      // eslint-disable-next-line react/jsx-closing-bracket-location
                    )}
                  />
                </>
              )}

              <Stack
                direction="row"
                align="center"
                spacing="20px"
                padding="15px 0"
                style={{
                  position: 'sticky',
                  bottom: '-25px',
                  background: themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
                }}
              >
                <Button
                  variant="ghost"
                  type="button"
                  full
                  onClick={() => {
                    setModal(false);
                    // eslint-disable-next-line react/jsx-curly-spacing
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  full
                  loading={loading ? 1 : 0}
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Drawer>
        </>
      )}
    </>
  ) : (
    <>
      <HeaderPage />
      <InsufficientPermission />
    </>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import { Controller, set, useForm } from 'react-hook-form';
import { HiChevronDown } from 'react-icons/hi';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import IcRoles from '../../assets/icons/roles';
import IcSett from '../../assets/icons/setting';
import HeaderPage from '../../components/Header';
import { useAuth } from '../../contexts/AuthContext';
import {
  Button,
  CheckBox,
  Image,
  Input,
  Modal,
  Stack,
  Text,
  ToggleButton,
  Drawer,
} from '../../lib';

import Upload from '../../lib/Uploader/style';
import api, { themeSystem } from '../../services/api';
import {
  AvatarProfile,
  Content,
  Label,
  ListItems,
  SidebarSubMenu,
  SubContent,
} from '../../styles/style';
import { ContPayment, CardPayment } from './style';
import InsufficientPermission from '../../components/InsufficientPermission';
import LoadingPage from '../../components/LoadingPage';
import FailedLoad from '../../components/FailedLoad';

export default function Settings() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentRouter = searchParams.get('tab') || 0;
  const { user } = useAuth();
  const [loadingPage, setLoadingPage] = useState<string>('loading');

  let tabNav;
  let menuTabs;

  setTimeout(() => {
    setLoadingPage('');
  }, 1000);

  if (
    user?.configuration?.access?.dashboard?.read ||
    user?.configuration?.access?.dashboard?.write ||
    user?.configuration?.access?.requests?.read ||
    user?.configuration?.access?.requests?.write ||
    user?.configuration?.access?.clients?.read ||
    user?.configuration?.access?.clients?.write ||
    user?.configuration?.access?.products.read ||
    user?.configuration?.access?.products.write ||
    user?.configuration?.access?.users?.read ||
    user?.configuration?.access?.users?.write ||
    user?.configuration?.access?.couriers?.read ||
    user?.configuration?.access?.couriers?.write ||
    user?.configuration?.access?.settings?.read ||
    user?.configuration?.access?.settings?.write
  ) {
    switch (+currentRouter) {
      case 0:
        tabNav = <DataMyProfile />;
        break;
      case 1:
        tabNav = <DataTab />;
        break;
      case 2:
        tabNav = <DataPayment />;
        break;
      default:
        tabNav = <DataTab />;
    }

    menuTabs = ['Meu Perfil', 'Dados do Sistema', 'Formas de Pagamentos'];
  } else {
    tabNav = <DataMyProfile />;

    menuTabs = ['Meu Perfil'];
  }

  return (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <Stack>
          {user?.configuration?.office === 'couriers' ? (
            <HeaderPage title="Entregas" link="/entregas" />
          ) : (
            <HeaderPage />
          )}

          <Stack
            direction="row"
            className="responsive-flex-768"
            style={{ height: '100%' }}
          >
            <SidebarSubMenu spacing="8px" as={Stack} data-aos="fade-right">
              <Label style={{ margin: 0 }}>Configurações</Label>

              <ListItems as={Stack} spacing="8px">
                {menuTabs.map((item: string, index: number) => (
                  <Button
                    key={item}
                    variant={+currentRouter === index ? 'actived_menu' : 'menu'}
                    distance={15}
                    full
                    align="flex-start"
                    onClick={() => {
                      navigate({
                        pathname: '/configuracoes',
                        search: createSearchParams({
                          tab: String(index),
                        }).toString(),
                      });
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </ListItems>
            </SidebarSubMenu>
            <SubContent as={Content}>{tabNav}</SubContent>
          </Stack>
        </Stack>
      )}
    </>
  );
}

const DataTab = () => {
  const { control, handleSubmit } = useForm<any>();
  const [modalSave, setModalSave] = useState<boolean>();
  const [configPix, setConfigPix] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [thumb, setThumb] = useState<string>('');
  const [imgLogo, setImgLogo] = useState<string>('');
  const [imgBackground, setImgBackground] = useState<string>('');
  const { user } = useAuth();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    if (user?.configuration?.access?.settings?.read) {
      await api
        .get(
          'rest/options.php/name,logo,whatsapp,instagram,facebook,mapa,endereco,background,timezone,delivery,theme,timeExpected,,openTime,closeTime,payment,pix,service,headerTitle',
        )
        .then((res) => {
          setData(res.data);
          setImgLogo(res.data.logo);
          setImgBackground(res.data.background);
          localStorage.setItem('colorSystem', res.data.theme);
        });
    }
  }

  async function saveData(config: any) {
    if (user?.configuration?.access?.settings?.write) {
      setLoading(true);
      await api
        .post('rest/options.php', {
          name: config.name,
          whatsapp: config.whatsapp,
          instagram: config.instagram,
          facebook: config.facebook,
          mapa: config.mapa,
          endereco: config.endereco,
          timezone: config.timezone,
          delivery: config.delivery,
          timeExpected: config.timeExpected,
          openTime: config.openTime,
          closeTime: config.closeTime,
          theme: config.theme,
          payment: config.payment,
          service: config.service,
          pix: config.pix,
          logo: imgLogo,
          background: imgBackground,
          headerTitle: config.headerTitle,
        })
        .then(() => {
          setModalSave(true);
          setLoading(false);
          config.theme && localStorage.setItem('colorSystem', config.theme);
        });
    }
  }

  async function submitFormData(img: any, type) {
    if (user?.configuration?.access?.settings?.write) {
      setLoading(true);

      const formData = new FormData();
      formData.append('generic', img);

      await api({
        method: 'post',
        url: `rest/upload.php/generic`,
        data: formData,
      }).then((res) => {
        type === 'logo'
          ? setImgLogo(res.data.generic)
          : setImgBackground(res.data.generic);
        setLoading(false);
      });
    }
  }

  const colors = [
    {
      name: 'Azul',
      id: 'blue',
      color: 'blue',
    },
    {
      name: 'Vermelho',
      id: 'red',
      color: 'red',
    },
    {
      name: 'Verde',
      id: 'green',
      color: 'green',
    },
  ];

  return user?.configuration?.access?.settings?.read ? (
    <>
      {data && (
        <>
          <Stack
            align="flex-start"
            spacing="10px"
            padding="0 0 20px"
            data-aos="fade-down"
          >
            <Stack spacing="12px" direction="row" align="center" as={Text}>
              <IcSett />
              <Text variant="title">Dados do Sistema</Text>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing="40px"
            data-aos="fade-up"
            className="responsive-flex-768"
          >
            <Stack spacing="15px">
              <Text variant="label">Nome</Text>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Ex.: Weeke Pizzaria"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.name}
                  />
                )}
              />

              <Text variant="label">Logo</Text>

              <Upload htmlFor="uploadLogo">
                <input
                  type="file"
                  id="uploadLogo"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e: any) => {
                    submitFormData(e.currentTarget.files[0], 'logo');
                  }}
                />

                <Stack spacing="20px" align="center">
                  <Stack spacing="5px" align="center">
                    <Image
                      src={imgLogo || thumb}
                      width="102px"
                      height="102px"
                      borderRadius="50%"
                    />
                  </Stack>
                  <Text>100 x 100</Text>
                </Stack>
              </Upload>

              <Text variant="label">Background</Text>

              <Upload htmlFor="uploadBackground">
                <input
                  type="file"
                  id="uploadBackground"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e: any) => {
                    submitFormData(e.currentTarget.files[0], 'background');
                  }}
                />

                <Stack spacing="20px" align="center">
                  <Stack spacing="5px" align="center">
                    <Image
                      src={imgBackground || thumb}
                      width="100%"
                      height="140px"
                      borderRadius="8px"
                    />
                  </Stack>
                  <Text>900 x 140</Text>
                </Stack>
              </Upload>

              <Text variant="label">Fuso Horário</Text>
              <Controller
                control={control}
                name="timezone"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    as="select"
                    placeholder="Tipo do produto"
                    full
                    defaultValue={data.timezone ?? 'cba'}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                    value={value}
                    ref={ref}
                  >
                    <option id="cba" value="cba">
                      -4:00 - Cuiabá - MT
                    </option>
                    <option id="sp" value="sp">
                      -3:00 - Sao Paulo - SP
                    </option>
                  </Input>
                )}
              />

              <Text variant="label">Whatsapp</Text>
              <Controller
                control={control}
                name="whatsapp"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Whatsapp"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.whatsapp}
                  />
                )}
              />
              <Text variant="label">Instagram</Text>
              <Controller
                control={control}
                name="instagram"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Instagram"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.instagram}
                  />
                )}
              />
              <Text variant="label">Facebook</Text>
              <Controller
                control={control}
                name="facebook"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Facebook"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.facebook}
                  />
                )}
              />
              <Text variant="label">Link para o Mapa</Text>
              <Controller
                control={control}
                name="mapa"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Mapa"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.mapa}
                  />
                )}
              />
              <Text variant="label">Endereço</Text>
              <Controller
                control={control}
                name="endereco"
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    placeholder="Endereço"
                    full
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    defaultValue={data.endereco}
                  />
                )}
              />
              <Button
                variant="primary"
                onClick={handleSubmit(saveData)}
                loading={loading}
              >
                Salvar
              </Button>
            </Stack>
            <Stack spacing="15px">
              <Stack direction="row" spacing="15px">
                <Stack spacing="15px">
                  <Text variant="label">Taxa de entrega</Text>
                  <p
                    style={{
                      position: 'absolute',
                      top: 56,
                      left: 6,
                      zIndex: 10,
                      fontSize: 11,
                      color: '#bbb',
                    }}
                  >
                    R$
                  </p>
                  <Controller
                    control={control}
                    name="delivery"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder="00,00"
                        full
                        onChange={onChange}
                        value={value?.replace('.', ',')}
                        ref={ref}
                        defaultValue={data.delivery}
                      />
                    )}
                  />
                </Stack>

                <Stack spacing="15px">
                  <Text variant="label">Estimativa de entrega</Text>
                  <Controller
                    control={control}
                    name="timeExpected"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder="Ex: 30min"
                        full
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        defaultValue={data.timeExpected}
                      />
                    )}
                  />
                </Stack>
              </Stack>

              <Stack direction="row" spacing="15px" align="end">
                <Stack spacing="15px">
                  <Text variant="label">Horário de Funcionamento</Text>
                  <Controller
                    control={control}
                    name="openTime"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder="07am"
                        type="time"
                        full
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        defaultValue={data.openTime}
                      />
                    )}
                  />
                </Stack>

                <Stack spacing="15px">
                  <Controller
                    control={control}
                    name="closeTime"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder="07am"
                        type="time"
                        full
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        defaultValue={data.closeTime}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing="15px" align="end">
                <Stack spacing="15px">
                  <Text variant="label">Cor do sistema</Text>
                  <Stack direction="row" spacing="15px" wrap="true">
                    <Controller
                      control={control}
                      name="theme"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Input
                            full
                            as="select"
                            onChange={(e: any) => {
                              onChange(e.target.value);
                            }}
                            value={value}
                            defaultValue={data.theme}
                          >
                            {colors.map((item: any) => (
                              <option
                                id={item.color}
                                value={item.color}
                                key={item.color}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </>
                      )}
                    />
                  </Stack>
                </Stack>
                <Stack spacing="15px">
                  <Text variant="label">Tema do sistema</Text>
                  <Input
                    as="select"
                    full
                    defaultValue={themeSystem}
                    onChange={(e: any) => {
                      localStorage.setItem('themeSystem', e.target.value);
                      window.location.reload();
                    }}
                  >
                    <option id="light" value="light">
                      Modo Claro
                    </option>
                    <option id="dark" value="dark">
                      Modo Escuro
                    </option>
                  </Input>
                </Stack>
              </Stack>
              <Stack spacing="15px">
                <Text variant="label">Formas de Atendimento</Text>
                <Stack
                  direction="row"
                  align="center"
                  justify="between"
                  spacing="20px"
                >
                  <Controller
                    control={control}
                    name="service.delivery"
                    defaultValue={data?.service?.delivery}
                    render={({ field: { onChange, value, ref } }) => (
                      <ToggleButton
                        onChange={onChange}
                        checked={value}
                        ref={ref}
                      />
                    )}
                  />
                  <Text>Entrega</Text>
                </Stack>
                <Stack
                  direction="row"
                  align="center"
                  justify="between"
                  spacing="20px"
                >
                  <Controller
                    control={control}
                    name="service.withdrawal"
                    defaultValue={data?.service?.withdrawal}
                    render={({ field: { onChange, value, ref } }) => (
                      <ToggleButton
                        onChange={onChange}
                        checked={value}
                        ref={ref}
                      />
                    )}
                  />
                  <Text>Retirada</Text>
                </Stack>
                <Stack
                  direction="row"
                  align="center"
                  justify="between"
                  spacing="20px"
                >
                  <Controller
                    control={control}
                    name="service.table"
                    defaultValue={data.service?.table}
                    render={({ field: { onChange, value, ref } }) => (
                      <ToggleButton
                        onChange={onChange}
                        checked={value}
                        ref={ref}
                      />
                    )}
                  />
                  <Text>
                    <Stack direction="row" align="center">
                      Mesa
                    </Stack>
                  </Text>
                </Stack>
              </Stack>
              <Stack spacing="15px">
                <Text variant="label">Título dinâmico ao cabeçalho</Text>
                <Stack
                  direction="row"
                  align="center"
                  justify="between"
                  spacing="20px"
                >
                  <Controller
                    control={control}
                    name="headerTitle"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder="Ex.: Sabor autêntico, momentos inesquecíveis!"
                        full
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        defaultValue={data?.headerTitle}
                        maxLength={50}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Drawer set={setConfigPix} state={configPix} title="Configurar Pix">
            <Stack spacing="10px">
              <>
                <Text variant="label">Tipo da Chave do Pix</Text>
                <Controller
                  control={control}
                  name="pix.type"
                  rules={{ required: true }}
                  defaultValue={data.pix?.type}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      full
                      placeholder="Tipo da Chave do Pix"
                      type="text"
                      name="type"
                      id="type"
                      value={value}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </>
              <>
                <Text variant="label">Chave do Pix</Text>
                <Controller
                  control={control}
                  name="pix.chave"
                  rules={{ required: true }}
                  defaultValue={data.pix?.chave}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      full
                      placeholder="Chave do Pix"
                      type="text"
                      name="chave"
                      id="chave"
                      value={value}
                      ref={ref}
                      error={error ? 'Campo obrigatorio' : ''}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </>
              <Stack
                direction="row"
                align="center"
                spacing="20px"
                padding="15px 0"
                style={{
                  position: 'sticky',
                  bottom: '-25px',
                  background: themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
                }}
              >
                <Button
                  variant="ghost"
                  full
                  type="button"
                  onClick={() => {
                    setConfigPix(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  full
                  loading={loading ? 1 : 0}
                  type="button"
                  onClick={handleSubmit(saveData)}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Drawer>
        </>
      )}

      <Modal set={setModalSave} state={modalSave} title="Salvo">
        <Text gutter="20px">Alterações feitas!</Text>
        <Stack direction="row" align="center" spacing="20px">
          <Button
            variant="ghost"
            full
            onClick={() => {
              setModalSave(false);
              window.location.reload();
            }}
          >
            Tudo certo!
          </Button>
        </Stack>
      </Modal>
    </>
  ) : (
    <>
      <InsufficientPermission />
    </>
  );
};

const DataMyProfile = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalSave, setModalSave] = useState<boolean>(false);
  const [imgProfile, setImgProfile] = useState<string>(
    user?.configuration?.picture,
  );
  const [upload, setUpload] = useState<string>('');
  const [thumb, setThumb] = useState<string>('');
  const { control, handleSubmit } = useForm<any>();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  function saveData(config: any) {
    if (
      user?.configuration?.access?.settings?.exec ||
      user?.configuration?.access?.settings?.write
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append('avatar', upload);
      setButtonDisabled(true);

      api
        .put(`rest/users.php/${user?.id}`, {
          email: config?.email,
          configuration: {
            name: config?.configuration?.name,
            phone: config?.configuration?.phone,
          },
        })
        .then((res) => {
          api({
            method: 'post',
            url: `rest/upload.php/user/${user?.id}`,
            data: formData,
          }).then(() => {
            setLoading(false);
            setModalSave(true);
            setButtonDisabled(false);
          });
        });
    }
  }

  async function submitFormData(img: any) {
    if (
      user?.configuration?.access?.settings?.exec ||
      user?.configuration?.access?.settings?.write
    ) {
      setLoading(true);

      const formData = new FormData();
      formData.append('generic', img);

      await api({
        method: 'post',
        url: `rest/upload.php/generic`,
        data: formData,
      }).then((res) => {
        setImgProfile(res.data.generic);
        setLoading(false);
      });
    }
  }

  return user?.configuration?.access?.settings?.exec ||
    user?.configuration?.access?.settings?.read ? (
    <>
      <Stack
        align="flex-start"
        spacing="10px"
        padding="0 0 20px"
        data-aos="fade-down"
      >
        <Stack as={Text} spacing="12px" direction="row" align="center">
          <IcSett />
          <Text variant="title">Meu perfil</Text>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing="60px"
        padding="0 0 30px 0"
        data-aos="fade-up"
        className="responsive-flex-768"
      >
        <Stack spacing="15px">
          <Text variant="label">Foto de perfil</Text>
          <Upload htmlFor="upload">
            <input
              type="file"
              id="upload"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e: any) => {
                setThumb(e.currentTarget.files[0]);
                setUpload(e.currentTarget.files[0]);
                submitFormData(e.currentTarget.files[0]);
              }}
            />

            <Stack spacing="20px" align="center">
              <Stack spacing="5px" align="center">
                <AvatarProfile>
                  <Image
                    src={imgProfile || thumb}
                    width="102px"
                    height="102px"
                    borderRadius="50%"
                  />
                </AvatarProfile>
                <Text style={{ textAlign: 'center' }}>
                  Escolha sua melhor foto
                </Text>
              </Stack>
            </Stack>
          </Upload>
        </Stack>
        <Stack spacing="15px">
          <Text variant="label">Nome</Text>
          <Controller
            control={control}
            name="configuration.name"
            render={({ field: { onChange, value, ref } }) => (
              <Input
                placeholder="Nome do usuário"
                full
                onChange={onChange}
                value={value}
                ref={ref}
                defaultValue={user?.configuration?.name}
                name="name"
                id="name"
              />
            )}
          />
          <Text variant="label">E-mail</Text>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value, ref } }) => (
              <Input
                onChange={onChange}
                value={value}
                ref={ref}
                placeholder="E-mail de acesso"
                full
                defaultValue={user?.email}
                name="email"
                id="email"
              />
            )}
          />
          <Text variant="label">Telefone</Text>
          <Controller
            control={control}
            name="configuration.phone"
            render={({ field: { onChange, value, ref } }) => (
              <Input
                onChange={onChange}
                value={value}
                ref={ref}
                full
                placeholder="Telefone"
                defaultValue={user?.configuration?.phone}
                name="phone"
                id="phone"
              />
            )}
          />
          <Button
            variant="primary"
            loading={loading}
            onClick={handleSubmit(saveData)}
            disabled={isButtonDisabled}
          >
            Salvar
          </Button>
        </Stack>
      </Stack>

      <Modal set={setModalSave} state={modalSave} title="Salvo">
        <Text gutter="20px">Alterações feitas!</Text>
        <Stack direction="row" align="center" spacing="20px">
          <Button
            variant="ghost"
            full
            onClick={() => {
              setModalSave(false);
            }}
          >
            Tudo certo!
          </Button>
        </Stack>
      </Modal>
    </>
  ) : (
    <>
      <InsufficientPermission />
    </>
  );
};

const DataPayment = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control: controlSave,
    handleSubmit: handleSubmitSave,
    reset: resetSave,
  } = useForm<any>();
  const {
    control: controlEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
  } = useForm<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [modalData, setModalData] = useState<any>({});
  const { user } = useAuth();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  async function getData() {
    if (user?.configuration?.access?.settings?.read) {
      await api.get('rest/payment_gateway.php').then((res) => {
        setData(res.data);
      });
    }
  }

  const checkModalDate = (e: any) => {
    resetEdit();
    setModalData(data[Number(e.currentTarget.dataset.id)]);
  };

  async function deleteData() {
    if (user?.configuration?.access?.settings?.write) {
      setLoading(true);
      setButtonDisabled(true);

      const id = Number(modalData.id);

      const token = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            'token_weeke_delivery',
          )}`,
        },
      };

      await api.delete(`rest/payment_gateway.php/${id}`, token).then(() => {
        getData();
        setLoading(false);
        setButtonDisabled(false);
      });
    }
  }

  async function saveData(config: any) {
    if (user?.configuration?.access?.settings?.write) {
      if (config?.payment) {
        setLoading(true);
        setButtonDisabled(true);

        const token = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              'token_weeke_delivery',
            )}`,
          },
        };

        await api
          .post(`rest/payment_gateway.php`, config.payment, token)
          .then(() => {
            Array.from(document.querySelectorAll('input')).forEach(
              // eslint-disable-next-line no-return-assign
              (input) => (input.value = ''),
            );
            getData();
            resetSave();
            setLoading(false);
            setButtonDisabled(false);
          });
      }
    }
  }

  async function updateData(config: any) {
    if (user?.configuration?.access?.settings?.write) {
      if (config?.payment) {
        setLoading(true);
        setButtonDisabled(true);

        const id = Number(modalData.id);

        const token = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              'token_weeke_delivery',
            )}`,
          },
        };

        await api
          .put(`rest/payment_gateway.php/${id}`, config.payment, token)
          .then(() => {
            Array.from(document.querySelectorAll('input')).forEach(
              // eslint-disable-next-line no-return-assign
              (input) => (input.value = ''),
            );
            getData();
            setLoading(false);
            resetEdit();
            setModal(false);
            setButtonDisabled(false);
          });
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return user?.configuration?.access?.settings?.read ? (
    <>
      <Stack
        align="flex-start"
        spacing="10px"
        padding="0 0 20px"
        data-aos="fade-down"
      >
        <Stack spacing="12px" direction="row" align="center" as={Text}>
          <IcSett />
          <Text variant="title">Formas de Pagamentos</Text>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        spacing="40px"
        data-aos="fade-up"
        className="responsive-flex-768"
      >
        <Stack spacing="20px">
          <Controller
            control={controlSave}
            name="payment.type"
            defaultValue="manual"
            render={({ field: { onChange, value, ref } }) => (
              <>
                <Text variant="label">Tipo</Text>
                <Input
                  as="select"
                  placeholder="Tipo"
                  full
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  ref={ref}
                >
                  <option id="manual" value="manual">
                    Manual
                  </option>
                  <option id="api" value="api">
                    Api
                  </option>
                </Input>
              </>
            )}
          />
          <Controller
            control={controlSave}
            name="payment.name"
            rules={{ required: true }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <>
                <Text variant="label">Nome do Pagamento</Text>
                <Input
                  onChange={onChange}
                  ref={ref}
                  placeholder="Por favor, informe o nome da forma de pagamento desejada."
                  full
                  name="name"
                  id="name"
                  error={error ? 'Campo obrigatorio' : ''}
                />
              </>
            )}
          />
          <Controller
            control={controlSave}
            name="payment.value"
            rules={{ required: true }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <>
                <Text variant="label">Informações de Pagamento</Text>
                <Input
                  onChange={onChange}
                  ref={ref}
                  placeholder="Por favor, informe os detalhes relevantes sobre a forma de pagamento, como a chave do PIX ou se aceita somente cartões de débito ou crédito."
                  full
                  name="value"
                  id="value"
                  error={error ? 'Campo obrigatorio' : ''}
                />
              </>
            )}
          />
        </Stack>
        <Button
          variant="primary"
          loading={loading}
          onClick={handleSubmitSave(saveData)}
          disabled={isButtonDisabled}
        >
          Salvar
        </Button>
        <Stack spacing="15px">
          {data?.map((el, index) => {
            return (
              <ContPayment
                key={index}
                onClick={checkModalDate}
                onMouseEnter={checkModalDate}
                data-id={index}
              >
                <CardPayment>
                  <p>
                    <strong>Tipo: </strong>
                    {el.type}
                  </p>
                  <p>
                    <strong>Nome do Pagamento: </strong>
                    {el.name}
                  </p>
                </CardPayment>
                <Button
                  variant="primary"
                  onClick={() => setModal(true)}
                  data-id="5"
                >
                  Editar
                </Button>
                <Button
                  disabled={isButtonDisabled}
                  variant="primary"
                  onClick={() => deleteData()}
                >
                  Deletar
                </Button>
              </ContPayment>
            );
          })}
        </Stack>
      </Stack>

      <Modal set={setModal} state={modal} title="Editar Pagamento">
        <Stack direction="column" spacing="12px">
          <Stack spacing="25px">
            <Controller
              defaultValue={modalData.type}
              control={controlEdit}
              name="payment.type"
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <>
                  <Text variant="label">Tipo</Text>
                  <Input
                    as="select"
                    placeholder="Tipo"
                    full
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                    ref={ref}
                  >
                    <option id={modalData?.type} value={modalData?.type}>
                      {modalData?.type.charAt(0).toUpperCase() +
                        modalData?.type.slice(1)}
                    </option>
                    <option
                      id={modalData?.type === 'manual' ? 'api' : 'manual'}
                      value={modalData?.type === 'manual' ? 'api' : 'manual'}
                    >
                      {modalData?.type === 'manual' ? 'Api' : 'Manual'}
                    </option>
                  </Input>
                </>
              )}
            />
            <Controller
              control={controlEdit}
              name="payment.name"
              rules={{ required: true }}
              defaultValue={modalData.name}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <>
                  <Text variant="label">Nome do Pagamento</Text>
                  <Input
                    ref={ref}
                    value={value}
                    placeholder="Por favor, informe o nome da forma de pagamento desejada."
                    full
                    name="name"
                    id="name"
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                </>
              )}
            />
            <Controller
              defaultValue={modalData.value}
              control={controlEdit}
              name="payment.value"
              rules={{ required: true }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <>
                  <Text variant="label">Informações de Pagamento</Text>
                  <Input
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                    ref={ref}
                    placeholder="Por favor, informe os detalhes relevantes sobre a forma de pagamento, como a chave do PIX ou se aceita somente cartões de débito ou crédito."
                    full
                    name="value"
                    id="value"
                    error={error ? 'Campo obrigatorio' : ''}
                    value={value}
                  />
                </>
              )}
            />
            <Stack direction="row" spacing="20px">
              <Button
                variant="primary"
                loading={loading}
                onClick={handleSubmitEdit(updateData)}
                disabled={isButtonDisabled}
              >
                Salvar
              </Button>
              <Button variant="primary" onClick={() => setModal(false)}>
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  ) : (
    <>
      <InsufficientPermission />
    </>
  );
};

/*
  const RolesNPermissions = () => {
    return (
      <>
        <Stack
          align="flex-start"
          spacing="10px"
          padding="0 0 20px"
          data-aos="fade-down"
        >
          <Stack spacing="12px" direction="row" align="center" as={Text}>
            <IcRoles />
            <Text variant="title">Cargos e Permissões</Text>
          </Stack>
        </Stack>
        <Stack data-aos="fade-up">
          <Stack
            spacing="15px"
            direction="row"
            padding="0 0 30px"
            style={{ borderBottom: '1px dashed #E4E8EB' }}
          >
            <Stack size="25%">
              <Text weight="600">Settings</Text>
            </Stack>
            <Stack size="25%" align="center">
              <Text weight="600">Financeiro</Text>
            </Stack>
            <Stack size="25%" align="center">
              <Text weight="600">Gerente</Text>
            </Stack>
            <Stack size="25%" align="center">
              <Text weight="600">Usuário</Text>
            </Stack>
          </Stack>
          <Stack
            spacing="15px"
            direction="row"
            padding="30px 0 0"
            align="center "
          >
            <Stack size="25%">
              <Text weight="600" gutter="10px">
                Dashboard
              </Text>
              <Text>Acompanhar os pedidos e gráficos da página inicial</Text>
            </Stack>
            <Stack size="25%" align="center">
              <Button
                variant="menu"
                dropDown={
                  <Stack spacing="15px" padding="10px">
                    <CheckBox id="read">Somente leitura</CheckBox>
                    <CheckBox id="store">Criar</CheckBox>
                    <CheckBox id="edit">Editar</CheckBox>
                  </Stack>
                }
              >
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
            <Stack size="25%" align="center">
              <Button variant="menu">
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
            <Stack size="25%" align="center">
              <Button variant="menu">
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
          </Stack>
          <Stack
            spacing="15px"
            direction="row"
            padding="30px 0 0"
            align="center "
          >
            <Stack size="25%">
              <Text weight="600" gutter="10px">
                Usuários
              </Text>
              <Text>Administrar usuários de acesso ao sistema</Text>
            </Stack>
            <Stack size="25%" align="center">
              <Button variant="menu">
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
            <Stack size="25%" align="center">
              <Button variant="menu">
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
            <Stack size="25%" align="center">
              <Button variant="menu">
                Selecionar opções <HiChevronDown />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  };

*/

/* eslint-disable array-callback-return */
/* eslint-disable no-useless-return */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'reactjs-lottie';

// Componentes
import { BiChevronRight, BiSearch } from 'react-icons/bi';
import axios from 'axios';

import moment from 'moment';
import {
  Container,
  Text,
  Tag,
  Logo,
  Button,
  Tabs,
  Input,
  Stack,
  CustomerStack,
  InputIcon,
  ResumeBag,
  Box,
} from './lib/style';
import CardItem from './components/CardItem';
import ModalProduct from './components/Modals/ModalProduct';
import ModalBag from './components/Modals/ModalBag';
import ModalCompany from './components/Modals/ModalCompany';
import Card from './components/dailyHighlights/card';

// Icones
import IconEmpty from './lotties/empty.json';
import IconBag from './lotties/bag.json';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

import api, { apiURL } from '../../services/api';
import { AuthProvider, useAuth } from '../../contexts/AuthContext';
import ModalLogin from './components/Modals/ModalLogin';
import { SlideHighlights } from './components/dailyHighlights/style';
import CustomerHeader from '../../components/CustomerHeader';

function App() {
  const [selectedTab, setSeletedTab] = React.useState({
    id: '*',
    name: 'Todos',
    priority: '1',
  });
  const [company, setCompany] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState('');
  const [product, setProduct] = React.useState(false);
  const [bag, setBag] = React.useState(false);
  const [modalCompany, setModalCompany] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [orderCategories, setOrderCategories] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [sumTotal, setTotal] = React.useState(0);
  const [bagStorage, setBagStorage] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);
  const [featuredProductsList, setFeaturedProductsList] = React.useState([]);

  const searchHandle = (event) => {
    const value = event.target.value.toLowerCase().trim();
    let result = [];
    result = data?.filter((item) => {
      return item.name.toLowerCase().search(value) !== -1;
    });
    setFilteredData(result);
  };

  const sumTotalHandle = () => {
    const sum = bagStorage.reduce(function (sum, tax) {
      return sum + tax.price;
    }, 0);
    setTotal(sum);
  };

  const getCompany = async () => {
    await axios
      .get(
        `${apiURL}/rest/options.php/name,logo,whatsapp,instagram,facebook,mapa,endereco,background,delivery,theme,timeExpected,openTime,closeTime,payment,pix,service`,
      )
      .then((res) => setCompany(res.data));
  };

  const getProducts = async () => {
    await axios.get(`${apiURL}/rest/products.php`).then((res) => {
      setData(res.data);
      updateTheLocalStorageBag(res.data);
      setFilteredData(res.data);
    });
  };

  const getDataFeatured = async () => {
    api
      .get(`${apiURL}/rest/options.php/featured`)
      .then((res) => {
        const resp = res.data.featured;
        setFeaturedProductsList(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCategories = async () => {
    await axios.get(`${apiURL}/rest/products_categories.php`).then((res) => {
      setCategories(res.data ? res.data : []);
    });
    await axios
      .get(`${apiURL}/rest/options.php/categoryPriority`)
      .then((res) => {
        setOrderCategories(res.data.categoryPriority);
      });
  };

  useEffect(() => {
    getProducts();
    getCompany();
    getCategories();
    getDataFeatured();
  }, []);

  useEffect(() => {
    updateTheLocalStorageBag(data);
  }, [product, bag]);

  useEffect(() => {
    sumTotalHandle();
  }, [bagStorage]);

  const themeColor = { color: company.theme ?? 'blue' };

  function sortedCategories() {
    const newOrder = [];
    if (orderCategories) {
      orderCategories?.forEach((e) => {
        const item = categories?.find((el) => Number(el.id) === Number(e));
        newOrder.push(item);
      });
    }

    return newOrder;
  }

  function filteringFeaturedProducts() {
    const resp = data?.filter(
      (e) => featuredProductsList.includes(Number(e.id)) && e.status !== '0',
    );

    return resp || [];
  }

  const updateTheLocalStorageBag = (data = []) => {
    // Obtém a lista de produtos do localStorage ou cria uma lista vazia caso não exista.
    let bag = JSON.parse(localStorage.getItem('BAG')) || [];

    if (
      Array.isArray(bag) &&
      bag.length > 0 &&
      Array.isArray(data) &&
      data.length > 0
    ) {
      // Itera sobre os produtos na sacola (bag).
      bag.forEach((e, index) => {
        // Verifica se o produto ainda está disponível na lista de produtos (data).
        const productIndex = data.findIndex(
          (el) => Number(el.id) === Number(e.id.id),
        );

        if (productIndex !== -1) {
          const product = data[productIndex];

          // Atualiza os detalhes do produto na sacola (bag).
          e.id = product;
          e.price = Number(e.qtd) * Number(product.price);

          if (Array.isArray(e.options) && e.options.length > 0) {
            e.options.forEach((el) => {
              const groups = product.options;
              const groupIndex = groups.findIndex((elem) => {
                return Number(elem.id) === Number(el.groupId);
              });

              if (groupIndex !== -1) {
                const optionIndex = groups[groupIndex].list.findIndex(
                  (element) => {
                    return Number(element.id) === Number(el.id);
                  },
                );

                if (optionIndex !== -1) {
                  el.nome = groups[groupIndex].list[optionIndex].name;
                  el.preco = groups[groupIndex].list[optionIndex].price;

                  e.price += Number(el.qtd) * Number(el.preco);
                } else {
                  const newOptions = bag[index].options.filter((element) => {
                    return element.id !== el.id;
                  });

                  bag[index].options = newOptions;
                }
              } else {
                const newOptions = bag[index].options.filter((element) => {
                  return element.id !== el.id;
                });

                bag[index].options = newOptions;
              }
            });
          }
        } else {
          // Caso o produto não seja encontrado na lista de produtos, remove-o da sacola.
          bag = bag.filter((el) => el.id.id !== e.id.id);
        }
      });

      // Atualiza a sacola no localStorage com os produtos atualizados ou vazios.
      localStorage.setItem('BAG', JSON.stringify(bag));
      setBagStorage(bag);
    } else {
      setBagStorage([]);
    }
  };

  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={themeColor}>
          <Box
            style={{
              backgroundImage: `linear-gradient(0deg, ${`rgba(var(--${company.theme}), .10)`} 80%, ${`rgba(var(--${company.theme}), 1)`} 100%), url(${
                company?.background
              })`,
              backgroundPosition: 'center',
              backgroundSize: '100%, cover',
              backgroundAttachment: 'fixed, scroll',
              padding: '70px 0',
            }}
          />
          <Container>
            <CustomerHeader
              company={company}
              setLoginModal={setLoginModal}
              setModalCompany={setModalCompany}
            />
            <SlideHighlights>
              {filteringFeaturedProducts().length > 0 ? (
                <>
                  <h2>Destaques</h2>
                  <ul>
                    {filteringFeaturedProducts()?.map((item) => {
                      return (
                        <Card
                          key={item.id}
                          image={item.image || imgLogo}
                          title={item.name}
                          category={item.category.name}
                          price={item.price}
                          open={() => {
                            setProduct(true);

                            setSelectedProduct(item);
                          }}
                        />
                      );
                    })}
                  </ul>
                </>
              ) : (
                ''
              )}
            </SlideHighlights>
            <Box
              background="#FFFFFF"
              padding="10px 0 15px"
              style={{
                borderBottom: '1px solid #DAE5F2',
                position: 'sticky',
                top: 0,
                zIndex: 100,
              }}
            >
              <Tabs>
                <Stack direction="row" spacing="15px" alignItems="center">
                  <Button
                    key=""
                    variant="ghost"
                    size="md"
                    onClick={() => {
                      setSeletedTab({ id: '*', name: 'Todos', priority: '1' });
                    }}
                  >
                    Todos
                  </Button>

                  {orderCategories
                    ? sortedCategories().map((item) => (
                        <Button
                          key={item?.name}
                          variant={
                            selectedTab === item || selectedTab.id === '*'
                              ? 'solid'
                              : 'ghost'
                          }
                          size="md"
                          onClick={() => {
                            setSeletedTab(item);
                          }}
                        >
                          {item?.name}
                        </Button>
                      ))
                    : ''}
                </Stack>
              </Tabs>
            </Box>
            <Box
              padding="20px"
              background="#F7F8F9"
              style={{ borderBottom: '1px solid #DAE5F2' }}
            >
              <InputIcon align="right">
                <Input
                  placeholder="Pesquisar produto"
                  onChange={(event) => searchHandle(event)}
                />
                <BiSearch
                  size="18"
                  color={`rgba(var(--${company.theme}), 1)`}
                />
              </InputIcon>
            </Box>
            <Box background="#F7F8F9" padding="10px 20px">
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={selectedTab ? selectedTab.nome : 'empty'}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 20 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.15 }}
                >
                  {selectedTab && (
                    <Stack style={{ minHeight: '50vh' }} divider="true">
                      {filteredData?.filter(
                        (el) =>
                          el?.category?.id === selectedTab?.id ||
                          selectedTab?.id === '*',
                      ).length > 0 ? (
                        filteredData
                          ?.filter(
                            (el) =>
                              el?.category?.id === selectedTab?.id ||
                              selectedTab?.id === '*',
                          )
                          ?.filter((el) => el?.status === '1')
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((item) => (
                            <CardItem
                              key={item.id}
                              data={item}
                              open={() => {
                                setProduct(true);

                                setSelectedProduct(item);
                              }}
                            />
                          ))
                      ) : (
                        <Box textAlign="center" padding="0 0 40px 0">
                          <Text
                            variant="title"
                            gutter="15px"
                            style={{ paddingTop: 40 }}
                          >
                            Ops! Nada foi encontrado
                          </Text>
                          <Text>
                            Desculpe, não há resultados para o que isso.
                          </Text>
                          <Lottie
                            width="200px"
                            options={{
                              animationData: IconEmpty,
                            }}
                          />
                        </Box>
                      )}
                    </Stack>
                  )}
                </motion.div>
              </AnimatePresence>
            </Box>
          </Container>
          {moment(new Date(), 'hh:mm:ss').isBetween(
            moment(company.openTime, 'hh:mm:ss'),
            moment(company.closeTime, 'hh:mm:ss'),
          ) && (
            <>
              <ResumeBag onClick={() => setBag((open) => !open)}>
                <Stack
                  direction="row"
                  spacing="15px"
                  padding="0 0 20px 0"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <Text>Subtotal</Text>
                    <Text variant="caption">
                      {Number(sumTotal)?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Text>
                  </div>
                  <Stack
                    direction="row"
                    spacing="20px"
                    alignItems="center"
                    style={{ width: 'fit-content' }}
                  >
                    <Button variant="solid" size="lg">
                      <Lottie
                        options={{
                          animationData: IconBag,
                          autoPlay: true,
                          loop: true,
                        }}
                        width="26px"
                        height="26px"
                      />
                      <p>VER SACOLA</p>
                    </Button>
                  </Stack>
                </Stack>
              </ResumeBag>
              <ModalProduct
                open={product}
                company={company}
                product={selectedProduct}
                close={() => setProduct(false)}
                submit={() => {
                  setBag(true);
                }}
              />
              <ModalCompany
                open={modalCompany}
                company={company}
                product={selectedProduct}
                close={() => setModalCompany(false)}
              />
              <ModalBag
                open={bag}
                close={() => setBag(false)}
                company={company}
              />
            </>
          )}

          <ModalLogin
            open={loginModal}
            company={company}
            close={() => setLoginModal(false)}
          />
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;

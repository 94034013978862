import React from 'react';
import { HiRefresh } from 'react-icons/hi';
import { TiWarningOutline } from 'react-icons/ti';
import { Button, Stack, Text } from '../../lib';
import { color } from '../../services/api';

function FailedLoad(): JSX.Element {
  return (
    <Stack
      style={{ flex: 1, height: '-webkit-fill-available', textAlign: 'center' }}
      align="center"
      justify="center"
      spacing="20px"
      padding="20px"
    >
      <TiWarningOutline size="30px" color={`rgba(var(--${color}3), 1)`} />
      <Text variant="title">Algo ao carregar</Text>
      <div>
        <Text align="center" gutter="5px">
          Não foi possível carregar os dados para essa página, tente novamente!
        </Text>
        <Text align="center">
          Caso o erro persista,
          <a href="/">
            <u>entre em contato com o suporte</u>
          </a>
          .
        </Text>
      </div>
      <Button variant="ghost" onClick={() => window.location.reload()}>
        <HiRefresh /> Recarregar
      </Button>
    </Stack>
  );
}

export default FailedLoad;

/* eslint-disable no-nested-ternary */
import styled, { css, createGlobalStyle, keyframes } from 'styled-components';

import { color } from '../services/api';

export const Board = styled.div`
  width: calc(100% - 40px);
  overflow: auto;
  padding: 20px;
  height: calc(100% - 237px);
  background: ${(props) => props.theme.background};
  border-top: 1px solid ${(props) => props.theme.backgroundSec};
  @media only screen and (max-width: 900px) {
    height: calc(100% - 220px);
  }
`;

export const ColumnBoard = styled.div<any>`
  align-self: stretch;
  padding: 15px;
  width: 270px;
  min-width: 300px;
  background-color: ${(props) => props.theme.backgroundSec};
  border-radius: 10px;
  border-left: 1px solid ${(props) => props.theme.shadow};
  border-right: 1px solid ${(props) => props.theme.shadow};
  border-bottom: 1px solid ${(props) => props.theme.shadow};
  border-top: 3.5px solid ${(props) => props.borderColor};

  :hover {
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const NotifItem = styled.div`
  transition: 300ms all ease;
  border-radius: 10px;
  &:hover {
    background-color: rgba(var(--${color}), 0.1);
  }
`;

export const CentralNotif = styled.div`
  min-width: 370px;
  & > *:last-child {
    display: none;
  }
`;

export const AvatarProfile = styled.div`
  background: #fff;
  padding: 14px;
  border-radius: 50%;
  line-height: 0;
  position: relative;
  margin-bottom: 15px;
  transition: 500ms all ease;
  &:before {
    position: absolute;
    content: '';
    z-index: 9;
    top: 13.5px;
    left: 13.5px;
    padding: 50px;
    border-radius: 50%;
    border: 2px dashed #e3e8eb;
    background: #fafbfb;
    transform: all 500ms ease;
  }
  &:after {
    position: absolute;
    content: '';
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(/img/icon_camera.svg);
    background-position: center;
    background-size: 28px;
    background-repeat: no-repeat;
    transition: all 500ms ease;
  }
  & img {
    z-index: 12;
    position: relative;
    transition: 300ms all ease;
  }
  &:hover {
    & img {
      transform: scale(0.9);
      opacity: 0.15;
    }
    &:before {
      animation: rotate-center 4s ease-in-out infinite both;
    }
    &:after {
      background-size: 32px;
    }
  }
  &:active {
    transform: scale(0.9);
    background: #fafbfb;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarRandom = styled.div<{ className: any }>`
  position: relative;
  line-height: 0;
  cursor: pointer;
  &:before {
    position: absolute;
    content: '';
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: 300ms ease all;
  }
  &.active:before,
  &:hover:before {
    background: rgba(var(--${color}), 0.2);
    box-shadow: 0 10px 15px -5px rgba(var(--${color}), 0.5),
      0 4px 6px -2px rgba(var(--${color}), 0.5),
      0 0 0px 3px rgba(var(--${color}), 1);
  }

  &.active:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 300ms ease all;
    background: rgba(var(--${color}), 0.7);
    background-image: url(/img/icon_check.svg);
    background-position: center;
    background-size: 22px;
    background-repeat: no-repeat;
  }
`;

export const Card = styled.div`
  border: 1px solid ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.backgroundSec};
  border-radius: 7px;
  padding: 17px 18px;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => props.theme.text};
  transition: 300ms all ease;
  width: calc(33.333% - 52px) !important;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    width: calc(50% - 48px) !important;
  }
  @media only screen and (max-width: 767px) {
    width: calc(100% - 38px) !important;
  }
  & img {
    transition: 300ms all ease;
  }
  &:hover {
    transform: translateY(-3px);
    background-color: rgba(var(--${color}), 0.075);
    border: 1px solid rgba(var(--${color}2), 0.1);
  }
  &:active {
    transform: translateY(1px);
    background-color: rgba(var(--${color}), 0.2);
  }
`;

export const Label = styled.p`
  font-weight: 700;
  color: ${(props) => props.theme.text};
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 13px;
  margin-top: 30px;
  margin-left: 1rem;
`;

export const Header = styled.div`
  width: -webkit-fill-available;
  padding: 20px 30px;
  border-bottom: 1px dashed ${(props) => props.theme.shadow || '#e4e8eb'};
  background-color: ${(props) => props.theme.background};
  justify-content: flex-end;

  @media only screen and (max-width: 900px) {
    padding: 15px 10px;
  }
`;

export const NavBar = styled.div<{ collapsed?: boolean }>`
  max-height: calc(100vh - 40px);
  scroll-behavior: smooth;
  padding: 20px 25px;
  background: ${(props) => props.theme.backgroundSec};
  border-right: 1px dashed ${(props) => props.theme.shadow};
  transition: 300ms all ease;
  z-index: 100;

  ${(props) =>
    props.collapsed
      ? css`
          width: 50px;
          padding: 20px 10px;
          overflow-y: inherit;
        `
      : css`
          width: 20vw;
          overflow-y: scroll;
          max-width: 250px;
        `}
  @media only screen and (max-width: 900px) {
    position: fixed;
    top: 0;
    height: -webkit-fill-available;
    ${(props) =>
      !props.collapsed &&
      css`
        width: 200px;
      `}
  }
`;

export const Container = styled.div<{ index: boolean }>`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;

  ${(props) =>
    props.index &&
    css`
      @media only screen and (max-width: 900px) {
        padding-left: 70px;
      }
    `}
`;
export const Content = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  background-color: ${(props) => props.theme.background};
  justify-content: ${(props) => props.justify || 'flex-start'};
  width: -webkit-fill-available;
  min-height: calc(100vh - 119px);
  padding: ${(props) => props.padding || '15px 30px 30px 30px'};
  flex: 1 0 auto;
  @media only screen and (max-width: 900px) {
    padding: 20px 17px;
    overflow-x: hidden;
  }
`;

export const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  height: -webkit-fill-available;
  background-color: ${(props) => props.theme.background};
`;

export const Dot = styled.div<{ size?: string; color?: string }>`
  padding: ${(props) => props.size ?? '10px'};
  line-height: 0;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const listCards = styled.div`
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding-top: 30px;
  }
`;

export const CardDashboard = styled.div`
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.shadow};
  background: ${(props) => props.theme.backgroundSec};
`;
export const CardStep = styled.div`
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.shadow};
  background: ${(props) => props.theme.backgroundSec};
  width: 33.33%;
  @media only screen and (max-width: 768px) {
    flex-basis: calc(50% - 50px);
    flex-grow: 1;
  }
`;

export const ListItems = styled.div`
  overflow-y: hidden;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SubContent = styled.div`
  width: calc(80% - 80px);

  @media only screen and (max-width: 768px) {
    width: -webkit-fill-available;
  }
`;

export const SidebarSubMenu = styled.div`
  width: 20%;
  min-width: 180px;
  align-self: stretch;
  background: ${(props) => props.theme.backgroundTerc};
  border-right: 1px dashed ${(props) => props.theme.shadow || '#e4e8eb'};
  padding: 30px 15px;
  @media only screen and (max-width: 768px) {
    width: -webkit-fill-available;
    padding: 20px 15px;
  }
`;

export const IntroduceLogin = styled.div`
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const GlobalStyle = createGlobalStyle`

  /* ver sass para cores  */
  :root {
    /* Blue */
    --blue: 10, 126, 251;
    --blue2: 0, 104, 216;
    --blue3: 2, 87, 177;
    /* Reds */
    --red: 205, 32, 43;
    --red2: 189, 37, 49;
    --red3: 174, 41, 47;
    /* Greens */
    --green: 87, 204, 153;
    --green2: 45, 106, 79;
    --green3: 33, 84, 61;
  }

  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #FFF;
  }
  span{
    line-height: 0;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  p{
    margin: 0;
    padding: 0;
  }
  a{
    text-decoration: none;
    line-height: 0;
  }
  ul, ol,
  ul li,
  ul ul li {
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style-type: 0;
    list-style: none;
  }
  ::-moz-selection { background: #0A7EFB40; }
  ::selection { background: #0A7EFB40; }

  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;
      border-collapse: inherit;
      border-spacing: 0;
      border-color: inherit;
      text-align: left;
      font-weight: inherit;
      -webkit-border-horizontal-spacing: 0;
      -webkit-border-vertical-spacing: 0;
  }

  button, input, textarea, select{
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    text-align: inherit;
    outline: none;
  }
  input::-moz-focus-inner, button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input:focus:not(:focus-visible), button:focus:not(:focus-visible) {
    outline: none;
  }
  input:focus:not(:-moz-focusring), button:focus:not(:-moz-focusring) {
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Animations Entrance */
  .drawer-body-enter {
    transform: translateX(100%);
  }
  .drawer-body-enter-active {
    transform: translateX(0);
    transition: all ease 500ms;
  }
  .drawer-body-exit {
    transform: translateX(0);
  }
  .drawer-body-exit-active {
    transform: translateX(100%);
    transition: all ease 500ms;
  }

  .drawer-overlay-enter {
    opacity: 0;
  }
  .drawer-overlay-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .drawer-overlay-exit {
    opacity: 1;
  }
  .drawer-overlay-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

  .modal-body-enter {
    transform: translateY(100%);
  }
  .modal-body-enter-active {
    transform: translateY(0%);
    transition: all ease 500ms;
  }
  .modal-body-exit {
    transform: translateY(0%);
  }
  .modal-body-exit-active {
    transform: translateY(100%);
    transition: all ease 500ms;
  }

  /* Loading */
  .loader,
  .loader:after {
    border-radius: 50%;
    width: .5rem;
    height: .5rem;
  }
  .loader {
    margin: 0;
    font-size: 3.65px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff00; 
  }
  ::-webkit-scrollbar-thumb {
    background: #8A909730; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8A909775; 
  }

  /* animation rotate-center */

  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0) scale(1);
              transform: rotate(0) scale(1);
    }
    50%{
      -webkit-transform: rotate(180deg) scale(1.1);
              transform: rotate(180deg) scale(1.1);
    }
    100% {
      -webkit-transform: rotate(360deg) scale(1);
              transform: rotate(360deg) scale(1);
    }
  }

  @keyframes check {
    100% {
        stroke-dashoffset: 4.5px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #FEB70Caa;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px #FEB70C00;
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #FEB70C00;
    }
  }


  @media only screen and (max-width: 768px) {
    .responsive-flex-768{
      flex-direction: column !important;
    }
  }
`;

const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(-15%);
  }
`;

const slideOutAnimation = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const Menu = styled.div<any>`
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 2px 0px 2px 30px;
  gap: 20px;
  cursor: pointer;
  animation: ${(props) =>
    props?.animation === true
      ? css`
          ${slideInAnimation} 1.2s ease forwards
        `
      : props?.animation === 'none'
      ? 'none'
      : css`
          ${slideOutAnimation} 1.2s ease forwards
        `};
`;

import React, { useState } from 'react';
import { FiUploadCloud } from 'react-icons/fi';

import Button from '../Button';
import Image from '../Image';
import Stack from '../Stack';
import Text from '../Text';
import { Upload } from './style';
import api from '../../services/api';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';

interface Props {
  thumb?: any;
  upload?: any;
  user?: any;
  style?: Record<string, unknown>;
  submitForm?: any;
}

const Uploader: React.FC<Props> = ({ ...props }) => {
  const [imgUser, setImgUser] = useState<any>(props.thumb);

  async function submitFormData(img: any) {
    const formData = new FormData();
    formData.append('generic', img);

    await api({
      method: 'post',
      url: `rest/upload.php/generic`,
      data: formData,
    }).then((res) => {
      props.upload(img);
      setImgUser(res.data.generic);
    });
  }

  async function deleteImg() {
    setImgUser(null);
    props.upload('null');
  }

  return (
    <Upload {...props} htmlFor="upload">
      <input
        type="file"
        id="upload"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e: any) => {
          submitFormData(e.currentTarget.files[0]);
        }}
      />

      {console.log(imgUser)}

      {imgUser ? (
        <Stack direction="row" spacing="15px" align="center">
          <Image
            src={
              imgUser &&
              !imgUser.includes('product_no_photo.png') &&
              !imgUser.includes('user_no_photo.png') &&
              imgUser !== null
                ? imgUser
                : imgLogo
            }
            borderRadius="50%"
            width="70px"
            height="70px"
          />
          <div style={{ width: '100%' }}>
            <Stack
              direction="row"
              spacing="15px"
              align="center"
              justify="center"
            >
              <Text variant="subtitle" color="#0257B1">
                Editar
              </Text>
              <Button
                style={{ padding: 2 }}
                variant="link"
                mode="dangerous"
                type="button"
                onClick={() => deleteImg()}
              >
                Remover
              </Button>
            </Stack>
          </div>
        </Stack>
      ) : (
        <Stack spacing="20px" align="center">
          <FiUploadCloud size="60px" color="#E2E8EB" />
          <Stack spacing="5px" align="center">
            <Text variant="subtitle" style={{ textAlign: 'center' }}>
              Adicionar Imagem
            </Text>
            <Text style={{ textAlign: 'center' }}>
              Selecione apenas uma imagem
            </Text>
          </Stack>
        </Stack>
      )}
    </Upload>
  );
};

export default Uploader;

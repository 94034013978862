import React from 'react';

export default function IcSettFilled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="16px"
      height="16px"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <g>
        <path d="M228.267,56c-17.455-37.114-61.692-53.05-98.805-35.595C113.814,27.765,101.226,40.353,93.867,56H32   C14.327,56,0,70.327,0,88l0,0c0,17.673,14.327,32,32,32h61.76c17.455,37.114,61.692,53.05,98.805,35.595   c15.647-7.359,28.235-19.948,35.595-35.595H480c17.673,0,32-14.327,32-32l0,0c0-17.673-14.327-32-32-32H228.267z" />
        <path d="M351.04,181.333c-28.765,0.051-54.931,16.659-67.221,42.667H32c-17.673,0-32,14.327-32,32l0,0c0,17.673,14.327,32,32,32   h251.733c17.455,37.114,61.692,53.05,98.805,35.595c15.647-7.359,28.235-19.948,35.595-35.595H480c17.673,0,32-14.327,32-32l0,0   c0-17.673-14.327-32-32-32h-61.76C405.953,197.999,379.798,181.393,351.04,181.333z" />
        <path d="M160.96,349.333c-28.758,0.059-54.913,16.666-67.2,42.667H32c-17.673,0-32,14.327-32,32l0,0c0,17.673,14.327,32,32,32   h61.76c17.455,37.114,61.692,53.05,98.805,35.595c15.647-7.359,28.235-19.948,35.595-35.595H480c17.673,0,32-14.327,32-32l0,0   c0-17.673-14.327-32-32-32H228.267C215.963,365.965,189.756,349.352,160.96,349.333z" />
      </g>
    </svg>
  );
}

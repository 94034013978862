/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ThemeContext } from 'styled-components';

// Icones
import { BiChevronDown } from 'react-icons/bi';
import {
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import {
  Text,
  Stack,
  FormItem,
  ModalBottom,
  ModalBody,
  ModalHead,
  ModalFooter,
  ModalOverlay,
} from '../../lib/style';

export default function ModalCompany({ open, company, close, submit, edit }) {
  const themeColor = React.useContext(ThemeContext);

  return (
    <>
      <CSSTransition in={open} timeout={300} classNames="fade" unmountOnExit>
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modalbottom"
        unmountOnExit
      >
        <ModalBottom>
          <ModalBody>
            <ModalHead>
              <Stack direction="row" spacing="15px" alignItems="center">
                <BiChevronDown
                  size="35"
                  color={`rgba(var(--${themeColor.color}), 1)`}
                  onClick={close}
                />
                <Text variant="title">{company.name} - Dados</Text>
              </Stack>
            </ModalHead>
            <div style={{ width: '100%' }}>
              <form>
                <FormItem>
                  <Stack spacing="15px" alignItems="flex-start">
                    {company.whatsapp && (
                      <Stack direction="row" spacing="15px" alignItems="center">
                        <FaWhatsapp
                          size="30"
                          color={`rgba(var(--${themeColor.color}), 1)`}
                        />
                        <a
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=55${company.whatsapp}`}
                          rel="noreferrer"
                        >
                          {company.whatsapp}
                        </a>
                      </Stack>
                    )}
                    {company.instagram && (
                      <Stack direction="row" spacing="15px" alignItems="center">
                        <FaInstagram
                          size="30"
                          color={`rgba(var(--${themeColor.color}), 1)`}
                        />
                        <a
                          target="_blank"
                          href={`https://instagram.com/${company.instagram}`}
                          rel="noreferrer"
                        >
                          {company.instagram}
                        </a>
                      </Stack>
                    )}
                    {company.facebook && (
                      <Stack direction="row" spacing="15px" alignItems="center">
                        <FaFacebook
                          size="30"
                          color={`rgba(var(--${themeColor.color}), 1)`}
                        />
                        <a
                          target="_blank"
                          href={`https://facebook.com/${company.facebook}`}
                          rel="noreferrer"
                        >
                          {company.facebook}
                        </a>
                      </Stack>
                    )}

                    {company.mapa && (
                      <Stack direction="row" spacing="15px" alignItems="center">
                        <FaMapMarkedAlt
                          size="30"
                          color={`rgba(var(--${themeColor.color}), 1)`}
                        />
                        <a
                          target="_blank"
                          href={`${company.mapa}`}
                          rel="noreferrer"
                        >
                          Como chegar lá
                        </a>
                      </Stack>
                    )}
                    {company.endereco && (
                      <Stack direction="row" spacing="15px" alignItems="center">
                        <FaMapMarkerAlt
                          size="30"
                          color={`rgba(var(--${themeColor.color}), 1)`}
                        />
                        {company.endereco}
                      </Stack>
                    )}
                  </Stack>
                </FormItem>
              </form>
            </div>
            <ModalFooter />
          </ModalBody>
        </ModalBottom>
      </CSSTransition>
    </>
  );
}

/* eslint-disable func-names */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import add from '../../../assets/icons/add.svg';
import { Button, Drawer, Input, Modal, Stack, Text } from '../../../lib';
import api, { apiURL } from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';
import imgLogo from '../../../assets/icons/logoWeekeDelivery.svg';
import {
  ButtonAdd,
  CardOption,
  ContainerInputAdd,
  ContainerUpload,
  EmptyContainer,
  ImagePreview,
  ImgOption,
  TitleGroup,
  UploadInput,
} from './style';

interface Props {
  state: boolean;
  set: any;
  data: any;
  id: any;
  groups: any;
  options: any;
  setMonitoringOptions: any;
}

const DrawerOptions: React.FC<Props> = function ({ ...props }) {
  const { user } = useAuth();
  const {
    setValue: setValueGroup,
    getValues: getValuesGroup,
    reset: resetGroup,
    handleSubmit: handleSubmitGroup,
    control: controlGroup,
  } = useForm<any>();
  const {
    reset: resetOption,
    handleSubmit: handleSubmitOption,
    control: controlOption,
  } = useForm<any>();
  const {
    reset: resetGroupEdit,
    handleSubmit: handleSubmitGroupEdit,
    control: controlGroupEdit,
  } = useForm<any>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalOptions, setModalOptions] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [modalGroup, setModalGroup] = React.useState<boolean>(false);
  const groups = props?.groups?.filter(
    (e: any) => e.product === String(props.id),
  );

  const [upload, setUpload] = React.useState<string>('');
  const [dataGroup, setDataGroup] = React.useState<any>({});
  const [dataOption, setDataOption] = React.useState<any>({});
  const [isButtonDisabled, setButtonDisabled] = React.useState<boolean>(false);

  const returnOptions = (id: any) => {
    const resp = props?.options?.filter((e) => e.group === id);
    return resp?.length === 0 ? null : resp;
  };

  const addDataOption = (id: any) => {
    resetOption();
    setUpload(props.options.find((e) => e.id === id).image);
    setDataOption(props.options.find((e) => e.id === id));
  };

  const createGroup = async (data) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .post(`rest/products_options_groups.php`, {
          name: data.name,
          limit_min: 1,
          limit_max: 1,
          product: props.id,
        })
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          resetGroup();
          resetGroupEdit();
          setValueGroup('name', '');
          setDataGroup({
            name: data.name,
            limit_min: 1,
            limit_max: 1,
            product: props.id,
            id: res.data,
          });
          setModalGroup(true);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const editGroup = async (data) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .put(`rest/products_options_groups.php/${dataGroup.id}`, {
          name: data.name,
          limit_min: data.min,
          limit_max: data.max,
        })
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          resetGroupEdit();
          setModalGroup(false);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const deleteGroup = async () => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .delete(`rest/products_options_groups.php/${dataGroup.id}`)
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          resetGroupEdit();
          setModalGroup(false);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const createOption = async (data) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .post(`rest/products_options.php`, {
          name: data.name,
          group: dataGroup.id,
        })
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          setUpload('');
          setDataOption({ name: data.name, group: dataGroup.id, id: res.data });
          setEditing(true);
          setButtonDisabled(false);
        })
        .catch((e) => {
          console.log(e);
          setButtonDisabled(false);
        });
    }
  };

  const editOption = async (data) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .put(`rest/products_options.php/${dataOption.id}`, {
          name: data.name,
          limit_min: data.min,
          limit_max: data.max,
          image: upload,
          price: data.price,
        })
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          resetOption();
          setUpload('');
          setModalOptions(false);
          setButtonDisabled(false);
        })
        .catch((e) => {
          console.log(e);
          setButtonDisabled(false);
        });
    }
  };

  const deleteOption = async () => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .delete(`rest/products_options.php/${dataOption.id}`)
        .then((res: any) => {
          props.setMonitoringOptions((prev) => !prev);
          resetOption();
          setUpload('');
          setModalOptions(false);
          setButtonDisabled(false);
        })
        .catch((e) => {
          console.log(e);
          setButtonDisabled(false);
        });
    }
  };

  const addDataGroup = (id) => {
    resetGroupEdit();
    setUpload('');
    setDataGroup(groups.find((e) => e.id === id));
  };

  async function submitFormData(img: any) {
    if (user?.configuration?.access?.products?.write) {
      const formData = new FormData();
      formData.append('generic', img);
      setButtonDisabled(true);

      await api({
        method: 'post',
        url: `rest/upload.php/generic`,
        data: formData,
      })
        .then((res) => {
          setUpload(res.data.generic);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  }

  return user?.configuration?.access?.products?.read ? (
    <>
      <Drawer
        set={props?.set}
        state={props?.state}
        title="Opcionais"
        behind={modalOptions}
      >
        <Stack
          spacing="30px"
          as="form"
          onSubmit={handleSubmitGroup(createGroup)}
        >
          <Text variant="label">Nome do Grupo de Opcionais</Text>
          <Controller
            control={controlGroup}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ContainerInputAdd>
                <Input
                  full
                  placeholder="Ex: Molhos"
                  type="text"
                  defaultValue={getValuesGroup('name')}
                  value={value || ''}
                  error={error ? 'Campo obrigatorio' : ''}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                />
                <ButtonAdd
                  type="submit"
                  title="Adicionar grupo"
                  disabled={isButtonDisabled}
                >
                  <img src={add} />
                </ButtonAdd>
              </ContainerInputAdd>
            )}
          />

          {groups ? (
            groups?.map((item: any) => (
              <Stack
                width="100%"
                align="center"
                justify="center"
                direction="column"
                spacing="20px"
                key={item?.id}
              >
                <TitleGroup
                  onClick={(e) => {
                    addDataGroup(e.currentTarget.dataset.id);
                  }}
                  data-id={item?.id}
                >
                  <Text variant="label">{item?.name}</Text>
                  <Button
                    type="button"
                    style={{ justifyContent: 'space-between' }}
                    onClick={() => {
                      setModalGroup(true);
                    }}
                  >
                    <FiEdit2 title="Editar Grupo" />
                  </Button>
                  <ButtonAdd
                    title="Adicionar Opcional"
                    type="button"
                    onClick={() => {
                      resetOption();
                      setModalOptions(true);
                      setEditing(false);
                      setDataOption({});
                    }}
                    disabled={isButtonDisabled}
                  >
                    <img src={add} />
                  </ButtonAdd>
                </TitleGroup>
                {returnOptions(item?.id) ? (
                  returnOptions(item?.id)?.map((e: any) => (
                    <CardOption
                      key={e?.id}
                      data-id={e.id}
                      onClick={(e: any) =>
                        addDataOption(e?.currentTarget?.dataset?.id)
                      }
                    >
                      <ImgOption
                        src={e?.image && e?.image !== null ? e?.image : imgLogo}
                      />
                      <Text variant="small">
                        {e?.name} –{' '}
                        {Number(e?.price).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Text>
                      <Button
                        style={{ justifyContent: 'space-between' }}
                        onClick={() => {
                          resetOption();
                          setModalOptions(true);
                          setEditing(true);
                        }}
                        type="button"
                      >
                        <FiEdit2 title="Editar Opcional" />
                      </Button>
                    </CardOption>
                  ))
                ) : (
                  <EmptyContainer>
                    <p>Vazio</p>
                  </EmptyContainer>
                )}
              </Stack>
            ))
          ) : (
            <EmptyContainer>
              <p>Lista de opções vazia</p>
            </EmptyContainer>
          )}
        </Stack>
      </Drawer>

      <Drawer
        set={setModalOptions}
        state={modalOptions}
        title={editing ? 'Editar Opção' : 'Adicionar Opção'}
      >
        <Stack
          spacing="30px"
          as="form"
          onSubmit={
            editing
              ? handleSubmitOption(editOption)
              : handleSubmitOption(createOption)
          }
        >
          <Text variant="label">Nome da opção</Text>
          <Controller
            control={controlOption}
            name="name"
            defaultValue={dataOption?.name || ''}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                placeholder="Nome da opção..."
                type="text"
                value={value}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
          {editing ? (
            <>
              <Text variant="label">Imagem</Text>
              <ContainerUpload>
                <ImagePreview
                  src={
                    upload ||
                    (dataOption?.image && dataOption?.image !== null
                      ? dataOption?.image
                      : imgLogo)
                  }
                  alt="Preview"
                />
                <UploadInput
                  type="file"
                  id="upload"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e: any) => {
                    setUpload(e.currentTarget.files[0]);
                    submitFormData(e.currentTarget.files[0]);
                  }}
                />
              </ContainerUpload>

              <Stack direction="row" spacing="16px">
                <Stack spacing="15px" style={{ width: 'calc(50% - 8px)' }}>
                  <Text variant="label">Limite mínimo</Text>
                  <Controller
                    control={controlOption}
                    name="min"
                    defaultValue={dataOption?.limit_min || ''}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        full
                        placeholder="Ex: 1"
                        type="text"
                        value={value}
                        error={error ? 'Campo obrigatorio' : ''}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing="15px" style={{ width: 'calc(50% - 8px)' }}>
                  <Text variant="label">Limite máximo</Text>
                  <Controller
                    control={controlOption}
                    name="max"
                    defaultValue={dataOption?.limit_max || ''}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        full
                        placeholder="Ex: 5"
                        type="text"
                        value={value}
                        error={error ? 'Campo obrigatorio' : ''}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing="16px">
                <Stack spacing="15px" style={{ width: 'calc(50% - 8px)' }}>
                  <Text variant="label">Preço</Text>
                  <Controller
                    control={controlOption}
                    name="price"
                    defaultValue={dataOption?.price || ''}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        full
                        placeholder="R$ 00,00"
                        type="text"
                        value={value}
                        error={error ? 'Campo obrigatorio' : ''}
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            ''
          )}
          <Button
            full
            variant="primary"
            loading={loading ? 1 : 0}
            type="submit"
            disabled={isButtonDisabled}
          >
            {editing ? 'Salvar ' : 'Adicionar'}
          </Button>
          {editing && (
            <Button
              disabled={isButtonDisabled}
              full
              mode="dangerous"
              type="button"
              onClick={deleteOption}
            >
              <FiTrash />
              Deletar
            </Button>
          )}
        </Stack>
      </Drawer>

      <Drawer set={setModalGroup} state={modalGroup} title="Editar Grupo">
        <Stack
          spacing="30px"
          as="form"
          onSubmit={handleSubmitGroupEdit(editGroup)}
        >
          <Text variant="label">Nome do Grupo</Text>
          <Controller
            control={controlGroupEdit}
            name="name"
            defaultValue={dataGroup?.name || ''}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                placeholder="Nome do grupo..."
                type="text"
                value={value}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Stack direction="row" spacing="16px">
            <Stack spacing="15px" style={{ width: 'calc(50% - 8px)' }}>
              <Text variant="label">Limite mínimo</Text>
              <Controller
                control={controlGroupEdit}
                name="min"
                defaultValue={dataGroup?.limit_min || ''}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Ex: 1"
                    type="text"
                    value={value}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing="15px" style={{ width: 'calc(50% - 8px)' }}>
              <Text variant="label">Limite máximo</Text>
              <Controller
                control={controlGroupEdit}
                name="max"
                defaultValue={dataGroup?.limit_max || ''}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="Ex: 5"
                    type="text"
                    value={value}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Button
            full
            type="submit"
            variant="primary"
            loading={loading ? 1 : 0}
            disabled={isButtonDisabled}
          >
            Salvar
          </Button>

          <Button
            disabled={isButtonDisabled}
            full
            mode="dangerous"
            type="button"
            onClick={deleteGroup}
          >
            <FiTrash />
            Deletar
          </Button>
        </Stack>
      </Drawer>
    </>
  ) : (
    <></>
  );
};

export default DrawerOptions;

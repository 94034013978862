import axios from 'axios';

export const apiURL = 'https://api.delivery.weeke.app';

// Theme system set
const theme = localStorage.getItem('themeSystem') || 'light';
export const themeSystem = theme !== null ? theme : 'light';

// Color system set
const colorSystem = localStorage.getItem('colorSystem') || 'blue';
export const color = colorSystem !== null ? colorSystem : 'blue';

// Create API rest enviroment
const api = axios.create({
  baseURL: apiURL,
  headers: {
    mode: 'no-cors',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-*': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    'token_weeke_delivery',
  )}`;
  config.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token_weeke_delivery',
  )}`;
  return config;
});

api.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.clear();
    } else if (error.response.data.errors) {
      error.response.data.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log(error.response.data);
    }
  } else {
    console.log('Erro ao completar requisição, tente novamente!');
  }
  return Promise.reject(error);
});

export default api;

import React, { useEffect, useState } from 'react';
import { HiRefresh } from 'react-icons/hi';
import Lottie from 'react-lottie';

import loadingLottie from '../../assets/lotties/loading_data.json';
import { Button, Stack, Text } from '../../lib';

function LoadingPage(): JSX.Element {
  const [pushReload, setPushReload] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPushReload(true);
    }, 5000);
  });
  return (
    <Stack
      style={{ alignSelf: 'center', height: '-webkit-fill-available' }}
      align="center"
      justify="center"
      spacing="20px"
    >
      <Lottie
        options={{ animationData: loadingLottie }}
        style={{ filter: 'saturate(0)', opacity: 1 }}
        height={120}
        width={300}
      />

      <Text>Carregando</Text>
      {pushReload && (
        <Button
          variant="ghost"
          onClick={() => window.location.reload()}
          data-aos="fade-up"
        >
          <HiRefresh />
          Forçar Refresh
        </Button>
      )}
    </Stack>
  );
}

export default LoadingPage;

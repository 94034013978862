/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from '../../contexts/AuthContext';
import {
  StatusContainer,
  List,
  ContainerTracking,
  ItemList,
  Status,
  ContainerImg,
  ContainerInfo,
  Container,
} from './style';
import Ok from '../../assets/icons/ok';
import Preparing from '../../assets/icons/preparing';
import MotorBike from '../../assets/icons/motorbike';
import Happy from '../../assets/icons/happy';
import Sad from '../../assets/icons/sad';
import Bag from '../../assets/icons/bag';
import { apiURL } from '../../services/api';

const TrackingInformation = () => {
  const [company, setCompany] = React.useState([]);
  const [orderData, setOrderData] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const { orderId } = useParams();

  const getCompany = async () => {
    await axios
      .get(
        `${apiURL}/rest/options.php/name,logo,whatsapp,instagram,facebook,mapa,endereco,background,delivery,theme,timeExpected,openTime,closeTime,payment,pix,service`,
      )
      .then((res) => setCompany(res.data));
  };

  const getOrder = async () => {
    await axios
      .get(`${apiURL}/rest/requests.php/${orderId}`)
      .then((res) => setOrderData(res.data));
  };

  useEffect(() => {
    getCompany();
    getOrder();
  }, []);

  const checkingOrderStatus = () => {
    const stage = orderData?.state;
    if (stage) {
      if (stage === 'DELIVERED') {
        setStatus(['ok', 'ok', 'ok', 'ok', 'ok']);
      } else if (stage === 'IN_QUEUE' || stage === 'WAITING_FOR_CONFIRMATION') {
        setStatus(['ok']);
      } else if (stage === 'IN_PRODUCTION') {
        setStatus(['ok', 'ok']);
      } else if (stage === 'CANCELED') {
        setStatus([]);
      } else if (stage === 'EN_ROUTE') {
        setStatus(['ok', 'ok', 'ok', 'ok']);
      } else if (stage === 'DELIVERY_QUEUE') {
        setStatus(['ok', 'ok', 'ok']);
      }
    }
  };

  useEffect(() => {
    checkingOrderStatus();
  }, [orderData]);

  const checkStageImg = (stage) => {
    let resp = '';

    if (stage === 'DELIVERED') {
      resp = <Happy color="green" width="40px" height="40px" />;
    } else if (stage === 'IN_QUEUE' || stage === 'WAITING_FOR_CONFIRMATION') {
      resp = <Ok color="green" width="40px" height="40px" />;
    } else if (stage === 'IN_PRODUCTION') {
      resp = <Preparing color="green" width="40px" height="40px" />;
    } else if (stage === 'CANCELED') {
      resp = <Sad color="green" width="40px" height="40px" />;
    } else if (stage === 'EN_ROUTE') {
      resp = <MotorBike color="green" width="40px" height="40px" />;
    } else if (stage === 'DELIVERY_QUEUE') {
      resp = <Bag color="green" width="40px" height="40px" />;
    }

    return resp;
  };

  const checkStage = (stage) => {
    let resp = '';

    if (stage === 'DELIVERED') {
      resp = 'Pedido entregue!';
    } else if (stage === 'IN_QUEUE' || stage === 'WAITING_FOR_CONFIRMATION') {
      resp = 'Na Fila!';
    } else if (stage === 'IN_PRODUCTION') {
      resp = 'Em Produção!';
    } else if (stage === 'CANCELED') {
      resp = 'Pedido cancelado!';
    } else if (stage === 'EN_ROUTE') {
      resp = 'Saiu para entrega!';
    } else if (stage === 'DELIVERY_QUEUE') {
      resp = 'Na fila de entrega!';
    }

    return resp;
  };

  const FormatData = (date) => {
    const dateTimeObj = new Date(date);
    const day = dateTimeObj.getDate().toString().padStart(2, '0');
    const month = (dateTimeObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTimeObj.getFullYear();
    const hour = dateTimeObj.getHours().toString().padStart(2, '0');
    const minute = dateTimeObj.getMinutes().toString().padStart(2, '0');
    const second = dateTimeObj.getSeconds().toString().padStart(2, '0');
    const formattedDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    return formattedDateTime;
  };

  console.log(orderData);

  const themeColor = { color: company.theme ?? 'blue' };

  return (
    <AuthProvider>
      <ThemeProvider theme={themeColor}>
        <Container>
          <ContainerTracking>
            <h1>Informações do Rastreio</h1>
            <StatusContainer>
              <ContainerImg>
                <Ok
                  color={status[0] ? 'green' : 'gray'}
                  width="40px"
                  height="40px"
                />
                <p>Na Fila</p>
              </ContainerImg>
              <Status background={status[1] ? 'green' : 'gray'} />
              <ContainerImg>
                <Preparing
                  color={status[1] ? 'green' : 'gray'}
                  width="40px"
                  height="40px"
                />
                <p>Em Produção</p>
              </ContainerImg>
              <Status background={status[2] ? 'green' : 'gray'} />
              <ContainerImg>
                <Bag
                  color={status[2] ? 'green' : 'gray'}
                  width="40px"
                  height="40px"
                />
                <p>Pronto</p>
              </ContainerImg>
              <Status background={status[3] ? 'green' : 'gray'} />
              <ContainerImg>
                <MotorBike
                  color={status[3] ? 'green' : 'gray'}
                  width="40px"
                  height="40px"
                />
                <p>Entrega</p>
              </ContainerImg>
              <Status background={status[4] ? 'green' : 'gray'} />
              <ContainerImg>
                <Happy
                  color={status[4] ? 'green' : 'gray'}
                  width="40px"
                  height="40px"
                />
                <p>Entregue</p>
              </ContainerImg>
            </StatusContainer>
            <ContainerInfo>
              <h2>Historico do Pedido</h2>
              <List>
                {orderData?.logs
                  ? orderData?.logs
                      ?.map((el) => {
                        return (
                          <ItemList background="white" key={el.id}>
                            <div>
                              {checkStageImg(el.type)}
                              <div>
                                <p>{checkStage(el.type)}</p>
                                <span>{FormatData(el.date)}</span>
                              </div>
                            </div>
                          </ItemList>
                        );
                      })
                      .reverse()
                  : ''}
              </List>
            </ContainerInfo>
          </ContainerTracking>
        </Container>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default TrackingInformation;

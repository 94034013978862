import styled from 'styled-components';

export const EmptyContainer = styled.div`
  border: 2px dashed #e4e8eb;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 300px;

  > p {
    font-size: 10px;
    font-weight: bold;
    color: #cad2d9;
    text-transform: uppercase;
  }
`;

export const ContainerUpload = styled.div`
  position: relative;
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px dashed #e4e8eb;
`;

export const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  ::before {
    content: 'IMG';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #f4f6f8;
    color: #cad2d9;
    text-align: center;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ImgOption = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;

  ::before {
    content: 'IMG';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f4f6f8;
    color: #cad2d9;
    text-align: center;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const CardOption = styled.div`
  border: 1px dashed #e4e8eb;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 322.8px;
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    word-wrap: break-word;
    width: 256.8px;
  }
`;

export const ContainerInputAdd = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ButtonAdd = styled.button<any>`
  padding: ${(props) => props.padding || '10px'};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--blue3), 1);
  border: 2px dashed rgba(var(--blue3), 1);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: rgba(0, 255, 0, 0.2);
  }
`;

import React from 'react';
import { Button, Stack, Text, Image } from '../../lib';
import notChecked from '../../assets/icons/unchecked.svg';
import { Container } from './style';

function InsufficientPermission() {
  return (
    <Stack
      style={{ flex: 1, height: '-webkit-fill-available', textAlign: 'center' }}
      align="center"
      justify="center"
    >
      <Container>
        <Image src={notChecked} width="160px" height="160px" />
        <Text variant="title">O acesso a esta página é restrito.</Text>
        <Text variant="subtitle">
          Certifique-se de ter permissões suficientes para acessar o mesmo.
        </Text>
      </Container>
    </Stack>
  );
}

export default InsufficientPermission;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import imgStarBlack from '../../assets/icons/star_black.svg';
import imgStarWhite from '../../assets/icons/star_white.svg';
import imgLogo from '../../assets/icons/logoWeekeDelivery.svg';
import IcProducts from '../../assets/icons/products';
import BreadcrumbComponent from '../../components/Breadcrumb';
import FailedLoad from '../../components/FailedLoad';
import HeaderPage from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import DrawerCategories from '../../components/Modals/Categories';
import ModalDelete from '../../components/Modals/Delete';
import DrawerOptions from '../../components/Modals/Options';
import { useAuth } from '../../contexts/AuthContext';
import {
  Button,
  Divider,
  Drawer,
  Image,
  Input,
  Modal,
  Stack,
  Tag,
  Text,
  ToggleButton,
  Uploader,
} from '../../lib';

import api, { apiURL, themeSystem } from '../../services/api';
import { Card, Content } from '../../styles/style';
import TextArea from '../../lib/TextArea';
import InsufficientPermission from '../../components/InsufficientPermission';

interface FormProducts {
  [x: string]: any;
  status: boolean;
  name: string;
  description: string;
  price: string;
  category: { id: number; name: string };
}

export default function Products() {
  const { user } = useAuth();
  const {
    control,
    getValues,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProducts>();

  // StorageDatas
  const [dataTags, setDataTags] = useState<any>('');
  const [dataOptions, setDataOptions] = useState<any>('');

  // States Modals
  const [modalProduct, setModalProduct] = useState<boolean>(false);
  const [modalDel, setModalDel] = useState<boolean>(false);
  const [openMenuDots, setOpenMenuDots] = useState<boolean>(false);
  const [modalListOptions, setModalListOptions] = useState<boolean>(false);
  const [modalListCategory, setModalListCategory] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [filterCat, setFilterCat] = useState<string>('0');
  const [productGroup, setProductGroup] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<string>('loading');
  const [onSearch, setOnSearch] = useState<string>('');
  const [upload, setUpload] = useState<string>('');
  const [monitoringOptions, setMonitoringOptions] = useState<boolean>(false);
  const [modalDuplicate, setModalDuplicate] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [dataCat, setDataCat] = useState<any>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [featuredProductsList, setFeaturedProductsList] = useState<any>([]);
  const [sortedCat, setSortedCat] = useState<any>([]);

  useEffect(() => {
    getSortedCategories();
    getDataCat();
    getData();
    getDataFeatured();
    getProductGroup();
    getGroupOptions();
  }, []);

  useEffect(() => {
    getProductGroup();
    getGroupOptions();
  }, [monitoringOptions]);

  function getData() {
    if (user?.configuration?.access?.products?.read) {
      setLoading(true);
      setLoadingPage('loading');
      api
        .get(`${apiURL}/rest/products.php`)
        .then((res) => {
          setData(res?.data || []);
          setLoading(false);
          setLoadingPage('');
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setLoadingPage('error');
        });
    }
  }

  function getProductGroup() {
    if (user?.configuration?.access?.products?.read) {
      api
        .get(`${apiURL}/rest/products_options_groups.php/`)
        .then((res) => {
          setProductGroup(res?.data || []);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function getGroupOptions() {
    if (user?.configuration?.access?.products?.read) {
      api
        .get(`${apiURL}/rest/products_options.php/`)
        .then((res) => {
          setGroupOptions(res?.data || []);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function getDataCat() {
    if (user?.configuration?.access?.products?.read) {
      setLoading(true);
      api
        .get(`${apiURL}/rest/products_categories.php`)
        .then((res) => {
          const resp = res?.data.map((e) => {
            return {
              id: e?.id,
              name: e?.name,
              priority: e?.priority,
              color: JSON.parse(e?.options)?.color,
            };
          });
          setDataCat(resp || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  function getSortedCategories() {
    if (user?.configuration?.access?.products?.read) {
      setLoading(true);

      api
        .get(`${apiURL}/rest/options.php/categoryPriority`)
        .then((res) => {
          setSortedCat(res?.data?.categoryPriority || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  function getDataFeatured() {
    if (user?.configuration?.access?.products?.read) {
      setLoading(true);
      api
        .get(`${apiURL}/rest/options.php/featured`)
        .then((res) => {
          setFeaturedProductsList(res?.data?.featured || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  function checkProductOptions(id) {
    const groups = productGroup.map((e) => e.product);
    const resp = groups.includes(id);
    return resp;
  }

  const submitProduct = (data: FormProducts) => {
    setLoading(true);
    editing ? updateProduct(data) : createProduct(data);
  };

  const createProduct = async (data: FormProducts) => {
    if (user?.configuration?.access?.products?.write) {
      const formData = new FormData();
      formData.append('image', upload);
      setButtonDisabled(true);

      api
        .post(`rest/products.php`, {
          name: data.name,
          description: data.description,
          price: data.price.replace(',', '.'),
          category: +data.category,
          status: data.status === true ? '1' : '0',
        })
        .then((res: any) => {
          api({
            method: 'post',
            url: `rest/upload.php/product/${res.data}`,
            data: formData,
          }).then(() => {
            setModalDuplicate(false);
            setModalProduct(false);
            getData();
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const copyingProduct = async (data: FormProducts) => {
    if (user?.configuration?.access?.products?.write) {
      setButtonDisabled(true);
      api
        .post(`rest/products.php`, {
          name: `Copy ${data.name}`,
          description: data.description,
          price: data.price.replace(',', '.'),
          category: +data.category,
          status: data.status === true ? '1' : '0',
          image: data.image,
        })
        .then((res: any) => {
          getData();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
          setModalDuplicate(false);
          setModalProduct(false);
        });
    }
  };

  const copyingProductOptions = async (data: FormProducts) => {
    if (user?.configuration?.access?.products?.write) {
      const groups = productGroup?.filter(
        (group: any) => group.product === String(data.id),
      );

      setButtonDisabled(true);

      api
        .post(`rest/products.php`, {
          name: `Copy ${data.name}`,
          description: data.description,
          price: data.price.replace(',', '.'),
          category: +data.category,
          status: data.status === true ? '1' : '0',
          image: data.image,
        })
        .then((respProduct: any) => {
          groups.forEach((group: any) => {
            api
              .post(`rest/products_options_groups.php`, {
                name: group.name,
                limit_min: group.limit_min,
                limit_max: group.limit_max,
                product: respProduct.data,
              })
              .then((respGroup: any) => {
                const options = groupOptions.filter(
                  (op: any) => op.group === group.id,
                );

                options.forEach((option: any) => {
                  api.post(`rest/products_options.php`, {
                    name: option.name,
                    limit_min: option.limit_min,
                    limit_max: option.limit_max,
                    image: option.image,
                    price: option.price,
                    group: respGroup.data,
                  });
                });

                getProductGroup();
                getGroupOptions();
                getData();
              });
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
          setModalDuplicate(false);
          setModalProduct(false);
        });
    }
  };

  const updateProduct = async (data: FormProducts) => {
    if (user?.configuration?.access?.products?.write) {
      const formData = new FormData();
      formData.append('image', upload);

      setButtonDisabled(true);

      api
        .put(`rest/products.php/${data.id}`, {
          name: data.name,
          description: data.description,
          price: data.price.replace(',', '.'),
          category: data.category,
          status: data.status === true ? '1' : '0',
        })
        .then((e) => {
          getData();
          setModalProduct(false);
          if (upload !== 'null') {
            api({
              method: 'post',
              url: `rest/upload.php/product/${data.id}`,
              data: formData,
            }).then(() => {
              setModalProduct(false);
              getData();
            });
          } else if (upload === 'null') {
            api.put(`rest/products.php/${data.id}`, {
              image: null,
            });
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  const highlightProduct = async (e: any) => {
    if (user?.configuration?.access?.products?.write) {
      e.stopPropagation();
      setLoading(true);
      setLoadingPage('loading');
      const key = Number(e.target.getAttribute('data-key'));
      let products = featuredProductsList;
      if (featuredProductsList.includes(key)) {
        const newList = featuredProductsList?.filter(
          (e: number) => Number(e) !== key,
        );
        products = newList;
      } else if (featuredProductsList.length === 0) {
        products = [key];
      } else {
        products = [key, ...featuredProductsList];
      }

      api
        .put(`rest/options.php`, {
          featured: JSON.stringify(products),
        })
        .then((e) => {
          getDataFeatured();
          setLoading(false);
          setLoadingPage('');
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  function sortedCategories() {
    const newOrder: any = [];

    if (dataCat.length > 0) {
      sortedCat?.forEach((e: any) => {
        const item = dataCat?.find((el: any) => Number(el?.id) === Number(e));
        newOrder.push(item);
      });
    }
    return newOrder;
  }

  function updateOrderCategories() {
    getSortedCategories();
    sortedCategories();
  }

  return user?.configuration?.access?.products?.read ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : loadingPage === 'error' ? (
        <FailedLoad />
      ) : (
        <>
          <HeaderPage />
          <Content>
            <BreadcrumbComponent
              name="Produtos"
              icon={<IcProducts />}
              permission={user?.configuration?.access?.products?.write}
              addNew={() => {
                setModalProduct(true);
                reset({});
                setDataTags('');
                setDataOptions('');
                setEditing(false);
                setUpload('');
              }}
              length={
                data?.filter((e: any) =>
                  filterCat === '0' ? e : e.category.id === filterCat,
                ).length
              }
              extra={
                <div style={{ position: 'relative' }}>
                  <Button
                    variant="ghost"
                    shape="icon"
                    onClick={() => setOpenMenuDots(!openMenuDots)}
                  >
                    <BsThreeDotsVertical size="16px" />
                  </Button>
                  {openMenuDots && (
                    <Stack
                      style={{
                        background:
                          themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
                        position: 'absolute',
                        top: 60,
                        right: 0,
                        width: '250px',
                        border: '1px solid #eee',
                        borderRadius: 5,
                        boxShadow: '-10px 0 50px -15px #00000020',
                      }}
                    >
                      <Stack
                        spacing="13px"
                        padding="15px"
                        style={{ marginTop: -10 }}
                      >
                        <Text variant="label" full>
                          <Stack direction="row" justify="space-between">
                            Categorias
                            <Button
                              shape="small"
                              variant="link"
                              type="button"
                              onClick={() => setModalListCategory(true)}
                              style={{ marginLeft: 10 }}
                            >
                              Ver Categorias
                            </Button>
                          </Stack>
                        </Text>
                        <Input
                          full
                          as="select"
                          type="text"
                          placeholder="Tipo do produto"
                          defaultValue={getValues('category.id') || 0}
                          value={filterCat}
                          onChange={(e: any) => {
                            setFilterCat(e.target.value);
                          }}
                        >
                          <option value="0">Todos</option>
                          {sortedCat
                            ? sortedCategories()?.map((item: any) => (
                                // eslint-disable-next-line react/jsx-indent
                                <option value={item?.id} key={item?.id}>
                                  {item?.name}
                                </option>
                              ))
                            : ''}
                        </Input>
                      </Stack>
                    </Stack>
                  )}
                </div>
              }
              search={
                <Input
                  type="text"
                  placeholder="Pesquisar ..."
                  icon={<BsSearch />}
                  id="name"
                  value={onSearch}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setOnSearch(e.currentTarget?.value);
                  }}
                />
              }
            />
            <Stack data-aos="fade-up">
              <Stack direction="row" spacing="20px" align="center" wrap="true">
                {data
                  ?.filter((e: any) =>
                    e.name.toLowerCase().includes(onSearch.toLowerCase()),
                  )
                  ?.filter((e: any) =>
                    filterCat === '0' ? e : e.category.id === filterCat,
                  )
                  ?.map((item: any) => (
                    <Card
                      key={item?.id}
                      as={Stack}
                      direction="row"
                      spacing="20px"
                      align="top"
                      onClick={() => {
                        if (user?.configuration?.access?.products?.write) {
                          reset(item);
                          setUpload(item.image);
                          setModalProduct(true);
                          setEditing(true);
                          setValue('status', item.status === '1');
                          setValue('category', item.category?.id);
                          setDataTags(item.tags);
                          setDataOptions(item.options);
                        }
                      }}
                    >
                      <Image
                        src={`${
                          item?.image &&
                          !item.image.includes('product_no_photo.png')
                            ? item.image
                            : imgLogo
                        }?date=${moment(new Date()).format(
                          'DD-MM-YYYY-HH:mm:ss',
                        )}`}
                        width="65px"
                        height="65px"
                        borderRadius="50%"
                      />
                      <Stack
                        direction="row"
                        justify="space-between"
                        align="center"
                      >
                        <Stack spacing="7px">
                          <Text variant="subtitle">{item.name}</Text>
                          <Text variant="small">{item.category?.name}</Text>
                          <Text>
                            R${' '}
                            {parseFloat(item.price)
                              .toFixed(2)
                              .replace('.', ',')}
                          </Text>
                        </Stack>
                        <div>
                          <Stack
                            direction="column"
                            justify="end"
                            align="center"
                            spacing="50px"
                          >
                            {featuredProductsList.includes(Number(item.id)) ? (
                              <Image
                                src={`${imgStarBlack || 'null'}?date=${moment(
                                  new Date(),
                                ).format('DD-MM-YYYY-HH:mm:ss')}`}
                                width="24px"
                                height="24px"
                                title="Destacado"
                                onClick={highlightProduct}
                                data-key={item.id}
                              />
                            ) : (
                              <Image
                                src={`${imgStarWhite || 'null'}?date=${moment(
                                  new Date(),
                                ).format('DD-MM-YYYY-HH:mm:ss')}`}
                                width="24px"
                                height="24px"
                                title="Destacar?"
                                onClick={highlightProduct}
                                data-key={item.id}
                              />
                            )}
                            <Tag color={item.status === '1' ? 'green' : 'red'}>
                              {item.status === '1' ? 'Ativo' : 'Desativado'}
                            </Tag>
                          </Stack>
                        </div>
                      </Stack>
                    </Card>
                  ))}
              </Stack>
            </Stack>
          </Content>
        </>
      )}
      <Drawer
        set={setModalProduct}
        state={modalProduct}
        title={editing ? 'Editar Produto' : 'Adicionar Produto'}
        behind={modalListCategory || modalListOptions}
      >
        <Stack spacing="30px" as="form" onSubmit={handleSubmit(submitProduct)}>
          <Text variant="label">Status</Text>

          <Stack direction="row" spacing="15px">
            <Controller
              control={control}
              name="status"
              defaultValue={getValues('status') ?? true}
              render={({ field: { onChange, value } }) => (
                <ToggleButton onChange={onChange} checked={value} />
              )}
            />
          </Stack>
          <Text variant="label">Nome do produto</Text>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                placeholder="Nome do produto"
                type="text"
                defaultValue={getValues('name')}
                value={value}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Uploader thumb={upload} upload={(img: any) => setUpload(img)} />

          <Text variant="label">Descrição</Text>
          <Controller
            control={control}
            name="description"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextArea
                full
                placeholder="Breve descrição do produto"
                type="text"
                value={value}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Stack direction="row" spacing="16px">
            <Stack
              spacing="15px"
              style={{ width: 'calc(50% - 8px)', position: 'relative' }}
            >
              <Text variant="label">Preço </Text>
              <p
                style={{
                  position: 'absolute',
                  top: 56,
                  left: 6,
                  zIndex: 10,
                  fontSize: 11,
                  color: '#bbb',
                }}
              >
                R$
              </p>
              <Controller
                control={control}
                name="price"
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    full
                    placeholder="00,00"
                    type="text"
                    value={value?.replace('.', ',')}
                    error={error ? 'Campo obrigatorio' : ''}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Divider />

          <Text variant="label" full>
            <Stack direction="row" justify="space-between">
              Categoria Principal
              <Button
                shape="small"
                variant="link"
                type="button"
                onClick={() => setModalListCategory(true)}
                style={{ marginLeft: 10 }}
              >
                Ver Categorias
              </Button>
            </Stack>
          </Text>

          <Controller
            control={control}
            name="category"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                full
                as="select"
                type="text"
                placeholder="Tipo do produto"
                defaultValue={getValues('category.id') || 0}
                value={value}
                error={error ? 'Campo obrigatorio' : ''}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              >
                <option value="0" disabled>
                  Selecione uma categoria
                </option>
                {sortedCat
                  ? sortedCategories()?.map((item: any) => (
                      // eslint-disable-next-line react/jsx-indent
                      <option value={item?.id} key={item?.id}>
                        {item?.name}
                      </option>
                    ))
                  : ''}
              </Input>
            )}
          />

          {editing ? (
            <>
              <Text variant="label">Opcionais</Text>
              <Button
                full
                type="button"
                variant="outline"
                style={{ borderStyle: 'dashed' }}
                onClick={() => {
                  setModalListOptions(true);
                  setDataOptions('');
                }}
              >
                Ver Opcionais
              </Button>
            </>
          ) : (
            ''
          )}
          <Stack
            direction="row"
            align="center"
            spacing="20px"
            padding="15px 0"
            style={{
              position: 'sticky',
              bottom: '-25px',
              background: themeSystem !== 'dark' ? '#FFFFFF' : '#191A1F',
            }}
          >
            <Button
              variant="ghost"
              full
              type="button"
              onClick={() => {
                setModalProduct(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              full
              type="submit"
              loading={loading ? 1 : 0}
              disabled={isButtonDisabled}
            >
              {editing ? 'Salvar' : 'Adicionar'}
            </Button>
          </Stack>
          <Button
            full
            type="button"
            variant="ghost"
            onClick={() => {
              checkProductOptions(getValues('id'))
                ? setModalDuplicate(true)
                : copyingProduct(getValues());
            }}
            disabled={isButtonDisabled}
          >
            <HiOutlineDuplicate />
            Duplicar
          </Button>
          {editing && (
            <Button
              full
              mode="dangerous"
              type="button"
              onClick={() => {
                if (user?.configuration?.access?.products?.write) {
                  setModalDel(true);
                  setModalProduct(false);
                }
              }}
            >
              <FiTrash />
              Deletar
            </Button>
          )}
        </Stack>
      </Drawer>

      <ModalDelete
        set={setModalDel}
        state={modalDel}
        update={getData}
        disabled={isButtonDisabled}
        setDisabled={setButtonDisabled}
        item={`${apiURL}/rest/products.php/${getValues('id')}`}
      />

      <Modal
        set={setModalDuplicate}
        state={modalDuplicate}
        title={`Copiar ${getValues('name')}` ?? 'Copiar'}
      >
        <Text gutter="20px">
          Gostaria de copiar também os opcionais do produto?
        </Text>
        <Stack direction="row" align="center" spacing="20px">
          <Button
            variant="ghost"
            full
            type="button"
            onClick={() => copyingProduct(getValues())}
            disabled={isButtonDisabled}
          >
            Não
          </Button>
          <Button
            variant="primary"
            full
            type="button"
            onClick={() => copyingProductOptions(getValues())}
            disabled={isButtonDisabled}
          >
            Sim
          </Button>
        </Stack>
      </Modal>

      <DrawerCategories
        set={setModalListCategory}
        update={getDataCat}
        state={modalListCategory}
        data={sortedCategories()}
        updateCategories={updateOrderCategories}
      />

      <DrawerOptions
        set={setModalListOptions}
        state={modalListOptions}
        data={dataOptions}
        groups={productGroup}
        options={groupOptions}
        id={getValues('id')}
        setMonitoringOptions={setMonitoringOptions}
      />
    </>
  ) : (
    <>
      <HeaderPage />
      <InsufficientPermission />
    </>
  );
}

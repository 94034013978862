/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import api, { apiURL } from '../../services/api';
import {
  Container,
  ContainerDeliveries,
  Filter,
  FilterList,
  CardDelivery,
  CardHeader,
  CardBody,
  CardFooter,
} from './style';
import { Button, Image } from '../../lib';
import filter from '../../assets/icons/filter.svg';
import right from '../../assets/icons/right.svg';
import { AuthProvider, useAuth } from '../../contexts/AuthContext';
import LoadingPage from '../../components/LoadingPage';
import {
  ModalDeliveries,
  colorsSteps,
  backgroundColors,
  lists,
} from '../../components/Modals/Orders';
import HeaderPage from '../../components/Header';
import InsufficientPermission from '../../components/InsufficientPermission';

const Deliveries = () => {
  const [data, setData] = React.useState([]);
  const [filterSize, setFilterSize] = React.useState('50px');
  const [displayStates, setDisplayStates] = React.useState('none');
  const [modal, setModal] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const { user } = useAuth();
  const [dataCategory, setDataCategory] = React.useState([]);
  const [category, setCategory] = React.useState('Todos');
  const [loadingPage, setLoadingPage] = React.useState('');
  const [monitoring, setMonitoring] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [deliveryFee, setDeliveryFee] = React.useState('');
  const [modalCanceled, setModalCanceled] = React.useState(false);

  const getData = async () => {
    const token = localStorage.getItem('token_weeke_delivery');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (
      user?.configuration?.access?.couriers?.read ||
      user?.configuration?.access?.couriers?.exec
    ) {
      setLoadingPage('loading');
      api
        .get(`${apiURL}/rest/requests.php/`, { headers })
        .then((res) => {
          setData(
            res.data?.filter(
              (e) =>
                e.state === 'DELIVERY_QUEUE' ||
                e.state === 'EN_ROUTE' ||
                e.state === 'DELIVERED' ||
                e.state === 'CANCELED',
            ),
          );
          setDataCategory(
            res.data?.filter(
              (e) =>
                e.state === 'DELIVERY_QUEUE' ||
                e.state === 'EN_ROUTE' ||
                e.state === 'DELIVERED' ||
                e.state === 'CANCELED',
            ),
          );
          setCategory('Todos');
        })
        .then(() => {
          setLoadingPage('');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getCompany = async () => {
    if (
      user?.configuration?.access?.couriers?.read ||
      user?.configuration?.access?.couriers?.exec
    ) {
      await api
        .get(
          `${apiURL}/rest/options.php/name,logo,whatsapp,instagram,facebook,mapa,endereco,background,delivery,theme,timeExpected,openTime,closeTime,payment,pix,service`,
        )
        .then((res) => setCompany(res.data));
    }
  };

  const getDeliveryFee = async () => {
    if (
      user?.configuration?.access?.couriers?.read ||
      user?.configuration?.access?.couriers?.exec
    ) {
      await api.get('rest/options.php/delivery').then((res) => {
        setDeliveryFee(res.data.delivery);
      });
    }
  };

  const getPaymentMethods = async () => {
    if (
      user?.configuration?.access?.couriers?.read ||
      user?.configuration?.access?.couriers?.exec
    ) {
      await api.get('rest/payment_gateway.php').then((res) => {
        setPaymentMethods(res.data);
      });
    }
  };

  useEffect(() => {
    getData();
    getCompany();
    getPaymentMethods();
    getDeliveryFee();
  }, []);

  useEffect(() => {
    getData();
  }, [monitoring]);

  const themeColor = { color: company.theme ?? 'blue' };

  const openFilter = () => {
    setFilterSize((prev) => (prev === '50px' ? '100%' : '50px'));
    setDisplayStates((prev) => (prev === 'none' ? 'flex' : 'none'));
  };

  const checkingStatus = (status) => {
    let resp = '';

    if (status === 'DELIVERY_QUEUE') {
      resp = 'Fila de Entrega';
    } else if (status === 'EN_ROUTE') {
      resp = 'A Caminho';
    } else if (status === 'DELIVERED') {
      resp = 'Entregue';
    } else if (status === 'CANCELED') {
      resp = 'Cancelado';
    }

    return resp;
  };

  const openModal = (el) => {
    // eslint-disable-next-line prefer-destructuring
    const id = el.currentTarget.dataset.id;
    setOrderData(data.find((e) => e.id === id));
    setModal(true);
  };

  const filteringByCategory = (filterCategory) => {
    let resp = [];
    if (filterCategory === 'todos') {
      resp = data;
      setCategory('Todos');
    } else if (filterCategory === 'fila de entregas') {
      // eslint-disable-next-line array-callback-return
      resp = data?.filter((e) => e.state === 'DELIVERY_QUEUE');
      setCategory('Fila de Entregas');
    } else if (filterCategory === 'a caminho') {
      // eslint-disable-next-line array-callback-return
      resp = data?.filter((e) => e.state === 'EN_ROUTE');
      setCategory('A Caminho');
    } else if (filterCategory === 'entregue') {
      // eslint-disable-next-line array-callback-return
      resp = data?.filter((e) => e.state === 'DELIVERED');
      setCategory('Entregue');
    } else if (filterCategory === 'cancelados') {
      // eslint-disable-next-line array-callback-return
      resp = data?.filter((e) => e.state === 'CANCELED');
      setCategory('Cancelados');
    }
    openFilter();
    return setDataCategory(resp);
  };

  return user?.configuration?.access?.couriers?.read ||
    user?.configuration?.access?.couriers?.exec ? (
    <>
      {loadingPage === 'loading' ? (
        <LoadingPage />
      ) : (
        <AuthProvider>
          <ThemeProvider theme={themeColor}>
            <Container>
              <HeaderPage title="Entregas" />
              <Filter height={filterSize}>
                <Button onClick={openFilter}>
                  {category}
                  <Image src={filter} width="24px" height="24px" />
                </Button>
                <FilterList display={displayStates}>
                  <button
                    type="button"
                    onClick={() => filteringByCategory('todos')}
                  >
                    Todos
                  </button>
                  <button
                    type="button"
                    onClick={() => filteringByCategory('fila de entregas')}
                  >
                    Fila de Entregas
                  </button>
                  <button
                    type="button"
                    onClick={() => filteringByCategory('a caminho')}
                  >
                    A caminho
                  </button>
                  <button
                    type="button"
                    onClick={() => filteringByCategory('entregue')}
                  >
                    Entregue
                  </button>
                  <button
                    type="button"
                    onClick={() => filteringByCategory('cancelados')}
                  >
                    Cancelados
                  </button>
                </FilterList>
              </Filter>
              <ContainerDeliveries>
                {dataCategory?.map((e) => {
                  return (
                    <CardDelivery
                      key={e.id}
                      onClick={(el) => openModal(el)}
                      data-id={e.id}
                    >
                      <CardHeader
                        backgroundColor={
                          backgroundColors[
                            Number(
                              lists?.find((el) => el.state === e?.state)?.id,
                            ) - 1
                          ]
                        }
                        color={
                          colorsSteps[
                            Number(
                              lists?.find((el) => el.state === e?.state)?.id,
                            ) - 1
                          ]
                        }
                      >
                        <h2>{checkingStatus(e.state)}</h2>
                        <span>#{e.id}</span>
                      </CardHeader>
                      <CardBody>
                        <p>{`Nome: ${e.user.name}`}</p>
                        <p>
                          {`${`Endereço: ${e.address.rua}, ${e.address.bairro}`.slice(
                            0,
                            60,
                          )}...`}
                        </p>
                        <div>
                          <Image
                            src="https://i.ibb.co/B4pRZ5y/map.png%22"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </CardBody>
                      <CardFooter>
                        <span>Vizualizar Entrega</span>
                        <Image
                          src={right || 'null'}
                          width="16px"
                          height="16px"
                        />
                      </CardFooter>
                    </CardDelivery>
                  );
                })}
              </ContainerDeliveries>
            </Container>
            <ModalDeliveries
              modal={modal}
              setModal={setModal}
              data={orderData}
              deliveryFee={deliveryFee}
              paymentMethods={paymentMethods}
              setMonitoring={setMonitoring}
              modalCanceled={modalCanceled}
              setModalCanceled={setModalCanceled}
            />
          </ThemeProvider>
        </AuthProvider>
      )}
    </>
  ) : (
    <>
      <HeaderPage title="Entregas" />
      <InsufficientPermission />
    </>
  );
};

export default Deliveries;

import React from 'react';
import { Text, Image } from '../../lib/style';
import { HighlightsCard } from './style';
import imgLogo from '../../../../assets/icons/logoWeekeDelivery.svg';

function Card({ image, title, category, price, open }) {
  return (
    <HighlightsCard onClick={open}>
      <div>
        <Text variant="heading" gutter="8px">
          {title}
        </Text>
        <Text variant="small" gutter="8px">
          {category}
        </Text>
        <Text variant="caption-md">
          {Number(price)?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Text>
      </div>
      <div>
        <Image
          src={
            image && !image.includes('product_no_photo.png') ? image : imgLogo
          }
          width="90px"
          height="90px"
        />
      </div>
    </HighlightsCard>
  );
}

export default Card;

/* eslint-disable no-unused-expressions */
import React from 'react';

// Componentes
import { Button, Modal, Stack, Text } from '../../../lib';
import api from '../../../services/api';

interface Props {
  state: boolean;
  set: any;
  item: string;
  title?: string;
  update?: any;
  disabled: any;
  setDisabled: any;
}

const ModalDelete: React.FC<Props> = ({ ...props }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const deleteItem = async (item: string) => {
    setLoading(true);
    props.setDisabled(true);
    api
      .delete(item)
      .then((e) => {
        setLoading(false);
        props.set(false);
        props.update();
        props.setDisabled(false);
      })
      .catch((e) => {
        console.log(e);
        props.setDisabled(false);
      });
  };

  return (
    <Modal set={props.set} state={props.state} title={props.title ?? 'Delete'}>
      <Text gutter="20px">
        Essa ação será irreversível. Deseja realmente deletar?
      </Text>
      <Stack direction="row" align="center" spacing="20px">
        <Button
          variant="ghost"
          full
          onClick={() => {
            props.set(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          full
          loading={loading ? 1 : 0}
          onClick={() => {
            deleteItem(props.item);
          }}
          disabled={props.disabled}
        >
          Deletar
        </Button>
      </Stack>
    </Modal>
  );
};

export default ModalDelete;

/* eslint-disable no-else-return */
/* eslint-disable react/react-in-jsx-scope */
import { WarningModalStyle } from './style';
import { Text, Image } from '../../../lib';
import ImgWarning from '../../../assets/icons/warning';
import ImgSuccess from '../../../assets/icons/success';
import ImgError from '../../../assets/icons/error';

export const WarningModal = ({ text, img, animation }) => {
  const getImageByType = (name) => {
    if (name === 'warning') {
      return <ImgWarning />;
    } else if (name === 'success') {
      return <ImgSuccess />;
    } else if (name === 'error') {
      return <ImgError />;
    }
  };
  return (
    <WarningModalStyle animation={animation}>
      {getImageByType(img)}
      <Text variant="label">{text}</Text>
    </WarningModalStyle>
  );
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Lottie from 'reactjs-lottie';
import { ThemeContext } from 'styled-components';

// Icones
import { BiChevronDown } from 'react-icons/bi';
import { MdDeliveryDining } from 'react-icons/md';
import { HiCreditCard, HiCash } from 'react-icons/hi';
import moment from 'moment';
import {
  Text,
  Stack,
  ModalHead,
  ModalBottom,
  ModalBody,
  Button,
  ModalOverlay,
  Box,
  Alert,
  ModalFooter,
} from '../../lib/style';
import { ReactComponent as IconPix } from '../../lib/iconPix.svg';
import IconSuccess from '../../lotties/success.json';
import api, { apiURL } from '../../../../services/api';

export default function ModalOrder({ open, order, orderId, close }) {
  const [orderReq, setOrderReq] = React.useState();
  const [dataPayment, setDataPayment] = React.useState();

  useEffect(() => {
    getOrder();
    getPayment();
  }, [open]);

  const FormattingDate = () => {
    const date = new Date();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

    const newFormattedDate = `Data: ${day}/${month}/${date.getFullYear()} - Horário: ${hour}:${minutes}:${seconds}
    `;

    return newFormattedDate;
  };

  const getOrder = async (data) => {
    await api.get(`${apiURL}/rest/requests.php/${orderId}`).then((res) => {
      setOrderReq({ ...order, ...res.data });
    });
  };

  async function getPayment() {
    await api.get('rest/payment_gateway.php').then((res) => {
      setDataPayment(res.data);
    });
  }
  const themeColor = React.useContext(ThemeContext);

  return (
    <>
      <CSSTransition in={open} timeout={300} classNames="fade" unmountOnExit>
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modalbottom"
        unmountOnExit
      >
        <ModalBottom>
          <ModalBody>
            <ModalHead>
              <Stack direction="row" spacing="15px" alignItems="center">
                <BiChevronDown
                  size="35"
                  color={`rgba(var(--${themeColor}), 1)`}
                  onClick={close}
                />
                <Text variant="title">Resumo</Text>
              </Stack>
            </ModalHead>
            {orderReq && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  style={{ marginTop: 20 }}
                >
                  <Box
                    borderRadius="10px 0 0 10px"
                    padding="12px 25px 10px"
                    style={{ backgroundColor: '#1EE7AE' }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing="20px"
                    >
                      <Box style={{ width: 50 }}>
                        <Lottie
                          width="50px"
                          height="50px"
                          options={{
                            animationData: IconSuccess,
                          }}
                        />
                      </Box>
                      <Box>
                        <Text variant="caption" gutter="6px" color="#ffffff">
                          PEDIDO ENVIADO
                        </Text>
                        <Text variant="small" color="#FFFFFF">
                          {FormattingDate()}
                        </Text>
                      </Box>
                    </Stack>
                  </Box>
                  <Box
                    borderRadius="0 10px 10px 0"
                    padding="12px 35px"
                    style={{
                      backgroundColor: '#1DD09D',
                      width: 'fit-content',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                  >
                    <Text variant="title" color="#ffffff">
                      #{orderReq?.id}
                    </Text>
                  </Box>
                </Stack>
                <Stack divider="true" padding="0 0 30px 0">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing="20px"
                    padding="30px 0"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing="20px"
                    >
                      <MdDeliveryDining
                        size="30px"
                        color={`rgba(var(--${themeColor}), 1)`}
                      />
                      <Box>
                        <Text>Estimativa de entrega</Text>
                        <Text variant="small">
                          Chegará até as{' '}
                          {moment(new Date().getTime())
                            .add(+orderReq.timeExpected, 'minutes')
                            .format('HH:mm')}
                        </Text>
                      </Box>
                    </Stack>
                    <Box style={{ width: '200px', textAlign: 'right' }}>
                      <Text
                        variant="title"
                        color={`rgba(var(--${themeColor}), 1)`}
                      >
                        {+orderReq.timeExpected}-{+orderReq.timeExpected + 10}
                        min
                      </Text>
                    </Box>
                  </Stack>
                  <Box>
                    <Box padding="15px 0">
                      <Stack
                        direction="row"
                        spacing="15px"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="small">Subtotal</Text>
                        <Text>
                          {orderReq.total?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      </Stack>

                      <Stack
                        direction="row"
                        spacing="15px"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="small">Taxa de entrega</Text>
                        <Text>
                          {(
                            orderReq?.deliveryTax && +orderReq?.deliveryTax
                          )?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      </Stack>
                    </Box>
                    <Box padding="5px 0">
                      <Stack
                        direction="row"
                        spacing="15px"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="caption-md">Total</Text>
                        <Text variant="caption-md">
                          {(
                            orderReq?.total + +orderReq?.deliveryTax
                          )?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                          {console.log(orderReq.Total)}
                        </Text>
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
                {/* <Alert>
              <Text variant="small">
                Confirme os dados de entrega, valor final e seu nome e envie o
                comprovante para o bot no WhatsApp
              </Text>
            </Alert> */}
                <Stack direction="column" spacing="20px" padding="30px 0">
                  <Box>
                    <Text variant="caption" gutter="5px">
                      NOME
                    </Text>
                    <Text>{orderReq.name}</Text>
                  </Box>
                  {orderReq?.rua && orderReq?.numero && (
                    <Box>
                      <Text variant="caption" gutter="5px">
                        ENDEREÇO DE ENTREGA
                      </Text>
                      <Text>
                        {`${orderReq.rua}, ${orderReq.numero}, ${orderReq.complemento}`}
                        <br />
                        {`${orderReq.cep}, ${orderReq.bairro}, ${orderReq.cidade} – ${orderReq.uf}`}
                      </Text>
                    </Box>
                  )}
                  <Box>
                    <Text variant="caption" gutter="5px">
                      FORMA DE PAGAMENTO
                    </Text>
                    <Stack
                      direction="row"
                      spacing="15px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" spacing="10px">
                        {dataPayment?.find(
                          (e) => e.id === orderReq?.pagamento,
                        ) ? (
                          <>
                            <Text>
                              {
                                dataPayment.find(
                                  (e) => e.id === orderReq?.pagamento,
                                ).name
                              }
                            </Text>
                          </>
                        ) : (
                          <Text>Forma de pagamento não encontrada</Text>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </>
            )}
            {/* <ModalFooter>
              <a
                href={`https://api.whatsapp.com/send?phone=+5567998091459&text=Oi 👋 Acabei de fazer um pedido pelo Weeke Delivery
              %0a%0a
              *Meu Pedido*%0a
              N° ${orderReq?.id}%0a
              %0a
               ${orderReq.order?.map(
                 (item) =>
                   `*• ${item.qtd} ${item.id.nome}*%0a - ${item.options
                     ?.filter((item) => item !== undefined)
                     .map((item) => `${item.nome}${' '}`)}`,
               )}%0a
              %0a
              *Resumo*%0a
              Valor do pedido: R$ ${orderReq.total}%0a
              Taxa de Entrega: R$ ${orderReq.deliveryTax}%0a
              -------------------------%0a
              Total do Pedido: R$${orderReq.total + orderReq.deliveryTax}%0a
              %0a
              *Forma de pagamento* %0a
              ${orderReq.pagamento}%0a
              %0a
              *Forma de entrega*%0a
              Tipo: Delivery%0a
              Endereço: ${orderReq.logradouro}, ${orderReq.numero}, ${
                  orderReq.complemento
                } ${orderReq.cep}, ${orderReq.bairro}, ${orderReq.localidade} – ${
                  orderReq.uf
                }%0a
              Nome: ${orderReq.name}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="solid" size="lg" style={{ width: '100%' }}>
                  Enviar Comprovante
                </Button>
              </a>
            </ModalFooter> */}
          </ModalBody>
        </ModalBottom>
      </CSSTransition>
    </>
  );
}

import styled from 'styled-components';
import { color } from '../../services/api';

export const Cont = styled.div`
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnItem = styled(Cont)`
  border: 1px solid transparent;
  :hover {
    border-bottom: 1px dashed gray;
  }
`;

export const Column = styled(Cont)`
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const Title = styled(Cont)`
  width: 100%; 
  height: auto;
  padding 15px 0px;
  background-color: ${(props) => props.theme.backgroundSec};

  > span{
    color: ${(props) => props.theme.text};
    margin: 0px;
  }
`;

export const InputDate = styled(Cont)`
  box-sizing: border-box;
  width: auto;
  max-width: 600px;
  height: 48.8px;

  padding: 13px 20px;

  border: 1px solid ${(props) => props.theme.shadow};
  border-radius: 7px;

  background-color: ${(props) => props.theme.backgroundSec};
  color: ${(props) => props.theme.text};

  font-size: 0.875rem;
  font-weight: 400;
  -webkit-transition: 300ms all ease;
  transition: 300ms all ease;

  gap: 20px;

  overflow: hidden;

  > select{
    border: none;
  }
}
`;

export const Select = styled.select`
  box-sizing: border-box;
  width: auto;
  max-width: 329px;
  height: 48.8px;
  padding: 13px 20px;

  border: 1px solid ${(props) => props.theme.shadow};
  border-radius: 7px;

  background-color: ${(props) => props.theme.backgroundSec};
  color: ${(props) => props.theme.text};

  font-size: 0.875rem;
  font-weight: 400;

  background-image: url(/img/icon_chevron.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  padding-right: 40px;
  background-position: top 50% right 12px;
`;

import React from 'react';
import axios from 'axios';

import { ThemeProvider } from 'styled-components';
import { AuthProvider } from '../../contexts/AuthContext';
import { apiURL } from '../../services/api';
import { ListLocation, Container, HeaderLocation, ItemLocation } from './style';
import { Button } from '../site/lib/style';

const NextUnit = () => {
  const [company, setCompany] = React.useState([]);

  const getCompany = async () => {
    await axios
      .get(
        `${apiURL}/rest/options.php/name,logo,whatsapp,instagram,facebook,mapa,endereco,background,delivery,theme,timeExpected,openTime,closeTime,payment,pix,service`,
      )
      .then((res) => setCompany(res.data));
  };

  const themeColor = { color: company.theme ?? 'blue' };

  return (
    <AuthProvider>
      <ThemeProvider theme={themeColor}>
        <Container>
          <div>
            <HeaderLocation>
              <h1>Weeke delivery</h1>
              <p>
                Utilize sua localização para encontrar a unidade mais próxima de
                você.
              </p>
              <Button key="" variant="ghost" size="md">
                Usar minha localização
              </Button>
            </HeaderLocation>
            <ListLocation>
              <ItemLocation>
                <div>
                  <img
                    src="https://www.weeke.com.br/wp-content/uploads/2021/07/logotipo-weeke-manager.svg"
                    alt="logo"
                  />
                </div>
                <div>
                  <h2>Weeke Delivery</h2>
                  <p>Av. Cinco, n°1, Parque Cuiaba, Cuiabá-MT</p>
                  <p>Abre ás 17:30</p>
                </div>
              </ItemLocation>
              <ItemLocation>
                <div>
                  <img
                    src="https://www.weeke.com.br/wp-content/uploads/2021/07/logotipo-weeke-manager.svg"
                    alt="logo"
                  />
                </div>
                <div>
                  <h2>Weeke Delivery</h2>
                  <p>Av. Cinco, n°1, Parque Cuiaba, Cuiabá-MT</p>
                  <p>Abre ás 17:30</p>
                </div>
              </ItemLocation>
              <ItemLocation>
                <div>
                  <img
                    src="https://www.weeke.com.br/wp-content/uploads/2021/07/logotipo-weeke-manager.svg"
                    alt="logo"
                  />
                </div>
                <div>
                  <h2>Weeke Delivery</h2>
                  <p>Av. Cinco, n°1, Parque Cuiaba, Cuiabá-MT</p>
                  <p>Abre ás 17:30</p>
                </div>
              </ItemLocation>
              <ItemLocation>
                <div>
                  <img
                    src="https://www.weeke.com.br/wp-content/uploads/2021/07/logotipo-weeke-manager.svg"
                    alt="logo"
                  />
                </div>
                <div>
                  <h2>Weeke Delivery</h2>
                  <p>Av. Cinco, n°1, Parque Cuiaba, Cuiabá-MT</p>
                  <p>Abre ás 17:30</p>
                </div>
              </ItemLocation>
            </ListLocation>
          </div>
        </Container>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default NextUnit;

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  borderRadius?: string;
  padding?: string;
  fit?: string;
  position?: string;
  style?: Record<string, unknown>;
  size?: string;
  border?: string;
}

export const ImageCustom = styled.img<Props>`
  box-sizing: border-box;
  width: ${(props) => props.width || '100%'};
  min-width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'fit-content'};
  min-height: ${(props) => props.height || 'fit-content'};
  padding: ${(props) => props.padding || 0};
  border-radius: ${(props) => props.borderRadius || 0};
  object-fit: ${(props) => props.fit || 'cover'};
  object-position: ${(props) => props.position || 'center'};
  border: ${(props) => props.border || 'none'};

  ::before {
    background-color: #f4f6f8;
    content: 'IMG';

    width: ${(props) => props.width || '100%'};
    min-width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'fit-content'};
    min-height: ${(props) => props.height || 'fit-content'};

    display: flex;
    align-items: center;
    justify-content: center;

    color: #cad2d9;

    text-align: center;
    font-size: ${(props) => props.size || '14px'};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default ImageCustom;

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const DividerBorder = styled.div<{ spacing?: string }>`
  width: -webkit-fill-available;
  height: 1px;
  margin: ${(props) => (props.spacing ? `${props.spacing} 0` : '20px 0')};
  border-bottom: 1px solid ${(props) => props.theme.shadow};
`;

export default DividerBorder;

/* eslint-disable array-callback-return */
/* eslint-disable no-useless-return */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-eval */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';
import Lottie from 'reactjs-lottie';

// Componentes
import { BiChevronDown } from 'react-icons/bi';
import { HiCash } from 'react-icons/hi';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import {
  Text,
  FormItem,
  Stack,
  Label,
  ModalHead,
  ModalBottom,
  ModalBody,
  Button,
  Divider,
  ModalOverlay,
  Input,
  Card,
  RadioCheck,
  Box,
  ModalFooter,
  Dot,
  Tag,
} from '../../lib/style';
import ModalOrder from './ModalOrder';
import ModalProduct from './ModalProduct';
import { Image } from '../../../../lib';
import imgLogo from '../../../../assets/icons/logoWeekeDelivery.svg';

// Icones
import { ReactComponent as IconPix } from '../../lib/iconPix.svg';
import IconEmpty from '../../lotties/empty.json';
import api, { apiURL, color } from '../../../../services/api';
import ModalLogin from './ModalLogin';
import { useAuth } from '../../../../contexts/AuthContext';

export default function ModalBag({ open, close, company }) {
  const [order, setOrder] = React.useState(false);
  const [loginModal, setLoginModal] = React.useState(false);
  const [cep, setCep] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [bag, setBag] = React.useState([]);
  const [sumTotal, setTotal] = React.useState(0);
  const [orderId, setOrderId] = React.useState(0);
  const [dataPayment, setDataPayment] = React.useState();

  const {
    watch,
    reset,
    getValues,
    clearErrors,
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { authed, user } = useAuth();

  const handleFinish = async (data) => {
    authed ? postOrder(data) : setLoginModal(true);
  };

  const postOrder = async (data) => {
    if (
      user?.configuration?.access?.requests?.write ||
      user?.configuration?.access?.requests?.exec
    ) {
      await api
        .post(`${apiURL}/rest/requests.php`, {
          state: 'IN_QUEUE',
          address: {
            cep: data?.cep,
            rua: data?.rua,
            numero: data?.numero,
            complemento: data?.complemento,
            bairro: data?.bairro,
            cidade: data?.cidade,
            uf: data?.uf,
          },
          payment_gateway: data?.pagamento,
          observation: data?.generalObservation ? data?.generalObservation : '',
          products: bag.map((item) => ({
            product: item?.id.id,
            amount: item?.qtd,
            options: item?.options,
            price: item?.price,
            observation: item?.observation ?? '',
          })),
        })
        .then((res) => {
          console.log(res);
          api.put(`${apiURL}/rest/users.php/${user?.id}`, {
            configuration: {
              name: data?.name,
              phone: data?.phone,
            },
            address: [
              {
                cep: data?.cep,
                rua: data?.rua,
                numero: data?.numero,
                complemento: data?.complemento,
                bairro: data?.bairro,
                cidade: data?.cidade,
                uf: data?.uf,
              },
            ],
          });
          reset({
            ...data,
            total: sumTotal || 0,
            deliveryTax: data?.entregaValue || 0,
            timeExpected: company?.timeExpected || 0,
            order: bag || [],
          });
          setOrderId(res?.data);
          setOrder(true);
          /*
            postBotDelivery(data, bag, res.data);
          
            */
        })
        .catch((erro) => {
          console.log(erro);
        });
    }
  };

  // Enviando o pedido para WeekeBot - Sicronização com API.
  /*
    const postBotDelivery = async (user, bag, id) => {
      const data = {
        message: {
          status: 'IN_QUEUE',
          requestNumber: id,
          products: bag.map((item) => ({
            name: item.id.name,
            amount: Number(item.qtd),
            price: Number(item.price),
          })),
          user: {
            name: user.name,
            phone: user.phone,
            address: {
              cep: user.cep,
              rua: user.rua,
              numero: user.numero,
              complemento: user.complemento,
              bairro: user.bairro,
              cidade: user.cidade,
              uf: user.uf,
            },
          },
        },
      };

      try {
        const response = await fetch(
          'https://augusto.local.weeke.app/delivery/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            mode: 'no-cors',
          },
        );
        console.log('Requisição enviada com sucesso!');
      } catch (error) {
        console.error('Erro:', error);
      }
    };
 */

  const removeItemBag = (item) => {
    const temp = [...bag];
    const res = bag.indexOf(item);
    if (res > -1) {
      temp.splice(res, 1);
    }
    setBag(temp);
    localStorage.setItem('BAG', JSON.stringify(temp));
  };

  const sumTotalHandle = () => {
    const sum = bag.reduce(function (sum, tax) {
      return sum + tax.price;
    }, 0);
    setTotal(sum);
  };

  React.useEffect(() => {
    getPayment();
    if (user?.address) {
      const addUser = eval(JSON.parse(user?.address));

      setAddress(addUser && addUser[0]);
    }
    setBag(
      localStorage.getItem('BAG') === null || localStorage.getItem('BAG') === ''
        ? []
        : JSON.parse(localStorage.getItem('BAG')),
    );
  }, [open]);

  const getCep = (cep) => {
    axios.get(`https://viacep.com.br/ws/${cep}/json`).then((res) => {
      if (res.data.erro) {
        setError('cep');
      } else {
        setAddress(res.data);
        setValue('rua', res.data.logradouro);
        setValue('bairro', res.data.bairro);
        setValue('uf', res.data.uf);
        setValue('cidade', res.data.localidade);
        clearErrors(['rua', 'bairro', 'cidade', 'uf']);
      }
    });
  };

  async function getPayment() {
    await api.get('rest/payment_gateway.php').then((res) => {
      setDataPayment(res.data);
    });
  }

  React.useEffect(() => {
    sumTotalHandle();
  }, [bag]);

  function CardItemBag({ data }) {
    const [product, setProduct] = React.useState(false);
    return (
      <>
        <Card>
          <Stack
            direction="row"
            spacing="40px"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <div>
              <Stack
                direction="row"
                spacing="5px"
                align="center"
                style={{ paddingBottom: 7 }}
              >
                <Dot>{data.qtd}</Dot>
                <Text variant="heading">{data.id.name}</Text>
              </Stack>
              {data.options && (
                <Stack
                  direction="row"
                  spacing="15px"
                  style={{ paddingBottom: 7 }}
                >
                  {data.options
                    ?.filter((item) => item !== null)
                    .map((item) => (
                      <Tag key={item?.id} style={{ padding: 0 }}>
                        {item?.qtd} x {item?.nome}
                      </Tag>
                    ))}
                </Stack>
              )}
              {data.adicionals
                ?.filter((item) => item !== null)
                .map((item) => (
                  <Stack key={item?.id} direction="row" spacing="5px">
                    <Text variant="caption">Adicionais:</Text>
                    <Text variant="small">{item?.qtd}x</Text>
                    <Text variant="small">{item?.nome}</Text>
                  </Stack>
                ))}
              {data.observation && (
                <Stack
                  direction="row"
                  spacing="5px"
                  style={{ paddingBottom: 7 }}
                >
                  <Text variant="caption" color="#1C1E1F">
                    Observações:
                  </Text>

                  <Text variant="small">{data.observation}</Text>
                </Stack>
              )}
              <Text variant="caption-md" gutter="8px">
                {data.price?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
              <Stack direction="row" spacing="10px">
                <Text
                  variant="small"
                  gutter="8px"
                  color="#F2554D"
                  style={{
                    display: 'inline-flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                  onClick={() => removeItemBag(data)}
                >
                  <IoMdRemoveCircleOutline size="12px" />
                  Remover
                </Text>
              </Stack>
            </div>
            <Image
              src={
                data?.id?.image &&
                !data?.id?.image.includes('product_no_photo.png')
                  ? data?.id?.image
                  : imgLogo
              }
              width="90px"
              height="90px"
            />
          </Stack>
        </Card>

        <ModalProduct
          open={product}
          product={data.id}
          edit={data}
          close={() => setProduct(false)}
          submit={() => setBag(true)}
        />
      </>
    );
  }

  return (
    <>
      <CSSTransition in={open} timeout={300} classNames="fade" unmountOnExit>
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modalbottom"
        unmountOnExit
      >
        <ModalBottom>
          <ModalBody>
            <ModalHead>
              <Stack direction="row" spacing="15px" alignItems="center">
                <BiChevronDown
                  size="35"
                  color={`rgba(var(--${color}), 1)`}
                  onClick={close}
                />
                <Text variant="title">Sua sacola</Text>
              </Stack>
            </ModalHead>

            {bag.length > 0 ? (
              <>
                <Stack divider="true">
                  {bag.map((item) => (
                    <CardItemBag key={item?.id?.id} data={item} />
                  ))}
                </Stack>
                <Stack spacing="25px">
                  <Divider />
                  <Button variant="ghost" size="md" onClick={close}>
                    Adicionar mais itens
                  </Button>
                  <Button
                    variant="solid"
                    type="button"
                    size="md"
                    onClick={() => {
                      localStorage.removeItem('BAG');
                      close();
                    }}
                  >
                    Limpar sacola
                  </Button>
                </Stack>

                <div style={{ width: '100%' }}>
                  <form onSubmit={handleSubmit(handleFinish)}>
                    <FormItem>
                      <Label style={{ marginBottom: 10 }}>
                        <p>Seus dados</p>
                      </Label>
                      <Stack spacing="15px" alignItems="flex-start">
                        <Text variant="small">
                          Informe o nome da pessoa que receberá o pedido
                        </Text>
                        <Input
                          error={!!errors.name}
                          defaultValue={user?.configuration?.name}
                          placeholder="Nome completo"
                          {...register('name', { required: true })}
                        />
                        <Text variant="small">Telefone</Text>
                        <Input
                          error={!!errors.phone}
                          defaultValue={user?.configuration?.phone}
                          placeholder="(99) 9 9999-9999"
                          {...register('phone', { required: true })}
                        />
                      </Stack>
                    </FormItem>
                    {company && (
                      <FormItem>
                        <Label>
                          <p>Endereço</p>
                        </Label>
                        <Stack spacing="15px">
                          <Input
                            error={!!errors.cep}
                            defaultValue={address?.cep}
                            placeholder="CEP"
                            {...register('cep', {
                              onBlur: (e) => {
                                setCep(e.target.value);
                                console.log(e);
                                getCep(e.target.value);
                              },
                              required: true,
                            })}
                          />
                          <Stack spacing="15px" direction="row">
                            <Input
                              error={!!errors.rua}
                              placeholder="Rua"
                              defaultValue={address?.rua}
                              {...register('rua', {
                                required: true,
                              })}
                            />
                            <Input
                              error={!!errors.numero}
                              placeholder="Número"
                              defaultValue={address?.numero}
                              {...register('numero', {
                                required: true,
                              })}
                              style={{ width: 60 }}
                            />
                          </Stack>
                          <Input
                            placeholder="Complemento"
                            {...register('complemento')}
                          />
                          <Input
                            error={!!errors.bairro}
                            placeholder="Bairro"
                            defaultValue={address?.bairro}
                            {...register('bairro', {
                              required: true,
                            })}
                          />
                          <Stack spacing="15px" direction="row">
                            <Input
                              error={!!errors.cidade}
                              placeholder="Cidade"
                              defaultValue={address?.cidade}
                              {...register('cidade', {
                                required: true,
                              })}
                            />
                            <Input
                              error={!!errors.uf}
                              placeholder="UF"
                              defaultValue={address?.uf}
                              style={{ width: 60 }}
                              {...register('uf', {
                                required: true,
                              })}
                            />
                          </Stack>
                        </Stack>
                      </FormItem>
                    )}
                    <FormItem>
                      <Label>
                        <p>Forma de pagamento</p>
                      </Label>
                      <Stack spacing="20px">
                        {dataPayment?.map((e) => {
                          return (
                            <label
                              htmlFor={e.name}
                              style={{ width: '100%' }}
                              key={e?.id}
                            >
                              <Stack
                                direction="row"
                                spacing="15px"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack direction="row" spacing="10px">
                                  <Text>{e.name}</Text>
                                </Stack>
                                <RadioCheck
                                  type="radio"
                                  id={e.id}
                                  name="pagamento"
                                  value={e.id}
                                  defaultChecked
                                  {...register('pagamento', { required: true })}
                                />
                              </Stack>
                            </label>
                          );
                        })}
                      </Stack>
                    </FormItem>
                    <FormItem>
                      <Label>
                        <p>Tipo de entrega</p>
                      </Label>
                      <Stack
                        spacing="20px"
                        onChange={() => {
                          setValue(
                            'entregaValue',
                            getValues('entrega') === '1'
                              ? +company?.delivery
                              : '0',
                          );
                        }}
                      >
                        {company?.service?.delivery && (
                          <label htmlFor="delivery" style={{ width: '100%' }}>
                            <Stack
                              direction="row"
                              spacing="15px"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Stack direction="row" spacing="10px">
                                <MdOutlineDeliveryDining
                                  size="20px"
                                  color={`rgba(var(--${color}), 1)`}
                                />
                                <Text>Entrega </Text>
                              </Stack>
                              <RadioCheck
                                type="radio"
                                id="delivery"
                                name="entrega"
                                value="1"
                                defaultChecked
                                {...register('entrega', { required: true })}
                              />
                            </Stack>
                          </label>
                        )}

                        {company?.service?.withdrawal && (
                          <label htmlFor="retirar" style={{ width: '100%' }}>
                            <Stack
                              direction="row"
                              spacing="15px"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Stack direction="row" spacing="10px">
                                <HiCash
                                  size="20px"
                                  color={`rgba(var(--${color}), 1)`}
                                />
                                <Text>Retirar </Text>
                              </Stack>
                              <RadioCheck
                                type="radio"
                                id="retirar"
                                name="entrega"
                                value="2"
                                defaultChecked="{!company?.service?.delivery}"
                                {...register('entrega', { required: true })}
                              />
                            </Stack>
                          </label>
                        )}
                      </Stack>
                      <Input
                        defaultValue={company?.delivery}
                        {...register('entregaValue')}
                        hidden
                      />
                    </FormItem>
                    <FormItem>
                      <Label>
                        <p>Observação Geral</p>
                      </Label>
                      <Stack spacing="15px">
                        <Input
                          placeholder="Insira suas observações gerais aqui"
                          {...register('generalObservation')}
                        />
                      </Stack>
                    </FormItem>
                    <FormItem>
                      <Box
                        style={{ border: '1px solid #DAE5F2' }}
                        borderRadius="10px 10px 0 0"
                        padding="15px 22px"
                      >
                        <Stack
                          direction="row"
                          spacing="15px"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text variant="small">Subtotal</Text>
                          <Text variant="caption">
                            {sumTotal?.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </Text>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing="15px"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text variant="small">Taxa de entrega</Text>
                          <Text variant="caption">
                            {Number(watch('entregaValue'))?.toLocaleString(
                              'pt-BR',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              },
                            )}
                          </Text>
                        </Stack>
                      </Box>
                      <Box
                        background={`rgba(var(--${color}), .2)`}
                        borderRadius=" 0 0 10px 10px"
                        padding="15px 22px"
                      >
                        <Stack
                          direction="row"
                          spacing="15px"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text variant="caption-md">Total</Text>
                          <Text variant="caption-md">
                            {(
                              sumTotal + +watch('entregaValue')
                            )?.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </Text>
                        </Stack>
                      </Box>
                    </FormItem>
                    <ModalFooter>
                      <Button
                        variant="solid"
                        size="lg"
                        type="submit"
                        style={{ width: '100%' }}
                      >
                        Finalizar pedido
                      </Button>
                    </ModalFooter>
                  </form>
                </div>
              </>
            ) : (
              <Box textAlign="center" padding="30px">
                <Lottie
                  options={{
                    animationData: IconEmpty,
                  }}
                  width="200px"
                />
                <Text variant="title" gutter="10px">
                  Sacola vazia
                </Text>
                <Text gutter="20px">Adicione algo para continuar</Text>
                <Button variant="ghost" size="lg" onClick={close}>
                  Adicionar item
                </Button>
              </Box>
            )}
          </ModalBody>
        </ModalBottom>
      </CSSTransition>

      <ModalOrder
        open={order}
        orderId={orderId}
        order={getValues()}
        close={() => setOrder(false)}
      />

      <ModalLogin open={loginModal} close={() => setLoginModal(false)} />
    </>
  );
}
